import { Component, OnInit } from '@angular/core';
import { LocataireService } from 'app/shared/services/locataire/locataire.service';
import { AppartementService } from 'app/shared/services/appartement/appartement.service';
import { ContratBailService } from 'app/shared/services/contratBail/contrat-bail.service';
import { StorageMap } from '@ngx-pwa/local-storage';
import { Router, ActivatedRoute } from '@angular/router';
import {isEmpty} from "lodash";
import { NGXToastrService } from 'app/cont_bail/contbailtoast';
import { PaiementService } from 'app/shared/services/paiement/paiement.service';
@Component({
  selector: 'app-add-contbail',
  templateUrl: './add-contbail.component.html',
  styleUrls: ['./add-contbail.component.css'],
  providers: [NGXToastrService]
})
export class AddContbailComponent implements OnInit{

  constructor(private serviceLocataire: LocataireService,
              private serviceAppartement: AppartementService,
              private serviceContrat: ContratBailService,
              private storage: StorageMap,
              private routes:ActivatedRoute,
              private router:Router,
              private service: NGXToastrService,
              private servicePaiement:PaiementService) {
                let a= '01';
                console.log(parseInt(a)+1)
                                    }
  ngOnInit(): void {
    console.log(this.selectedvalue)
    this.routes.queryParams.subscribe((param)=>{
      if(param && param['id']){
        this.idloc=param['id']
        this.serviceLocataire.find(param['id']).subscribe((res)=>{
        this.locataire=res['NomLocataire']+' '+res['PrenomLocataire']
        this.serviceAppartement.find(res['IdAppartement']).subscribe((a)=>{
        this.idapp=a['IdAppart']
        this.appartement=a['NumAppart']
          })
        })
          this.serviceContrat.findByIdLocataire(param['id']).subscribe((c)=>{
            this.contrat=c
          })
      }
    })

  }

  typeSuccess(){
      this.service.typeSuccess()
  }
  typeMontant(){
      this.service.typeMontant()
  }
  typeErreur(){
    this.service.typeError()
  }
  typeInferieur(){
    this.service.typeInferieur()
  }
  register(){
    if(this.selectedvalue==2){
      if(this.locataire !=null && this.appartement !=null && this.montant !=null && this.montantverse !=null && this.caution !=null && !isEmpty(this.dateBail)){
        if(this.montant>0 && this.montantverse>0 && this.caution>0){
            if(this.montant<=this.montantverse){
              let tmp=this.dateBail.split('-')
              let contrat ={
                locataire:this.idloc,
                appartement:this.idapp,
                montant:this.montant,
                monstantverse:this.montantverse,
                datefin:this.datefin,
                MoisBail:'',
                statutContrat:'NonDetermine',
                caution:this.caution,
                AnneeBail:tmp[0]
              }
              switch (tmp[1]) {
                case '01':
                  contrat.MoisBail='Janvier'
                  break;
                case '02':
                  contrat.MoisBail='Fevrier'
                  break;
                case '03':
                  contrat.MoisBail='Mars'
                  break;
                case '04':
                  contrat.MoisBail='Avril'
                  break;
                case '05':
                  contrat.MoisBail='Mai'
                  break;
                case '06':
                  contrat.MoisBail='Juin'
                  break;
                case '07':
                  contrat.MoisBail='Juillet'
                  break;
                case '08':
                  contrat.MoisBail='Aoùt'
                  break;
                case '09':
                  contrat.MoisBail='Septembre'
                  break;
                case '10':
                  contrat.MoisBail='Octobre'
                  break;
                case '11':
                  contrat.MoisBail='Novembre'
                  break;
                default:
                  contrat.MoisBail='Deccembre'
                  break;
              }
              console.log(contrat)
              this.serviceContrat.create(contrat).subscribe(res=>{
                if(res['message']=='save done' && res['statue']==true){
                  let elt=this.datePaye.split('-')
                  let a=contrat.monstantverse/contrat.montant
                  for (let i = 1; i <= a; i++) {
                    let paiement={
                      contrat:this.idloc,
                      montant:contrat.montant,
                      mois:'',
                      annee:elt[0]               
                      }
                      this.numero=Number.parseInt(elt[1])
                      if(i !=1){this.numero +=1; elt[1]='0'+this.numero.toString()}
                    
                      switch (elt[1]) {
                      case '01':
                        paiement.mois='Janvier'
                        break;
                      case '02':
                        paiement.mois='Fevrier'
                        break;
                      case '03':
                        paiement.mois='Mars'
                        break;
                      case '04':
                        paiement.mois='Avril'
                        break;
                      case '05':
                        paiement.mois='Mai'
                        break;
                      case '06':
                        paiement.mois='Juin'
                        break;
                      case '07':
                        paiement.mois='Juillet'
                        break;
                      case '08':
                        paiement.mois='Aoùt'
                        break;
                      case '09':
                        paiement.mois='Septembre'
                        break;
                      case '10':
                        paiement.mois='Octobre'
                        break;
                      case '11':
                        paiement.mois='Novembre'
                        break;
                      default:
                        paiement.mois='Deccembre'
                        break;
                      }
                   this.servicePaiement.create(paiement).subscribe((p)=>{console.log(p)})
                  }
                  this.typeSuccess()
                  this.router.navigateByUrl('listuser')}
                this.resetInput()
              }, (err)=>{console.log(err)})
    
            }else{
              this.typeInferieur()
            }
        
        }else{this.typeMontant()}
      }else{
        this.typeErreur()
        }
    }else{
      if(this.locataire !=null && this.appartement !=null && this.montantverse !=null && this.montant !=null && this.caution !=null && !isEmpty(this.datefin) && !isEmpty(this.dateBail)){
        if(this.montant>0 && this.montantverse>0 && this.caution>0){
          if(this.montant<=this.montantverse){
            let tmp=this.dateBail.split('-')

            let contrat ={
              locataire:this.idloc,
              appartement:this.idapp,
              montant:this.montant,
              monstantverse:this.montantverse,
              datefin:this.datefin,
              MoisBail:'',
              statutContrat:'Determine',
              caution:this.caution,
              AnneeBail:tmp[0]
            }
            switch (tmp[1]) {
              case '01':
                contrat.MoisBail='Janvier'
                break;
              case '02':
                contrat.MoisBail='Fevrier'
                break;
              case '03':
                contrat.MoisBail='Mars'
                break;
              case '04':
                contrat.MoisBail='Avril'
                break;
              case '05':
                contrat.MoisBail='Mai'
                break;
              case '06':
                contrat.MoisBail='Juin'
                break;
              case '07':
                contrat.MoisBail='Juillet'
                break;
              case '08':
                contrat.MoisBail='Aoùt'
                break;
              case '09':
                contrat.MoisBail='Septembre'
                break;
              case '10':
                contrat.MoisBail='Octobre'
                break;
              case '11':
                contrat.MoisBail='Novembre'
                break;
              default:
                contrat.MoisBail='Deccembre'
                break;
            }
  
            console.log(contrat)
            this.serviceContrat.create(contrat).subscribe(res=>{
              if(res['message']=='save done' && res['statue']==true){
                let elt=this.datePaye.split('-')
                let a=contrat.monstantverse/contrat.montant
                for (let i = 1; i <= a; i++) {
                  let paiement={
                    contrat:this.idloc,
                    montant:contrat.montant,
                    mois:'',
                    annee:elt[0]
                    }
                    this.numero=Number.parseInt(elt[1])
                    if(i !=1){this.numero +=1; elt[1]='0'+this.numero.toString()}
                  
                    switch (elt[1]) {
                    case '01':
                      paiement.mois='Janvier'
                      break;
                    case '02':
                      paiement.mois='Fevrier'
                      break;
                    case '03':
                      paiement.mois='Mars'
                      break;
                    case '04':
                      paiement.mois='Avril'
                      break;
                    case '05':
                      paiement.mois='Mai'
                      break;
                    case '06':
                      paiement.mois='Juin'
                      break;
                    case '07':
                      paiement.mois='Juillet'
                      break;
                    case '08':
                      paiement.mois='Aoùt'
                      break;
                    case '09':
                      paiement.mois='Septembre'
                      break;
                    case '10':
                      paiement.mois='Octobre'
                      break;
                    case '11':
                      paiement.mois='Novembre'
                      break;
                    default:
                      paiement.mois='Deccembre'
                      break;
                    }
                 this.servicePaiement.create(paiement).subscribe((p)=>{console.log(p)})
                }
                this.typeSuccess()
                this.router.navigateByUrl('listuser')}
              this.resetInput()
            }, (err)=>{console.log(err)})
  
          }else{
            this.typeInferieur()
          }
          
        }else{this.typeMontant()}
  
        }else{this.typeErreur()}

    }
  }

  resetInput(){
    this.montant="",
    this.appartement="",
    this.locataire="",
    this.datefin=""
  }

  appartement:any
  locataire:any
  idloc:any
  idapp:any
  montantverse:any
  montant:any
  datefin=""
  contrat:any
  selectedvalue:any
  dateBail=""
  datePaye=""
  caution:any
  numero:number
}
