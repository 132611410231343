
import { ToastrService } from 'ngx-toastr';
import { Injectable } from '@angular/core';

@Injectable()
export class NGXToastrService {
    constructor(public toastr: ToastrService) { }

    // Success Type
    typeSuccess() {
        this.toastr.success('Inscription Reussit Avec Succèss !', 'Succès');
    }
    // Success Type
    typeError() {
        this.toastr.error('Veuillez Renseigner Les Champs!', 'Erreur');
    }
    typeUsernameOrPass(){
        this.toastr.error('Nom D\'utilisateur trop court\n Mot De Passe trop court')
    }
    typeEmail(){
        this.toastr.error('Email Non Valide !')
    }
    typePhone(){
        this.toastr.error('Telephone Non Valide !')
    }
    typeUnique()
    {
        this.toastr.error("Erreur lors de l'ajout, Nom utilisateur Existant, Veuillez ressayer")
    }
}