import { FactElectImpayeeComponent } from 'app/Factureimpayee/fact-elect-impayee/fact-elect-impayee.component';
import { MaterialModule } from './material/material.module';
import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';

import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';
import { ToastrModule } from 'ngx-toastr';
import { AgmCoreModule } from '@agm/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { StoreModule } from '@ngrx/store';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';

import {
    PerfectScrollbarModule,
    PERFECT_SCROLLBAR_CONFIG,
    PerfectScrollbarConfigInterface
} from 'ngx-perfect-scrollbar';

import { AppComponent } from './app.component';
import { ContentLayoutComponent } from './layouts/content/content-layout.component';
import { FullLayoutComponent } from './layouts/full/full-layout.component';

import { DragulaService } from 'ng2-dragula';
import { AuthService } from './shared/auth/auth.service';
import { AuthGuard } from './shared/auth/auth-guard.service';
import { ListLocComponent } from './locataire/list-loc/list-loc.component';
import { AddLocComponent } from './locataire/add-loc/add-loc.component';
import { ModifyLocComponent } from './locataire/modify-loc/modify-loc.component';
import { AddContbailComponent } from './cont_bail/add-contbail/add-contbail.component';
import { ListContbailComponent } from './cont_bail/list-contbail/list-contbail.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ViewFacteauComponent } from './facture_eau/view-facteau/view-facteau.component';
import { ModifyUserComponent } from './user/modify-user/modify-user/modify-user.component';
import { ListUserComponent } from './user/list-user/list-user/list-user.component';
import { AddUserComponent } from './user/add-user/add-user/add-user.component';
import { ModifyContbailComponent } from './cont_bail/modify-contbail/modify-contbail.component';
import {Http} from '@angular/http';
import { SideComponent } from './side/side.component';
import { AddReglageComponent } from './reglage/add-reglage/add-reglage.component';
import { ModifyReglageComponent } from './reglage/modify-reglage/modify-reglage.component';
import { ViewContbailComponent } from './cont_bail/view-contbail/view-contbail/view-contbail.component';
import { ListPrestComponent } from './prestataire/list-prest/list-prest.component';
import { ModifyPrestComponent } from './prestataire/modify-prest/modify-prest.component';
import { ViewPrestComponent } from './prestataire/view-prest/view-prest.component';
import { UpdateChargeComponent } from './charge/update-charge/update-charge.component';
import { ViewBatComponent } from './batiments/view-bat/view-bat.component';
import { AddBatComponent } from './batiments/add-bat/add-bat.component';
import { ListesBatComponent } from './batiments/listes-bat/listes-bat.component';
import { ModifyBatComponent } from './batiments/modify-bat/modify-bat.component';
import { AddChargComponent } from './charge/add-charg/add-charg.component';
import { ModifyChargComponent } from './charge/modify-charg/modify-charg.component';
import { ListChargComponent } from './charge/list-charg/list-charg.component';
import { ViewChargeComponent } from './charge/view-charge/view-charge.component';
import { AddPaiementComponent } from './paiement/add-paiement/add-paiement.component';
import { ModifyPaiementComponent } from './paiement/modify-paiement/modify-paiement.component';
import { DeletePaiementComponent } from './paiement/delete-paiement/delete-paiement.component';
import { ListPaiementComponent } from './paiement/list-paiementn/list-paiement/list-paiement.component';
import { ListeApptComponent } from './appartement/liste-appt/liste-appt.component';
import { ModifyApptComponent } from './appartement/modify-appt/modify-appt.component';
import { AddApptComponent } from './appartement/add-appt/add-appt.component';
import { AddFacteauComponent } from './facture_eau/add-facteau/add-facteau.component';
import { ListFacteauComponent } from './facture_eau/list-facteau/list-facteau.component';
import { ModifyFactelectComponent } from './facture_electricite/modify-factelect/modify-factelect.component';
import { ListFactelectComponent } from './facture_electricite/list-factelect/list-factelect.component';
import { ModifyFacteauComponent } from './facture_eau/modify-facteau/modify-facteau.component';
import { AddFactelectComponent } from './facture_electricite/add-factelect/add-factelect.component';
import { ViewApptComponent } from './appartement/view-appt/view-appt.component';
import { ViewFactelectComponent } from './facture_electricite/view-factelect/view-factelect.component';
import { FactEauImpayeeComponent } from './Factureimpayee/fact-eau-impayee/fact-eau-impayee.component';
import { ChargeResoluComponent } from './charge/charge-resolu/charge-resolu.component';
import { AllUserComponent } from './user/all-user/all-user/all-user.component';
import { AddusersComponent } from './user/all-user/addusers/addusers.component';
import { ListGeneraleEauComponent } from './facture_eau/list-generale-eau/list-generale-eau.component';
import { ListGeneraleElectriciteComponent } from './facture_electricite/list-generale-electricite/list-generale-electricite.component';

registerLocaleData(localeFr);

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true,
    wheelPropagation: false
};

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
    // tslint:disable-next-line:max-line-length
    declarations: [AppComponent,
                    SideComponent,
                    UpdateChargeComponent,
                   FullLayoutComponent,
                   ContentLayoutComponent, 
                   AddBatComponent, 
                   ListesBatComponent, 
                   ModifyBatComponent, 
                   ListeApptComponent, 
                   ModifyApptComponent, 
                   AddApptComponent, 
                   AddChargComponent, 
                   ModifyChargComponent, 
                   ListChargComponent, 
                   AddFacteauComponent, 
                   ListFacteauComponent, 
                   ModifyFacteauComponent, 
                   ModifyFactelectComponent, 
                   AddFactelectComponent, 
                   ListFactelectComponent,
                   AddReglageComponent,
                   ModifyReglageComponent,
                   ListLocComponent, 
                   AddLocComponent, 
                   ModifyLocComponent, 
                   ModifyContbailComponent, 
                   AddContbailComponent, 
                   ListContbailComponent, 
                   ViewApptComponent, 
                   ViewFactelectComponent, 
                   ViewFacteauComponent, 
                   ListUserComponent,
                   ListPrestComponent,
                   ModifyPrestComponent,
                   ViewPrestComponent,
                   ModifyUserComponent, 
                   AddUserComponent, 
                   ViewBatComponent, 
                   ViewContbailComponent, 
                   ViewChargeComponent, 
                   AddPaiementComponent, 
                   ModifyPaiementComponent, 
                   DeletePaiementComponent, 
                   ListPaiementComponent,
                   ChargeResoluComponent, 
                   FactElectImpayeeComponent,
                   FactEauImpayeeComponent,
                   AllUserComponent,
                   AddusersComponent,
                   ListGeneraleEauComponent,
                   ListGeneraleElectriciteComponent,
                ],
    imports: [

        CommonModule,
        BrowserModule,
        NgxDatatableModule,
        BrowserAnimationsModule,
        StoreModule.forRoot({}),
        AppRoutingModule,
        SharedModule,
        HttpClientModule,
        ReactiveFormsModule,
        FormsModule,
        ToastrModule.forRoot(),
        NgbModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient]
            }
        }),
        AgmCoreModule.forRoot({
            apiKey: 'YOUR KEY'
        }),
        PerfectScrollbarModule,
        MaterialModule
    ],
    providers: [
        AuthService,
        AuthGuard,
        DragulaService,
        {provide: LOCALE_ID, useValue:"fr-CA"},
        {
            provide: PERFECT_SCROLLBAR_CONFIG,
            useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
        },
        { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG }
    ],
    bootstrap: [AppComponent],
    entryComponents: [UpdateChargeComponent],
})
export class AppModule { }
