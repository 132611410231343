import { Injectable } from '@angular/core';
import { UrlConfigService } from '../urlConfig/url-config.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ChargeService {

  url: any
  constructor(private httpClient: HttpClient,
    private urlconfig: UrlConfigService) {
    this.url = this.urlconfig.getUrl()
  }
  read() {
    return this.httpClient.get(this.url + 'api_gesap/controllers/Ordre_travail/read.php')
  }
  readbat(id:string) {
    console.log(id);
    return this.httpClient.post(this.url + 'api_gesap/controllers/Ordre_travail/readbat.php',{id})
  }

  readChargeResolu(id:string) {
    console.log(id);
    return this.httpClient.post(this.url + 'api_gesap/controllers/Ordre_travail/readtafresolu.php',{id})
  }
  
  readprest(id) {
    console.log(id);
    return this.httpClient.post(this.url + 'api_gesap/controllers/Ordre_travail/readprest.php',{id})
  }

  create(obj) {
    return this.httpClient.post(this.url + 'api_gesap/controllers/Ordre_travail/create.php', obj)
  }

  find(id) {
    return this.httpClient.post(this.url + 'api_gesap/controllers/Ordre_travail/find.php', { id: id })
  }

  delete(id) {
    return this.httpClient.post(this.url + 'api_gesap/controllers/Ordre_travail/delete.php', { id: id })
  }

  update(obj) {
    console.log(obj)
    return this.httpClient.post(this.url + 'api_gesap/controllers/Ordre_travail/update.php', obj)
  }
  changestat(obj) {
    return this.httpClient.post(this.url + 'api_gesap/controllers/Ordre_travail/changestat.php', obj)
  }
  updateordretravail(idTaf:number,coute:number,statut:string,prest:string) {
    let obj={
      Cout: coute,
      NomPrestataire: prest,
      IdTaf: idTaf,
      Statut: statut
    }
    console.log(obj);
    return this.httpClient.post(this.url + 'api_gesap/controllers/Ordre_travail/updateordretravail.php', obj)
  }

  depense(id){
    return this.httpClient.post(this.url + 'api_gesap/controllers/Ordre_travail/depense.php', { id: id })
  }

  
  depense_annee(id,date){
    return this.httpClient.post(this.url + 'api_gesap/controllers/Ordre_travail/depense_annee.php', { id: id,date:date })
  }

  readchargePrio(Id:number,Priorite:number) {
    console.log(Id);
    const obj={
      id:Id,
      priorite:Priorite
    }
    return this.httpClient.post(this.url + 'api_gesap/controllers/Ordre_travail/readPriorite.php',obj)
  }


}
