import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UrlConfigService } from '../urlConfig/url-config.service';

@Injectable({
  providedIn: 'root'
})
export class ContratBailService {

  url:any
  constructor(private httpClient: HttpClient,
              private urlConfig: UrlConfigService) {
                this.url=this.urlConfig.getUrl()
               }

  create(contrat){
    return this.httpClient.post(this.url+'api_gesap/controllers/Contrat_bail/create.php', {MontantBail:contrat['monstantverse'],MontantAppart:contrat['montant'],MoisBail:contrat['MoisBail'],AnneeBail:contrat['AnneeBail'], DateFinBail:contrat['datefin'], IdAppart:contrat['appartement'], IdLocataire:contrat['locataire'], statutContrat:contrat['statutContrat'],caution:contrat['caution']})
  }

  find(id){
    return this.httpClient.post(this.url+'api_gesap/controllers/Contrat_bail/find.php', {IdBail:id})
  }

  findByIdLocataire(IdLocataire){
    return this.httpClient.post(this.url+'api_gesap/controllers/Contrat_bail/findByIdLocataire.php', {id:IdLocataire})
  }
  findAll(){
    return this.httpClient.post(this.url+'api_gesap/controllers/Contrat_bail/read.php', {})
  }

  read(idbat){
    return this.httpClient.post(this.url+'api_gesap/controllers/Contrat_bail/readByIdBat.php', {id:idbat})
  }

  delete(id){
    return this.httpClient.post(this.url+'api_gesap/controllers/Contrat_bail/delete.php', {IdBail:id})
  }
  
  update(contract){
    console.log(contract)
    return this.httpClient.post(this.url+'api_gesap/controllers/Contrat_bail/update.php', {IdBail:contract['IdBail'], MontantBail:contract['MontantBail'], DateFinBail:contract['DateFinBail'], IdAppart:contract['IdAppart'], IdLocataire:contract['IdLocataire']})
  }


}
