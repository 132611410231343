import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UrlConfigService } from '../urlConfig/url-config.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  url:any
  constructor(private http:HttpClient,
              private config:UrlConfigService) {
    this.url=this.config.getUrl()
   }
   
   getUsers(){
     return this.http.post(this.url+'api_gesap/controllers/Utilisateur/read.php', {})
   }

   deleteUser(id){
     return this.http.post(this.url+'api_gesap/controllers/Utilisateur/delete.php', {id:id})
   }

   updateUser(user){
     console.log('up',user)
     return this.http.post(this.url+'api_gesap/controllers/Utilisateur/update.php', {id:user['utilisateursID'], name:user['Nom_utilisateur'], surname:user['Prenom_utilisateur'], profil:user['profilsID'], username:user['Login_utilisateur'], password:user['Pass_utilisateur'], phone:user['phone'], email:user['email']})
   }

   find(id){
     return this.http.post(this.url+'api_gesap/controllers/Utilisateur/find.php', {id:id})
   }

   createUser(user){
    return this.http.post(this.url+'api_gesap/controllers/Utilisateur/create.php', 
   {name:user['Nom_utilisateur'],
    surname:user['Prenom_utilisateur'],
    username:user['Login_utilisateur'],
    password:user['Pass_utilisateur'],
    phone:user['phone'],
    mail:user['email'],
    profil:user['profilsID'],
    statut:user['statut']
   })
  }

  reabailleur()
  {
   return this.http.get(this.url+'api_gesap/controllers/Utilisateur/readBailleur.php');
  }

}
