<!--Registration Page Starts-->
<section id="regestration">
    <div class="container-fluid">
        <div class="row full-height-vh m-0">
            <div class="col-12 d-flex align-items-center justify-content-center">
                <div class="card">
                    <div class="card-content">
                        <div class="card-body register-img">
                            <div class="type-1 row" style="margin-left: 15px;">
                                <a routerLink="/listuser" class="btn btn-1">
                                    <span class="txt">Retour</span>
                                    <span class="round"><i class="fa fa-chevron-left" style="color: white;"></i></span>
                                </a>
                            </div>
                            <div class="row m-0">
                                <div class="col-lg-6 d-none d-lg-block py-2 px-3 text-center">
                                    <img src="assets/img/gallery/register.png" alt="" class="img-fluid mt-3 pl-3" width="400" height="230" />
                                </div>
                                <div class="col-lg-6 col-md-12 pt-3 px-4 bg-white">
                                    <h4 class="card-title mb-2">Edition Utilisateur</h4>
                                    <p class="card-text mb-3">
                                        Renseignez vos informations ci-dessous
                                    </p>
                                    <form ngForm="form" autocomplete="off">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <input name="name" type="text" class="form-control mb-3" placeholder="Nom" required="" [ngModel]="user['NomLocataire']" [(ngModel)]="name" />
                                            </div>
                                            <div class="col-md-6">
                                                <input name="surname" type="text" class="form-control mb-3" placeholder="Prenom" required="" [ngModel]="user['PrenomLocataire']" [(ngModel)]="surname" />
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <input name="cni" type="text" class="form-control mb-3" placeholder="CNI" required="" [ngModel]="user['CNI']" [(ngModel)]="cni" />
                                            </div>
                                            <div class="col-md-6">
                                                <input name="metier" type="text" class="form-control mb-3" placeholder="Metier" required="" [ngModel]="user['Metier']" [(ngModel)]="metier" />
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <input name="phone" type="tel" class="form-control mb-3" placeholder="Telephone" required="" [ngModel]="user['Telephone']" [(ngModel)]="phone" />
                                            </div>
                                            <div class="col-md-6">
                                                <input name="nbr" type="number" class="form-control mb-3" placeholder="Nombre Personne" required="" [ngModel]="user['NbrePersonnes']" [(ngModel)]="nbr" />
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <input name="email" type="tel" class="form-control mb-3" placeholder="Email" required="" [ngModel]="user['Email']" [(ngModel)]="email" />
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <select name="statut" [ngModel]="user['Statue']" [(ngModel)]="statut" required="" class="browser-default custom-select" style="margin-bottom: 20px;">
                                  <option value="0">ACTIF</option>
                                  <option value="1">NONACTIF</option>
                                </select>
                                            </div>
                                            <div class="col-md-6">
                                                <select name="appartement" [ngModel]="appartement" [(ngModel)]="idap" (change)="ap(idap)" required="" class="browser-default custom-select" style="margin-bottom: 20px;">
                                  <option *ngFor="let a of apparts" value={{a.NumAppart}}>{{a['NumAppart']}}</option>
                                </select>
                                            </div>
                                        </div>

                                    </form>
                                    <div class="fg-actions d-flex justify-content-between">
                                        <div class="recover-pass">
                                            <button style="width: 150px;" type="button" class="btn btn-primary btn-block" (click)="edit()">
                          <a class="text-decoration-none text-white">
                            Editer
                          </a>
                        </button>
                                        </div>
                                    </div>
                                    <div style="margin-left: 250px; margin-top: -50px;" class="fg-actions d-flex justify-content-between">
                                        <div class="recover-pass">
                                            <button style="width: 150px; margin-left: -75px;" type="button" class="btn btn-danger btn-block" routerLink="/listuser">
                          <a class="text-decoration-none text-white">
                            Annuler
                          </a>
                        </button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--Registration Page Ends-->