<div class="row" *ngIf="appart">
    <div class="card col-8" >
      <div class="card-header" style="margin-bottom: 15px;">
        <h4 class="card-title mb-0" >Vue d'un appartement</h4>
      </div>
      <div class=" px-3 card-content" >
        <div class="card-img-top img-fluid bg-cover height-300" style="background: url('assets/img/gallery/50.jpg') 50%;">
        </div>
          <div class="card-body text-center">
            <h3 class="primary"><u>Batiment </u> : {{nomBati}}</h3>
              <span class="row" style="margin-bottom: 15px;">
                  <span class="col-6"> 
                      <i class="ft-bookmark h5"></i><span class=""><span class="font-weight-bold h6"> Numéro appart : </span><span class="text-reset">{{appart.NumAppart}}</span></span>
                  </span>
                  <span class="col-6">
                          <i class="ft-bar-chart-2 h5"></i><span class=""><span class="font-weight-bold h6"> Nombre de salons : </span><span class="text-reset">{{appart.NombreSalon}}</span></span>
                  </span>
              </span>
              <span class="row" style="margin-bottom: 15px;">
                  <span class="col-6">
                      <i class="ft-bar-chart-2 h5"></i><span class=""><span class="font-weight-bold h6"> Nombre de chambres : </span ><span class="text-reset">{{appart.NombreCuisine}}</span></span>
                  </span>
                  <span class="col-6">
                          <i class="ft-bar-chart-2"></i><span class=""><span class="font-weight-bold h6"> Nombre de cuisines : </span><span class="text-reset">{{appart.NombreChambre}}</span></span>
                  </span>
              </span>
              <span class="row" style="margin-bottom: 15px;">
                  <span class="col-6">
                      <i class="ft-layers h5"></i><span class=""><span class="font-weight-bold h6"> Nombre de salles de bains : </span><span class="text-reset">{{appart.NombreSalleBain}}</span></span>
                  </span>
                  <span class="col-6">
                    <i class="ft-layers h5"></i><span class=""><span class="font-weight-bold h6"> Nombre de salles d'eau : </span><span class="text-reset">{{appart.NombreSalleEau}}</span></span>
                  </span>
    
              </span>
          </div>
        
      </div>
    
    </div>
    <div class=" card col-3 div2" style="margin-left: 10px;">
      <div>
        <nav>
            <div >
              <div class="card-title entete">
                <h2>Actions</h2>
              </div>
                
                <ul class="card-body" style="list-style-type: none;">
                    <li>
                      <button class="btn  btn-lg btn-block btn-success btn-raised btn-sm font-weight-light" (click)="createAppart()">
                        <i class="ft-plus"></i> Ajout appart
                      </button>
                    </li>
                    <li>
                        <button class="btn  btn-primary btn-lg btn-block btn-raised btn-sm font-weight-light" routerLink="/modifAppart/{{appart.IdAppart}}">
                          <i class="ft-edit-2"></i> Modifier
                        </button>
                    </li>
                    <li>
                      <button class="btn  btn-danger btn-lg btn-block btn-raised btn-sm font-weight-light"  (click)="delete1(appart.IdAppart)">
                        <i class="ft-trash"></i> Supprimer 
                      </button>
                    </li>
                    <li>
                      <button class="btn  btn-success btn-lg btn-block btn-raised btn-sm font-weight-light" routerLink="/Addcharge/{{appart.IdAppart}}/idAppart">
                        <i class="ft-plus"></i> Ajout tâche 
                      </button>
                    </li>
                    <li class="nav-item" ngbDropdown display="dynamic" >
                      <button id="dropdownBasic3" ngbDropdownToggle class="btn btn-lg btn-block btn-raised btn-sm font-weight-light" style="background-color: #007acc !important;color:whitesmoke !important;">
                        <i class="ft-inbox font-medium-3 blue-grey darken-4"></i> Facture Elect
                      </button>
                      
                      <div ngbDropdownMenu aria-labelledby="dropdownBasic3" class="dropdownLang text-left">                           
                          <a  class="dropdown-item py-1 lang" styleclass="dropdown-item py-1" routerLink="/ajoutFactElect/{{appart.IdAppart}}">
                              <i class="ft-plus mr-2"></i>
                             Ajout Fact
                          </a>
                          <a class="dropdown-item py-1 lang" styleclass="dropdown-item py-1" routerLink="/listFactElect/{{appart.IdAppart}}">
                              <i class="ft-list mr-2"></i>
                              Liste Fact
                          </a>
                      </div>
                  </li>
                  <li class="nav-item" ngbDropdown display="dynamic" >
                    <button id="dropdownBasic2" ngbDropdownToggle  class="btn btn-lg btn-block btn-raised btn-sm font-weight-light" style="background-color: #007acc !important;color:whitesmoke !important;">
                      <i class="ft-inbox font-medium-3 blue-grey darken-4"></i> Facture Eau
                    </button>
                    <div ngbDropdownMenu aria-labelledby="dropdownBasic2" class="text-left">                           
                        <a class="dropdown-item py-1 lang" styleclass="dropdown-item py-1" routerLink="/ajoutFactEau/{{appart.IdAppart}}">
                            <i class="ft-plus mr-2"></i>
                           Ajout Fact
                        </a>
                        <a class="dropdown-item py-1 lang" styleclass="dropdown-item py-1" routerLink="/listFactEau/{{appart.IdAppart}}">
                            <i class="ft-list mr-2"></i>
                            Liste Fact
                        </a>
                    </div>
                </li>
                <li style="margin-top: 40px;">
                    <div class="type-1 row justify-content-center">
                        <a routerLink="/listAppart" class="btn btn-1">
                          <span class="txt">Retour</span>
                          <span class="round"><i class="fa fa-chevron-left" style="color: white;"></i></span>
                        </a>
                      </div>
                </li>
                 
                </ul>
               
            </div>
        </nav>
    </div>
    </div>
</div>


