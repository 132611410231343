import { Component, OnInit } from '@angular/core';
import { UserService } from 'app/shared/services/user/user.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NGXToastrService } from './addusers-toast';
import { User } from 'app/interface/User';
import {isEmpty} from "lodash";


@Component({
  selector: 'app-addusers',
  templateUrl: './addusers.component.html',
  styleUrls: ['./addusers.component.css'],
  providers: [NGXToastrService]
})
export class AddusersComponent implements OnInit {

  bat:any
  user={}
  constructor(private serviceUser:UserService,
              private service:NGXToastrService,
              private router:Router,
              private routes:ActivatedRoute,
              ) { }

  ngOnInit(): void {
      this.refresh()
  }

  refresh(){
      this.routes.queryParams.subscribe((p)=>{
          if(p && p['id']){
            this.serviceUser.find(p['id']).subscribe((user)=>{
              this.user=user
                console.log(user)
            })
          }
      })
  }

  typeError(){
    this.service.typeError()
  }
  typeUsernameOrPass(){
      this.service.typeUsernameOrPass()
  }
  typeSuccess(){
      this.service.typeSuccess()
  }
  typeSuccessEdition(){
    this.service.typeSuccessEdition()
  }
  typeEmail(){
      this.service.typeEmail()
  }
  typePhone(){
      this.service.typePhone()
  }
  typeUnique(){
    this.service.typeUnique()
  }

  newUser={
    Nom_utilisateur:'',
    Prenom_utilisateur:'',
    Login_utilisateur:'',
    Pass_utilisateur:'',
    phone:'',
    email:'',
    statut:'NONACTIF',
    profilsID:''
  }

    adduser(){
      console.log(this.user)
        if(isEmpty(this.user)){
          if(this.newUser.Nom_utilisateur.trim().length==0 ||
          this.newUser.Prenom_utilisateur.trim().length==0 ||
          this.newUser.Login_utilisateur.trim().length==0 ||
          this.newUser.Pass_utilisateur.trim().length==0 ||
          this.newUser.phone.trim().length==0 ||
          this.newUser.email.trim().length==0
          ){
          this.typeError()
          }else{
            if(this.newUser.Login_utilisateur.trim().length<3 || this.newUser.Pass_utilisateur.trim().length<5){
              this.typeUsernameOrPass()
              }else{
                  if(this.Check()){
                        if(this.checkMail()){
                            this.serviceUser.createUser(this.newUser).subscribe((res)=>{console.log(res)
                                this.resetInput()
                                this.typeSuccess()
                                this.router.navigateByUrl('/all-user')
                            },()=>{this.typeUnique()})
                        }else{this.typeEmail()}
                  }else{
                    this.typePhone()
                  }
              }
            }  
          }else{
            if(this.newUser.Nom_utilisateur !=""){
              this.user['Nom_utilisateur']=this.newUser.Nom_utilisateur
          }
          if(this.newUser.Prenom_utilisateur !=""){
            this.user['Prenom_utilisateur']=this.newUser.Prenom_utilisateur
          }
          if(this.newUser.Login_utilisateur !=""){
             this.user['Login_utilisateur']=this.newUser.Login_utilisateur
          }

          if(this.newUser.email !=""){
              this.user['email']=this.newUser.email
          }
          if(this.newUser.phone !=""){
              this.user['phone']=this.newUser.phone
            
          }
          if(this.newUser.profilsID !=""){
            this.user['profilsID']=this.newUser.profilsID
          }
          this.serviceUser.updateUser(this.user).subscribe((result)=>{
              this.typeSuccessEdition()
              this.router.navigateByUrl('/all-user')
          })
          }
    }

    changeProfil(e){}

    resetInput(){
      this.newUser.Nom_utilisateur='',
      this.newUser.Prenom_utilisateur='',
      this.newUser.Login_utilisateur='',
      this.newUser.Pass_utilisateur='',
      this.newUser.phone='',
      this.newUser.email='',
      this.newUser.profilsID=''
      }

  redirect(){
      this.router.navigateByUrl('/all-user').then(()=>{})
  }

  public Check(){
    let result=true
    for(let i=0; i<this.newUser.phone.trim().length; i++)
    {
        if(
            Number.parseInt(this.newUser.phone.substring(i,i+1))==0 ||
            Number.parseInt(this.newUser.phone.substring(i,i+1))==1 ||
            Number.parseInt(this.newUser.phone.substring(i,i+1))==2 ||
            Number.parseInt(this.newUser.phone.substring(i,i+1))==3 ||
            Number.parseInt(this.newUser.phone.substring(i,i+1))==4 ||
            Number.parseInt(this.newUser.phone.substring(i,i+1))==5 ||
            Number.parseInt(this.newUser.phone.substring(i,i+1))==6 ||
            Number.parseInt(this.newUser.phone.substring(i,i+1))==7 ||
            Number.parseInt(this.newUser.phone.substring(i,i+1))==8 ||
            Number.parseInt(this.newUser.phone.substring(i,i+1))==9
          ){}else{result=false}
    }
    return result;
  }

  checkMail() : boolean{
    if(this.newUser.email.trim().indexOf('@') > -1){
       return true
    }else{return false}
  }


}
