import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, Input } from '@angular/core';
import { BatimentService } from 'app/shared/services/batiment/batiment.service';

@Component({
  selector: 'app-side',
  templateUrl: './side.component.html',
  styleUrls: ['./side.component.css']
})
export class SideComponent implements OnInit {
  @Input() heading=[];
  @Input() lien=[];
  @Input() headinglink=[];
  batId;
  id:any
  constructor( private router: Router, private routel: ActivatedRoute,private serviceBatiment:BatimentService) {
   }

  ngOnInit(): void {
    console.log(this.heading)
    this.batId = this.routel.snapshot.params['id'];
    console.log(this.batId);
     this.id = JSON.parse(localStorage.getItem('bt'))['idBat'];

  }

}
