import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UrlConfigService } from '../urlConfig/url-config.service';

@Injectable({
  providedIn: 'root'
})
export class PaiementService {

  url:any
  constructor(private http:HttpClient, private config:UrlConfigService) {this.url=this.config.getUrl() }

  create(paiement){
    console.log(paiement)
      return this.http.post(this.url+'api_gesap/controllers/Paiement/create.php', paiement)
  }

  readLocataire(){
    return this.http.post(this.url+ 'api_gesap/controllers/Paiement/readLocataire.php',{})
  }

  getPaiement(){
    return this.http.post(this.url+ 'api_gesap/controllers/Paiement/read.php', {})
  }

  deletepaiement(id){
    return this.http.post(this.url+ 'api_gesap/controllers/Paiement/delete.php', {id:id})
  }

  updatepaiement(paiement){
    return this.http.post(this.url+ 'api_gesap/controllers/Paiement/update.php', paiement)
  }

  findPaiement(idlocataire,Mois,Annee){
    return this.http.post(this.url+ 'api_gesap/controllers/Paiement/find.php', {Locataire:idlocataire,Mois:Mois,Annee:Annee})
  }
}
