import { Component, OnInit } from '@angular/core';
import { FactureEauService } from './../../shared/services/factureEau/facture-eau.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AppartementService } from './../../shared/services/appartement/appartement.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-view-facteau',
  templateUrl: './view-facteau.component.html',
  styleUrls: ['./view-facteau.component.css']
})
export class ViewFacteauComponent implements OnInit {

  idFact: number;
  facture: any;
  type: string;
  idAppart: number;
  appartement: any;
  numAppart: string;
  constructor(private router: Router, private route: ActivatedRoute, private service: FactureEauService,
                private serviceAppartement: AppartementService, public toastr: ToastrService) { }

  ngOnInit(): void {
    this.idFact = this.route.snapshot.params['idFact'];
    this.service.find(this.idFact).subscribe(data => {
      this.facture = data;
      this.type = this.facture.TypeCompteur;
      this.idAppart = this.facture.IdAppart;
      console.log(data);
      this.serviceAppartement.find(this.idAppart).subscribe(data1 => {
          this.appartement = data1;
          this.numAppart = this.appartement.NumAppart;
          

      });
    });
  }

  delete(id) {
    if (confirm("Voulez-vous vraiment supprimer la facture "+this.facture.NumFactEau+ " d\'eau?????")) {
      this.service.delete(id).subscribe(data => {
        this.router.navigate(['/listFactEau', this.facture.IdAppart]);
        this.toastr.error('Facture d\'eau supprimée.', 'Suppression!');
      });
    }
  }

}
