import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BatimentService } from 'app/shared/services/batiment/batiment.service';
import { AppartementService } from 'app/shared/services/appartement/appartement.service';
import { Appartement } from 'app/interface/appartement';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
@Component({
  selector: 'app-view-bat',
  templateUrl: './view-bat.component.html',
  styleUrls: ['./view-bat.component.css']
})
export class ViewBatComponent implements OnInit {
  batId;
  batiment;
  type: any;
  Appartements;
  temp = [];
  test:any
  idBati:any
  ELEMENT_DATA: Appartement[];
  single: any;
  closeResult: string;
  displayedColumns: string[] = ['id','NumAppart', 'NombreSalon', 'NombreCuisine', 'NombreChambre','action'];
  dataSource = new MatTableDataSource<Appartement>(this.ELEMENT_DATA);
  @ViewChild(MatSort, { static: true }) sorte: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(private service: BatimentService,
              private servapp: AppartementService, 
              private router: Router, 
              private routel: ActivatedRoute,
              private serviceBatiment:BatimentService) { }

  ngOnInit(): void {
    this.refresh();
  }

  refresh() {
        let profile=JSON.parse(localStorage.getItem('bt'))['profil']
        this.routel.queryParams.subscribe((p)=>{
          if(p && p['idBat']){
            if(profile=='ADMINISTRATEUR'){
              this.service.find(p['idBat']).subscribe((data) => {
                console.log(data)
                this.batiment = data;
                this.type = this.batiment.Type;
                if(this.batiment['NombreAppart']>this.batiment['NbApCr']){
                  console.log(this.batiment['NombreAppart'],this.batiment['NbApCr'])
                    this.test=true
                }else{this.test=false}
              });
              console.log(p['idBat'])
              this.servapp.read(p['idBat']).subscribe((data: any) => {
                console.log(data)
                this.dataSource.data=data;
                 this.dataSource.paginator = this.paginator;
                 this.dataSource.sort = this.sorte;
                 this.temp = [...data];
               });
            }
          }else{
                      if(profile=='BAILLEUR'){
                      this.batId=JSON.parse(localStorage.getItem('bt'))['idBat']
                      console.log(this.batId)
                  }else{
                  this.batId=this.routel.snapshot.params['id']
                  }
                    this.service.find(this.batId).subscribe((data) => {
                      console.log(data)
                      this.batiment = data;
                      this.type = this.batiment.Type;
                      if(this.batiment['NombreAppart']>this.batiment['NbApCr']){
                        console.log(this.batiment['NombreAppart'],this.batiment['NbApCr'])
                          this.test=true
                      }else{this.test=false}
                    });

                this.servapp.read(this.batId).subscribe((data: any) => {
                  this.dataSource.data=data;
                  this.dataSource.paginator = this.paginator;
                  this.dataSource.sort = this.sorte;
                  this.temp = [...data];
                });

          }
        })


  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    //this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  Ondelete() {
    if (confirm('voulez-vous vraiment supprimer ce batiment??')) {
      this.servapp.delete(this.batId).subscribe((data) => {
        //console.log(data);
        this.refresh();
      });
    }
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();

    // filter our data
    const temp = this.temp.filter(function (d) {
        return d.NumAppart.toLowerCase().indexOf(val) !== -1 || !val;
    });

    // update the rows
    this.dataSource.data = temp;
    
 }

  delete(id) {
    if (confirm("Voulez-vous vraiment supprimer cet appartement?????")) {
      this.service.delete(id).subscribe(data => {
        this.refresh();
      });
    }
  }

  retour(){
    if(JSON.parse(localStorage.getItem('bt'))['profil']=='ADMINISTRATEUR'){
      this.router.navigateByUrl('/dashboard/dashboard1')
    }else{
      this.router.navigateByUrl('/dashboard/dashboard2')
    }
  }

}
