import { Component, OnInit } from '@angular/core';
import { LocataireService } from 'app/shared/services/locataire/locataire.service';
import { Router } from '@angular/router';
import { AppartementService } from 'app/shared/services/appartement/appartement.service';
import { BatimentService } from 'app/shared/services/batiment/batiment.service';
import { StorageMap } from '@ngx-pwa/local-storage';
import { NGXToastrService } from '../add-user-toast';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.css'],
  providers: [NGXToastrService]
})
export class AddUserComponent implements OnInit {
  NumBat:any
  constructor(private serviceLocataire: LocataireService,
              private serviceAppart: AppartementService,
              private serviceBat:BatimentService,
              private storage:StorageMap,
              private router:Router,
              private service: NGXToastrService) {
              }

  ngOnInit(): void {
    let b=JSON.parse(localStorage.getItem('bt'))
    this.serviceBat.find(b['idBat']).subscribe((res)=>{this.NumBat=res['NomBatiment']})
    console.log(b['idBat'])
    this.serviceAppart.contratAppartBat(b['idBat']).subscribe((a:[])=>{this.aparts=a})
  }

        // error Email
        typeEmail() {
          this.service.typeEmail()
        }

        //Error phone
        typeTelephone(){
          this.service.typeTelephone()
        }

        typeError(){
          this.service.typeError()
        }
  

  create(){
    console.log(this.locataire.nbr)
    if(this.locataire.name.trim().length==0 || 
       this.locataire.surname.trim().length==0 ||
       this.locataire.phone.trim().length==0 ||
       this.locataire.cni.trim().length==0 ||
       this.locataire.metier.trim().length==0 ||
       this.locataire.email.trim().length==0 ||
       this.locataire.IdAppartement.length==0||
       (this.locataire.nbr.length==0 || this.locataire.nbr==undefined || this.locataire.nbr==null)){
         this.typeError()}else{
          if(!this.Check()){this.typeTelephone()
          }else{if(!this.checkMail())
            {this.typeEmail()
            }else{
              if(Number.parseInt(this.locataire.nbr)<1){alert('Veuillez Entrer Un Nombre Correct !')
              }else{console.log('def'); this.serviceLocataire.create(this.locataire).subscribe((res)=>{
                if(res['statue']==true && res['message']=='save done'){
                    this.resetInput()
                    this.router.navigateByUrl('listuser')
                  }
              })}
            }
          }
        }
      
  }

  changeBat(b){
  }

  locataire={
    name:"",
    surname:"",
    email:"",
    phone:"",
    nbr:"",
    cni:"",
    metier:"",
    IdAppartement:""
  }

  aparts=[]
  bats=[]
  sel=""
  bat=""

  resetInput(){
    this.locataire.name="",
    this.locataire.surname="",
    this.locataire.email="",
    this.locataire.phone="",
    this.locataire.nbr="",
    this.locataire.cni="",
    this.locataire.metier=""
  }

  public Check(){
    let result=true
    for(let i=0; i<this.locataire.phone.trim().length; i++)
    {
        if(
            Number.parseInt(this.locataire.phone.substring(i,i+1))==0 ||
            Number.parseInt(this.locataire.phone.substring(i,i+1))==1 ||
            Number.parseInt(this.locataire.phone.substring(i,i+1))==2 ||
            Number.parseInt(this.locataire.phone.substring(i,i+1))==3 ||
            Number.parseInt(this.locataire.phone.substring(i,i+1))==4 ||
            Number.parseInt(this.locataire.phone.substring(i,i+1))==5 ||
            Number.parseInt(this.locataire.phone.substring(i,i+1))==6 ||
            Number.parseInt(this.locataire.phone.substring(i,i+1))==7 ||
            Number.parseInt(this.locataire.phone.substring(i,i+1))==8 ||
            Number.parseInt(this.locataire.phone.substring(i,i+1))==9
          ){}else{result=false}
    }
    return result;
  }

  checkMail() : boolean{
    if(this.locataire.email.trim().indexOf('@') > -1){
       return true
    }else{return false}
  }

}
