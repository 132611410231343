import { ReglageService } from 'app/shared/services/reglage/reglage.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BatimentService } from './../../shared/services/batiment/batiment.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NGXToastrService } from '../add-reglage/add-reglage-toast';

@Component({
  selector: 'app-add-reglage',
  templateUrl: './add-reglage.component.html',
  styleUrls: ['./add-reglage.component.scss'],
  providers: [NGXToastrService]
})
export class AddReglageComponent implements OnInit {

  form: FormGroup;
  nombre = '^[0-9]{1,10}$';
  nomBati: string;
  idBati: number;
  constructor(private formBuilder: FormBuilder, private service: ReglageService, private serviceBatiment: BatimentService,
    private router: Router, public toastr: ToastrService) { }

  ngOnInit(): void {
    const obj1 = localStorage.getItem('info');
    const objJson1 = JSON.parse(obj1);
    console.log(objJson1);

    const obj = localStorage.getItem('bt');
    const objJson = JSON.parse(obj);
    this.serviceBatiment.find(objJson.idBat).subscribe(data => {
      console.log(data);
        this.idBati = data['IdBatiment'];
        this.nomBati = data['NomBatiment'];
    });
    this.initForm()
  }
  initForm() {
    this.form = this.formBuilder.group({
      prixKwh: ['', [Validators.required, Validators.pattern(this.nombre)]],
      prixM3: ['', [Validators.required, Validators.pattern(this.nombre)]],
      prixEntretienElectricite: ['', [Validators.required, Validators.pattern(this.nombre)]],
      prixEntretienEau: ['', [Validators.required, Validators.pattern(this.nombre)]],
    });
  }

  onSubmitForm() {
    const prixKwh = this.form.get('prixKwh').value;
    const prixM3 = this.form.get('prixM3').value;
    const prixEntretienElectricite = this.form.get('prixEntretienElectricite').value;
    const prixEntretienEau = this.form.get('prixEntretienEau').value;
    console.log(prixKwh, prixM3, prixEntretienElectricite, prixEntretienEau, this.idBati);
    this.service.create(prixKwh, prixM3, prixEntretienElectricite, prixEntretienEau, this.idBati).subscribe(data => {
        this.router.navigate(['/dashboard/dashboard2']);
        this.toastr.success('Réglage effectué!', 'Succèss!');
    });
  }
}
