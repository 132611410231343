import { AppartementService } from 'app/shared/services/appartement/appartement.service';
import { BatimentService } from 'app/shared/services/batiment/batiment.service';
import { ChargeService } from 'app/shared/services/charge/charge.service';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ViewPrestComponent } from 'app/prestataire/view-prest/view-prest.component';

@Component({
  selector: 'app-view-charge',
  templateUrl: './view-charge.component.html',
  styleUrls: ['./view-charge.component.scss']
})
export class ViewChargeComponent implements OnInit {
  idtaf:any;
  Taf:any;
  idbat;

  statut:any
  batiment:any;

  Nombat;
  numapp;
  priorite;
  categorie;
  NomPrestataire;
  Description;
  DateResol;
  DateOcc;
  Cout;
  appartement:any;
  constructor(private routel: ActivatedRoute,
              private service: ChargeService,
              private servbat:BatimentService,
              private servapp:AppartementService,
              private dialog: MatDialog
    ) { }

  ngOnInit() {
   this.refresh();
  }

  refresh(){
    this.idbat = JSON.parse(localStorage.getItem('bt'))['idBat'];

    let ibbat;

    this.idtaf = this.routel.snapshot.params['id'];

    this.service.find(this.idtaf).subscribe((data:Object)=>{
      this.Taf=data;
      ibbat=this.Taf.IdBatiment;

      console.log( this.Taf);

      this.statut=this.Taf.Statut;

      this.priorite=this.Taf.Priorite;
      this.Description=this.Taf.Description;
      this.categorie=this.Taf.Categorie;
      this.Cout=this.Taf.Cout;
      this.DateOcc=this.Taf.DateOcc;
      this.DateResol=this.Taf.DateResol;
      this.NomPrestataire=this.Taf.NomPrestataire;
     /* if(this.Taf.Priorite==1)
      {
        this.priorite="bas";
      }else if(this.Taf.Priorite==2)
      {
        this.priorite="Moyen";
      }else if(this.Taf.Priorite==3)
      {
        this.priorite="Haute";
      }*/
      
        if(this.Taf.IdAppart!="All")
        {
          this.servapp.find(this.Taf.IdAppart).subscribe((data2:any)=>{
            this.appartement=data2;
            console.log(data2);
            this.numapp=this.appartement.NumAppart;
          });
        }
        else{
          this.numapp=this.Taf.IdAppart;
        }
    });

    this.servbat.find(this.idbat).subscribe((data1:any)=>{
      this.batiment=data1;
      console.log(data1);
      this.Nombat=this.batiment.NomBatiment;

    });
  }

  Ondelete() {
    if (confirm("voulez-vous vraiment supprimer cette Tache??")) {
      this.service.delete(this.idtaf).subscribe((data) => {
        console.log(data);
        this.refresh();
      });
    }
  }
  openDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data ={id:this.idtaf} ;
    dialogConfig.height = '690px';
    dialogConfig.width = '400px';
    const dialogRef = this.dialog.open(ViewPrestComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(() => {
      this.refresh();
    });
  }
}
