import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class VerifyService {
  verify:boolean;
  constructor() {
  }
  funct(val)
  {
    this.verify=val;
    //console.log(this.verify);
  }
}
