import { Validators, NgForm } from '@angular/forms';
import { FormGroup, FormControl } from '@angular/forms';
import { ChargeService } from './../../shared/services/charge/charge.service';
import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PrestataireService } from 'app/shared/services/prestataire/prestataire.service';

@Component({
  selector: 'app-update-charge',
  templateUrl: './update-charge.component.html',
  styleUrls: ['./update-charge.component.scss']
})
export class UpdateChargeComponent implements OnInit {
   idTaf;
   idbat;
   prestataire;
   valide: any;
   pres:any;
   @ViewChild("f") floatingLabelForm: NgForm;
   @ViewChild("vform") validationForm: FormGroup;
   form2: FormGroup;
   mobnumPattern = "^[6]+[5|6|7|9|8]+[0-9]{7}$";
   Number="^[0-9]{3,20}$";
   unamePattern = "^[a|A-z|Z0-9 ]{5,20}$";

   batiments;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  public dialogRef: MatDialogRef<UpdateChargeComponent>,
  private servprest: PrestataireService,
  private service: ChargeService) { }

  ngOnInit() {
    this.idTaf = this.data['id'];
    console.log(this.idTaf);
    this.refresh();
  }
  refresh()
  {
    let idbats;
    this.service.find(this.idTaf).subscribe((data:any)=>{
      console.log(data);
      this.batiments= data;

      this.servprest.readidprest(this.batiments.IdBatiment).subscribe((data:any)=>{
        this.prestataire = data;
        console.log(this.prestataire);
      });
    });
    this.onInform();
  }
  getprestat(event) {
    let val = event.target.value;
    console.log(val);
  }
  
  add() {
    this.prestataire = false;
    this.form2 = new FormGroup(
      {
        prestataire: new FormControl(null, [Validators.required]),
        nom: new FormControl(null, [Validators.required,Validators.pattern(this.unamePattern)]),
        prenom: new FormControl(null, [Validators.required,Validators.pattern(this.unamePattern),
        ]),
        tel: new FormControl(null, [Validators.required,Validators.pattern(this.mobnumPattern)]),
        cout: new FormControl(null, [Validators.required,Validators.pattern(this.Number)])
      },
      { updateOn: "blur" }
    );
  }
  onInform()
  {
    this.form2 = new FormGroup(
      {
        prestataire: new FormControl(null, [Validators.required]),
        nom: new FormControl(0, [Validators.required,Validators.pattern(this.unamePattern)]),
        prenom: new FormControl(0, [Validators.required,Validators.pattern(this.unamePattern),
        ]),
        tel: new FormControl(0, [Validators.required,Validators.pattern(this.mobnumPattern)]),
        cout: new FormControl(null, [Validators.required,Validators.pattern(this.Number)])
      },
      { updateOn: "blur" }
    );
  }
  Onsubmit(statut: string) {

    const obj = {
      Nom: this.form2.get("nom").value,
      Prenom: this.form2.get("prenom").value,
      Contact: this.form2.get("tel").value,
    };

    let cout= this.form2.get("cout").value;
    let prest= this.form2.get("prestataire").value;
    let Statut= statut;
    let idTaf=this.idTaf;
    let prest2;

    if (prest) {
      this.service.updateordretravail(idTaf,cout,Statut,prest).subscribe((res) => {
        this.refresh();
        this.dialogRef.close();
      });
    }
    else {
      this.servprest.create(obj).subscribe((data:any) => {
        this.pres=data
        console.log(data);
        prest2=this.pres.Idprestataire;
        console.log(prest2);
        this.service.updateordretravail(idTaf,cout,Statut,prest2).subscribe((res) => {
          console.log(res)
          this.refresh();
          this.dialogRef.close();
        }); 
      });
         
    }


  }
}
