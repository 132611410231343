<div class="row">
    <div class="col-12" *ngIf="profil == 'ADMINISTRATEUR'" >
        <section id="filter" class="mb-3">
            <div class="row">
                <div class="col-sm-12">
                    <mat-form-field *ngFor="let filter of filterSelectObj" style="margin-left: 15px;">
                        <mat-label>Filtre {{filter.name}}</mat-label>
                        <select matNativeControl name="{{filter.columnProp}}" [(ngModel)]="filter.modelValue"
                          (change)="filterChange(filter,$event)">
                          <option value="">-- Sélectionner {{filter.name}} --</option>
                          <option [value]="item" *ngFor="let item of filter.options">{{item}}</option>
                        </select>
                    </mat-form-field>
                    &nbsp;
                    <button mat-flat-button color="warn" (click)="resetFilters()">Réinitialiser</button>
                    <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8" style="width: 100%;">
                        <ng-container matColumnDef="id">
                            <th mat-header-cell *matHeaderCellDef scope="col" mat-sort-header> # </th>
                            <td mat-cell *matCellDef="let i=index">
                                {{i+1}} </td>
                        </ng-container>
                        <ng-container matColumnDef="NumAppart">
                            <th mat-header-cell *matHeaderCellDef scope="col" mat-sort-header> Numéro de l'appartement </th>
                            <td mat-cell *matCellDef="let element" routerLinkActive="active" routerLink="/viewAppart/{{element.IdAppart}}">
                                {{element.NumAppart}} </td>
                        </ng-container>
                        <ng-container matColumnDef="NombreSalon">
                            <th mat-header-cell *matHeaderCellDef scope="col" mat-sort-header> Nombre de salons </th>
                            <td mat-cell *matCellDef="let element">
                                {{element.NombreSalon}} </td>
                        </ng-container>
                        <ng-container matColumnDef="NombreCuisine">
                            <th mat-header-cell *matHeaderCellDef scope="col" mat-sort-header> Nombre de cuisines </th>
                            <td mat-cell *matCellDef="let element">
                                {{element.NombreCuisine}} </td>
                        </ng-container>
                        <ng-container matColumnDef="NombreChambre">
                            <th mat-header-cell *matHeaderCellDef scope="col" mat-sort-header> Nombre de chambres </th>
                            <td mat-cell *matCellDef="let element">
                                {{element.NombreChambre}} </td>
                        </ng-container>
                        <ng-container matColumnDef="NomBatiment">
                            <th mat-header-cell *matHeaderCellDef scope="col" mat-sort-header> Bâtiment </th>
                            <td mat-cell *matCellDef="let element">
                                {{element.NomBatiment}} </td>
                        </ng-container>
                        <ng-container matColumnDef="action">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col" class="thead-dark1"> Actions </th>
                            <td mat-cell *matCellDef="let element">
                                <a class="info p-0" data-original-title="" title="Vue" routerLinkActive="active" routerLink="/viewAppart/{{element.IdAppart}}">
                                    <i class="ft-eye font-medium-3 mr-2"></i>
                                </a>
                                <a class="success p-0" data-original-title="" title="Modifier" routerLinkActive="active" routerLink="/modifAppart/{{element.IdAppart}}">
                                    <i class="ft-edit-2 font-medium-3 mr-2"></i>
                                </a>
                                <a class="danger p-0" data-original-title="" title="Supprimer" (click)="delete(element.IdAppart)">
                                    <i class="ft-trash font-medium-3 mr-2"></i>
                                </a>
                            </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
    
                    <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" showFirstLastButtons></mat-paginator>
    
    
                </div>
            </div>
        </section>
    </div>
</div>

<div class="row" *ngIf="profil == 'BAILLEUR'" > 
    <div class="col-9">
        <section id="filter" class="mb-3">
            <div class="row">
                <div class="col-sm-12">
                    <input type='text' style='padding:8px;margin:15px auto;width:30%;' placeholder='Rechercher...' (keyup)='updateFilter($event)' />
                    <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8" style="width: 100%;">
                        <ng-container matColumnDef="id">
                            <th mat-header-cell *matHeaderCellDef scope="col" mat-sort-header> # </th>
                            <td mat-cell *matCellDef="let i=index">
                                {{i+1}} </td>
                        </ng-container>
                        <ng-container matColumnDef="NumAppart">
                            <th mat-header-cell *matHeaderCellDef scope="col" mat-sort-header> Numéro de l'appartement </th>
                            <td mat-cell *matCellDef="let element" routerLinkActive="active" routerLink="/viewAppart/{{element.IdAppart}}">
                                {{element.NumAppart}} </td>
                        </ng-container>
                        <ng-container matColumnDef="NombreSalon">
                            <th mat-header-cell *matHeaderCellDef scope="col" mat-sort-header> Nombre de salons </th>
                            <td mat-cell *matCellDef="let element">
                                {{element.NombreSalon}} </td>
                        </ng-container>
                        <ng-container matColumnDef="NombreCuisine">
                            <th mat-header-cell *matHeaderCellDef scope="col" mat-sort-header> Nombre de cuisines </th>
                            <td mat-cell *matCellDef="let element">
                                {{element.NombreCuisine}} </td>
                        </ng-container>
                        <ng-container matColumnDef="NombreChambre">
                            <th mat-header-cell *matHeaderCellDef scope="col" mat-sort-header> Nombre de chambres </th>
                            <td mat-cell *matCellDef="let element">
                                {{element.NombreChambre}} </td>
                        </ng-container>
                        <ng-container matColumnDef="NomBatiment">
                            <th mat-header-cell *matHeaderCellDef scope="col" mat-sort-header> Bâtiment </th>
                            <td mat-cell *matCellDef="let element">
                                {{element.NomBatiment}} </td>
                        </ng-container>
                        <ng-container matColumnDef="action">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col" class="thead-dark1"> Actions </th>
                            <td mat-cell *matCellDef="let element">
                                <a class="info p-0" data-original-title="" title="Vue" routerLinkActive="active" routerLink="/viewAppart/{{element.IdAppart}}">
                                    <i class="ft-eye font-medium-3 mr-2"></i>
                                </a>
                                <a class="success p-0" data-original-title="" title="Modifier" routerLinkActive="active" routerLink="/modifAppart/{{element.IdAppart}}">
                                    <i class="ft-edit-2 font-medium-3 mr-2"></i>
                                </a>
                                <a class="danger p-0" data-original-title="" title="Supprimer" (click)="delete(element.IdAppart)">
                                    <i class="ft-trash font-medium-3 mr-2"></i>
                                </a>
                            </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>

                    <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" showFirstLastButtons></mat-paginator>


                </div>
            </div>
        </section>
    </div>

    <div class="card col-3 div2">
        <div>
            <nav>
                <div>
                    <div class="card-title entete">
                        <h2>Actions</h2>
                    </div>

                    <ul class="card-body" style="list-style-type: none;">
                        <li>
                            <button class="btn  btn-lg btn-block btn-success btn-raised btn-sm font-weight-light" (click)="createAppart()">
                      <i class="ft-plus"></i> Ajouter
                    </button>
                        </li>
                        <li style="margin-top: 40px;">
                            <div class="type-1 row justify-content-center">
                                <a routerLink="/ViewBatiment/{{idBati}}" class="btn btn-1">
                                    <span class="txt">Retour</span>
                                    <span class="round"><i class="fa fa-chevron-left" style="color: white;"></i></span>
                                </a>
                            </div>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
    </div>

</div>