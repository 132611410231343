import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UrlConfigService } from '../urlConfig/url-config.service';

@Injectable({
  providedIn: 'root'
})
export class BatimentService {

  url:any
  constructor(private httpClient: HttpClient,
              private urlconfig: UrlConfigService) {
              this.url=this.urlconfig.getUrl()
     }

     refreshData() {
        this.read().subscribe((data: any[]) => {},
        (error) => {
          console.log('Erreur de chargement');
      });
     }

     read(){
        return this.httpClient.get(this.url+'api_gesap/controllers/Batiments/read.php')
      }

    readAll(idBailleur){
      return this.httpClient.post(this.url+'api_gesap/controllers/Batiments/readAll.php',{id:idBailleur})
    }
  create(obj:any){
  console.log(obj);
    return this.httpClient.post(this.url+'api_gesap/controllers/Batiments/create.php', obj)
  }

  find(id){
    return this.httpClient.post(this.url+'api_gesap/controllers/Batiments/find.php', {id:id})
  }

  delete(id){
    return this.httpClient.post(this.url+'api_gesap/controllers/Batiments/delete.php', {id:id})
  }

  update(obj){
    return this.httpClient.post(this.url+'api_gesap/controllers/Batiments/update.php',obj)
  }

  BatActif(){
    return this.httpClient.get(this.url+'api_gesap/controllers/Batiments/readBatACTIF.php');

  }

  BatNonActif(){
    return this.httpClient.get(this.url+'api_gesap/controllers/Batiments/readBatNONACTIF.php');

  }
}
