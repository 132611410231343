<div class="container d-md-flex align-items-stretch">
    <nav id="sidebar" style="min-width: 210px;">
        <div class="p-4 pt-5">
            <h5>Options</h5>
            <ul class="list-unstyled components mb-5">
                <li *ngFor="let head of heading;let i=index">
                    <span *ngIf='batId'>
                        <a *ngIf='head=="Editer"' data-toggle="collapse" aria-expanded="false" routerLink="{{lien[i]}}/{{batId}}" class="dropdown-toggle">{{head}}</a>
                        <a *ngIf='(head!="Editer") && (head!="Ordre de travail") ' data-toggle="collapse" aria-expanded="false" routerLink="{{lien[i]}}" class="dropdown-toggle">{{head}}</a>
                        <a *ngIf='head=="Ordre de travail"' data-toggle="collapse" aria-expanded="false" routerLink="{{lien[i]}}/{{batId}}" class="dropdown-toggle">{{head}}</a>
                        
                    </span>
                    <span *ngIf='!batId'>
                        <a *ngIf='head=="Ajout Appartement"' data-toggle="collapse" aria-expanded="false" routerLink="{{lien[i]}}/{{id}}">{{head}}</a>
                    </span>
                    <span *ngIf='!batId'>
                        <a *ngIf='head=="Vue Batiment"' data-toggle="collapse" aria-expanded="false" routerLink="{{lien[i]}}/{{id}}" class="dropdown-toggle">{{head}}</a>
                        <a *ngIf='head=="Liste Batiment"' data-toggle="collapse" aria-expanded="false" routerLink="{{lien[i]}}" class="dropdown-toggle">{{head}}</a>                        
                    </span>

                </li>
             
            </ul>
           
        </div>
    </nav>
</div>