import { Router, ActivatedRoute } from '@angular/router';
import { Injectable } from '@angular/core';
import { StorageMap } from '@ngx-pwa/local-storage';
import { isNull } from 'util';
import {isEmpty} from "lodash";
@Injectable()
export class AuthService {
  token: string;

  constructor(private storage:StorageMap,
              private router:Router,
              private route: ActivatedRoute,
              ) {}

  signupUser(email: string, password: string) {
    //your code for signing up the new user
  }

  signinUser(email: string, password: string) {
    //your code for checking credentials and getting tokens for for signing in user
  }

  logout() {   
    this.token = null
  }

  getToken() {   
    return this.token
  }

  isAuthenticated() {
        const isLoggedIn = !isEmpty(localStorage.getItem('bt'));
        if (!isLoggedIn) {
          this.router.navigate(['/login']);
          //Si pas d'utilisateur connecté : redirection vers la page de login
      }
      return isLoggedIn;
    }

  
}
