<!-- Reactive Form Validaton Starts -->
<div class="row">
    <div class="col-12">
        <div class="row text-left">
            <div class="col-sm-12">
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title">Ajouter un batiment</h4>
                    </div>
                    <div class="card-content">
                        <div class="card-body">
                            <form [formGroup]="editform" novalidate>
                                <div class="row">
                                    <div class="form-group col">
                                        <label for="nom">Nom du batiment</label>

                                        <input type="text" class="form-control" id="nom" formControlName="nom" required/>
                                        <small class="form-text text-muted danger" *ngIf="
                          !editform.get('nom').valid &&
                          (editform.get('nom').dirty || editform.get('nom').touched)
                        ">champ obligatoire</small>
                                    </div>
                                    <div class="form-group col">
                                        <label for="nom">Type du batiment</label>
                                        <select class="form-control" id="type" formControlName="type" (change)="type($event)" required>
                      <option value="Immeuble">Immeuble</option>
                      <option value="Minicite">Mini-cite</option>
                    </select>

                                        <small class="form-text text-muted danger" *ngIf="
                          !editform.get('type').valid &&
                          (editform.get('type').dirty ||
                            editform.get('type').touched)
                        ">champ obligatoire</small>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="form-group col" *ngIf="types == 'Immeuble'">
                                        <label for="nbretage">Nombre d'etage</label>
                                        <input type="number" class="form-control" id="nbretage" formControlName="nbretage" required />
                                        <small class="form-text text-muted danger" *ngIf="
                          !editform.get('nbretage').valid &&
                          (editform.get('nbretage').dirty ||
                            editform.get('nbretage').touched)
                        ">champ obligatoire</small>
                                    </div>
                                    <div class="form-group col">
                                        <label for="nbrappart">Nombre d'appartement</label>
                                        <input type="number" class="form-control" id="nbrappart" formControlName="nbrappart" required />
                                        <small class="form-text text-muted danger" *ngIf="
                          !editform.get('nbrappart').valid &&
                          (editform.get('nbrappart').dirty ||
                            editform.get('nbrappart').touched)
                        ">champ obligatoire</small>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="form-group col">
                                        <label for="region">region</label>
                                        <select class="form-control" id="region" formControlName="region" (change)="region($event)" required>
                      <option *ngFor="let reg of Region" value="{{ reg }}">{{
                          reg
                        }}</option>
                    </select>

                                        <small class="form-text text-muted danger" *ngIf="
                          !editform.get('region').valid &&
                          (editform.get('region').dirty ||
                            editform.get('region').touched)
                        ">champ obligatoire</small>
                                    </div>
                                    <div class="form-group col">
                                        <label for="ville">Ville</label>
                                        <select class="form-control" id="ville" formControlName="ville" (change)="option($event)" required>
                      <option *ngFor="let opt of options" value="{{ opt }}">{{
                          opt
                        }}</option>
                    </select>

                                        <small class="form-text text-muted danger" *ngIf="
                          !editform.get('ville').valid &&
                          (editform.get('ville').dirty ||
                            editform.get('ville').touched)
                        ">champ obligatoire</small>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="form-group col">
                                        <label for="adresse">adresse</label>

                                        <input type="text" class="form-control" id="adresse" formControlName="adresse" required />
                                        <small class="form-text text-muted danger" *ngIf="
                          !editform.get('adresse').valid &&
                          (editform.get('adresse').dirty ||
                            editform.get('adresse').touched)
                        ">champs obligatoire</small>
                                    </div>
                                    <div class="form-group col">
                                        <label for="tel">Telephone</label>

                                        <input type="number" class="form-control" id="tel" formControlName="tel" required />
                                        <small class="form-text text-muted danger" *ngIf="
                          !editform.get('tel').valid &&
                          (editform.get('tel').dirty || editform.get('tel').touched)
                        ">champ obligatoire</small>
                                    </div>

                                </div>

                                <div class="form-group text-center">
                                    <button type="button" class="btn bg-danger btn-raised mr-1 text-white" (click)="retour()">
                    Annuler
                  </button>
                                    <button type="button" class="btn bg-success btn-raised mr-1 text-white" [disabled]="!editform.valid" (click)="Onsubmit()">
                    Enregistrer
                  </button>
                                </div>
                            </form>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>