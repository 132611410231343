import { Component, OnInit, ViewChild } from '@angular/core';
import { FactureEauService } from './../../shared/services/factureEau/facture-eau.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import swal from 'sweetalert2';

@Component({
  selector: 'app-list-generale-eau',
  templateUrl: './list-generale-eau.component.html',
  styleUrls: ['./list-generale-eau.component.css']
})
export class ListGeneraleEauComponent implements OnInit {

  idBati: string;

  ELEMENT_DATA1: any;
  factureEau: any;
  facture: any;
  profil: string;

  filterValues = {};

  dataSource = new MatTableDataSource();
  displayedColumns: string[] = ['id', 'NumFactEau',  'TypeCompteur', 'Prix', 'Mois', 'Annee', 'Status', 'NumAppart', 'NomBatiment', 'action'];
  @ViewChild(MatSort, { static: true }) sorte: MatSort;
  @ViewChild( 'TableTwoPaginator' ) tableTwoPaginator: MatPaginator;

  filterSelectObj = [];
  constructor(private service: FactureEauService, public toastr: ToastrService, private router: Router
  ) {

    // Object to create Filter for
    this.filterSelectObj = [
      {
        name: 'NUMERO FACTURE',
        columnProp: 'NumFactEau',
        options: []
      }, {
        name: 'TYPE COMPTEUR',
        columnProp: 'TypeCompteur',
        options: []
      }, {
        name: 'MOIS',
        columnProp: 'Mois',
        options: []
      }, {
        name: 'ANNEE',
        columnProp: 'Annee',
        options: []
      }, {
        name: 'STATUT',
        columnProp: 'Status',
        options: []
      }, {
        name: 'APPARTEMENT',
        columnProp: 'NumAppart',
        options: []
      }, {
        name: 'BATIMENT',
        columnProp: 'NomBatiment',
        options: []
      },
    ]
  }

  ngOnInit() {
    const obj = localStorage.getItem('bt');
    const objJson = JSON.parse(obj);
    this.idBati = objJson.idBat;
    this.profil = objJson.profil;
    this.getRemoteData();
    console.log(this.idBati);
    // Overrride default filter behaviour of Material Datatable
    this.dataSource.filterPredicate = this.createFilter();
  }

  // Get Uniqu values from columns to build filter
  getFilterObject(fullObj, key) {
    const uniqChk = [];
    fullObj.filter((obj) => {
      if (!uniqChk.includes(obj[key])) {
        uniqChk.push(obj[key]);
      }
      return obj;
    });
    return uniqChk;
  }

  // Get remote serve data using HTTP call
  getRemoteData() {
    this.service.readAll(this.idBati, this.profil).subscribe((data1: any) => {
      this.factureEau = data1;
      if(this.factureEau.length < 1) {
          swal.fire("Info!", "Aucune facture n\'est disponible pour le moment, veuillez vous situez dans un appartement pour en créer une!", "info");
          this.router.navigate(['/listAppart']);
      } else {
        this.dataSource.data = data1;
        this.dataSource.sort = this.sorte;
        this.dataSource.paginator = this.tableTwoPaginator;
        console.log(data1);
          console.log(this.factureEau);
  
          this.filterSelectObj.filter((o) => {
            o.options = this.getFilterObject(this.factureEau, o.columnProp);
          });
      }
      
    });
    
  }

  refresh() {
    this.service.readAll(this.idBati, this.profil).subscribe((data1: any) => {
      this.factureEau = data1;
      this.dataSource.data = data1;
      this.dataSource.sort = this.sorte;
      this.dataSource.paginator = this.tableTwoPaginator;
    });
  }

  // Called on Filter change
  filterChange(filter, event) {
    //let filterValues = {}
    this.filterValues[filter.columnProp] = event.target.value.trim().toLowerCase()
    this.dataSource.filter = JSON.stringify(this.filterValues)
  }

  // Custom filter method fot Angular Material Datatable
  createFilter() {
    let filterFunction = function (data: any, filter: string): boolean {
      let searchTerms = JSON.parse(filter);
      let isFilterSet = false;
      for (const col in searchTerms) {
        if (searchTerms[col].toString() !== '') {
          isFilterSet = true;
        } else {
          delete searchTerms[col];
        }
      }

      console.log(searchTerms);

      let nameSearch = () => {
        let found = false;
        if (isFilterSet) {
          for (const col in searchTerms) {
            searchTerms[col].trim().toLowerCase().split(' ').forEach(word => {
              if (data[col].toString().toLowerCase().indexOf(word) != -1 && isFilterSet) {
                found = true
              }
            });
          }
          return found
        } else {
          return true;
        }
      }
      return nameSearch()
    }
    return filterFunction
  }


  // Reset table filters
  resetFilters() {
    this.filterValues = {}
    this.filterSelectObj.forEach((value, key) => {
      value.modelValue = undefined;
    })
    this.dataSource.filter = "";
  }

  deleteFactureEau(id) {
    this.service.find(id).subscribe(data => {
        this.facture = data;
        if (confirm(" Voulez-vous vraiment supprimer la facture "+this.facture.NumFactEau+" d\'eau?????")) {
          this.service.delete(id).subscribe(data1 => {
            this.refresh();
            this.toastr.error('Facture d\'eau supprimée.', 'Suppression!');
          });
        }
    })
  }

  statut(val, id) {
    if(val == 'Non reglee') {
      this.toastr.info('La fature a déjà le statut non payée.', 'Information');
    }
    this.service.find(id).subscribe(data => {
      this.facture = data;
      if ( this.facture.Status == 'Payee') {
        this.toastr.info('La fature est déjà payée.', 'Information');
      } else if ( this.facture.Status == 'Non reglee') {
        this.service.changeStatut(id, val).subscribe(data1 => {
          this.toastr.success('La fature a été payée.', 'Succès!');
          this.refresh();
        });
      }
    });
  }
}

