import { Component, OnInit,Inject} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PrestataireService } from 'app/shared/services/prestataire/prestataire.service';



@Component({
  selector: 'app-view-prest',
  templateUrl: './view-prest.component.html',
  styleUrls: ['./view-prest.component.css']
})
export class ViewPrestComponent implements OnInit {
  idprest;
  prestataire;
  Nom;
  Prenom;
  contact;
  dates;
  constructor(private service:PrestataireService,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<ViewPrestComponent>,
    ) { }

  ngOnInit(): void {
    this.idprest = this.data['id'];
    this.service.find(this.idprest).subscribe((data:any)=>{
      console.log(data);
      this.prestataire=data;
      this.Nom=this.prestataire.Nom;
      this.Prenom=this.prestataire.Prenom;
      this.contact=this.prestataire.Contact;
      this.dates=this.prestataire.DateAjout;
    })
  }

}
