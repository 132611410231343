<button routerLink="/listuser" style="margin-bottom: 0px; background-color: #007acc; color:white" class="btn btn-raised">
  Retour
</button>

<div class="row">
    <div class="card col-9" style="background-color: white;">
        <div class="card" style="width: 76%; background-color: white; margin-left: 100px; margin-top: 0px;">
            <div class="card-img">
                <img class="card-img-top img-fluid" style="background-color: white;" src="assets/img/gallery/yui.png" alt="">
            </div>
        </div>
        <div class="row">
            <div class="col-9">
                <div style="margin-top: 30px;">
                    <ul style="margin-bottom: 30px;" class="list-group">
                        <li style="background-color: #007acc; width: 130%;" class="list-group-item active">Appartement</li>
                    </ul>

                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-sm-6">
                                <h4><label> Numéro Appartement : </label><span class="element">{{NumAppart}}</span></h4>
                            </div>
                            <div class="dropdown-divider"></div>
                            <div class="col-sm-6">
                                <h4><label> Nombre de salons : </label> <span class="element">{{appartement.NombreSalon}}</span></h4>
                            </div>
                        </div>
                    </div>
                    <div class="container-fluid">
                        <div class="row">
                            <div class="dropdown-divider"></div>
                            <div class="col-sm-6">
                                <h4><label> Nombre de chambres : </label> <span class="element">{{appartement['NombreChambre']}} </span></h4>
                            </div>
                            <div class="dropdown-divider"></div>
                            <div class="col-sm-6">
                                <h4><label> Nombre de cuisines : </label> <span class="element">{{appartement['NombreCuisine']}}</span></h4>
                            </div>
                        </div>
                    </div>
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-sm-6">
                                <h4><label> Nombre de salles de bains : </label> <span class="element">{{appartement['NombreSalleBain']}}</span></h4>
                            </div>
                            <div class="dropdown-divider"></div>
                            <div class="col-sm-6">
                                <h4><label> Nombre de salles d'eau : </label> <span class="element">{{appartement['NombreSalleEau']}}</span></h4>
                            </div>
                        </div>
                    </div>
                    <div class="container-fluid">
                        <div class="row">
                            <div class="dropdown-divider"></div>
                            <div class="col-sm-6">
                                <h4><label> Montant Contrat : </label> <span class="element">{{contract['MontantBail']}}</span></h4>
                            </div>
                        </div>
                    </div>


                    <div class="dropdown-divider"></div>

                    <ul style="margin-bottom: 30px;" class="list-group">
                        <li style="background-color: #007acc; width: 130%;" class="list-group-item active">Locatare</li>
                    </ul>
                    <div class="container-fluid">
                        <div class="row">
                            <div class="dropdown-divider"></div>
                            <div class="col-sm-6">
                                <h4><label> Nom : </label> <span class="element">{{locataire['NomLocataire']}}</span></h4>
                            </div>
                            <div class="col-sm-6">
                                <h4><label> Prenom : </label> <span class="element">{{locataire['PrenomLocataire']}}</span></h4>
                            </div>
                        </div>
                    </div>
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-sm-6">
                                <h4><label> Téléphone    : </label> <span class="element">{{locataire['Telephone']}}</span></h4>
                            </div>
                            <hr/>
                            <div class="col-sm-6">
                                <h4><label> Email : </label> <span class="element">{{locataire['Email']}}</span></h4>
                            </div>
                        </div>
                    </div>
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-sm-6">
                                <h4><label> Metier : </label> <span class="element">{{locataire['Metier']}}</span></h4>
                            </div>
                            <div class="dropdown-divider"></div>
                            <div class="col-sm-6">
                                <h4><label> Metier : </label> <span class="element">{{locataire['CNI']}}</span></h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
    <div style="background-color: white;" class="col-3 single category">
        <h3 class="side-title">Actions</h3>
        <ul class="list-unstyled">
            <!-- <li><button class="btn  btn-lg btn-block btn-raised btn-sm font-weight-light" style="background-color: #007acc !important; color:whitesmoke !important;">Ajout Contrat</button></li> -->
            <li>
                <button routerLink="/modifycontbail" [queryParams]="{id:IdBail}" class="btn  btn-lg btn-block btn-raised btn-sm font-weight-light" style="background-color: #007acc !important;color:whitesmoke !important;">Modifier Contrat</button>
            </li>
            <li style="margin-top: 40px;">
                <div class="type-1 row justify-content-center div2">
                    <a routerLink="/listuser" class="btn btn-1">
                        <span class="txt">Retour</span>
                        <span class="round"><i class="fa fa-chevron-left" style="color: white;"></i></span>
                    </a>
                </div>
            </li>
            <!-- <li>
            <a>
                <div ngbDropdown>
                    <button class="btn btn-lg btn-block btn-raised btn-sm font-weight-light" id="dropdownBasic1" style="background-color: #007acc !important;color:whitesmoke !important;" ngbDropdownToggle>Ajouter une tache</button>
                    <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                        <button class="dropdown-item" routerLink="/Addcharge">Batiment  En general</button>
                        <button class="dropdown-item" routerLink="/Addcharge">Pour un appartement</button>
                    </div>
                </div>
            </a>
        </li> -->
        </ul>
    </div>

</div>