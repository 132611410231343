import { FactureEau } from './../../interface/factureEau';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FactureEauService } from './../../shared/services/factureEau/facture-eau.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-fact-eau-impayee',
  templateUrl: './fact-eau-impayee.component.html',
  styleUrls: ['./fact-eau-impayee.component.css']
})
export class FactEauImpayeeComponent implements OnInit {
  idbat:number
  idAppart: number;
  ELEMENT_DATA1: FactureEau[];
  factureEau: any;
  facture: any;

  rows = [];

  temp = [];
  temp1 = [];


  displayedColumns1: string[] = ['id', 'NumFactEau',  'TypeCompteur', 'Prix', 'DateLimitePayement', 'Status', 'action'];
  dataSource1 = new MatTableDataSource<FactureEau>(this.ELEMENT_DATA1)
  @ViewChild('MatSort1') sorte1: MatSort;
  @ViewChild( 'TableTwoPaginator' ) tableTwoPaginator: MatPaginator;

  
  constructor(private router: Router, private route: ActivatedRoute, private service: FactureEauService) { }

  ngOnInit(): void {
    this.idAppart = this.route.snapshot.params['idAppart'];
    this.refresh();
    
  }

  refresh() {
    this.idbat = JSON.parse(localStorage.getItem("bt"))["idBat"];
    this.service.facture_no_regle_all(this.idbat).subscribe((data:FactureEau[])=>{
      this.factureEau = data;
      this.temp1 = [...this.factureEau];
      this.dataSource1.data = data;
      this.dataSource1.sort = this.sorte1;
      this.dataSource1.paginator = this.tableTwoPaginator;
     // console.log(data);
        //console.log(this.factureEau);
    });
  }

  updateFilter2(event) {
    const val = event.target.value.toLowerCase();

    // filter our data
    const temp1 = this.temp1.filter(function (d) {
        return d.NumFactEau.toLowerCase().indexOf(val) !== -1 || !val;
    });

    // update the rows
    this.dataSource1.data = temp1;
  }

  deleteFactureEau(id) {
    this.service.find(id).subscribe(data => {
        this.facture = data;
        if (confirm(" Voulez-vous vraiment supprimer la facture "+this.facture.NumFactEau+" d\'eau?????")) {
          this.service.delete(id).subscribe(data1 => {
            this.refresh();
          });
        }
    })
  }

}
