import { ChargeResoluComponent } from './../../charge/charge-resolu/charge-resolu.component';

import { Routes, RouterModule } from '@angular/router';
import { AddContbailComponent } from 'app/cont_bail/add-contbail/add-contbail.component';
import { ListContbailComponent } from 'app/cont_bail/list-contbail/list-contbail.component';
import { ListUserComponent } from 'app/user/list-user/list-user/list-user.component';
import { ModifyUserComponent } from 'app/user/modify-user/modify-user/modify-user.component';
import { ModifyContbailComponent } from 'app/cont_bail/modify-contbail/modify-contbail.component';
import { AddUserComponent } from 'app/user/add-user/add-user/add-user.component';
import { ModifyReglageComponent } from './../../reglage/modify-reglage/modify-reglage.component';
import { AddReglageComponent } from './../../reglage/add-reglage/add-reglage.component';
import { LoginPageComponent } from 'app/pages/content-pages/login/login-page.component';
import { RegisterPageComponent } from 'app/pages/content-pages/register/register-page.component';
import { ViewContbailComponent } from 'app/cont_bail/view-contbail/view-contbail/view-contbail.component';
import { ListPrestComponent } from 'app/prestataire/list-prest/list-prest.component';
import { ModifyPrestComponent } from 'app/prestataire/modify-prest/modify-prest.component';
import { ViewPrestComponent } from 'app/prestataire/view-prest/view-prest.component';
import { AddBatComponent } from 'app/batiments/add-bat/add-bat.component';
import { ListesBatComponent } from 'app/batiments/listes-bat/listes-bat.component';
import { ModifyBatComponent } from 'app/batiments/modify-bat/modify-bat.component';
import { ViewBatComponent } from 'app/batiments/view-bat/view-bat.component';
import { AddChargComponent } from 'app/charge/add-charg/add-charg.component';
import { ListChargComponent } from 'app/charge/list-charg/list-charg.component';
import { ModifyChargComponent } from 'app/charge/modify-charg/modify-charg.component';
import { ViewChargeComponent } from 'app/charge/view-charge/view-charge.component';
import { AddPaiementComponent } from 'app/paiement/add-paiement/add-paiement.component';
import { ModifyPaiementComponent } from 'app/paiement/modify-paiement/modify-paiement.component';
import { DeletePaiementComponent } from 'app/paiement/delete-paiement/delete-paiement.component';
import { ListPaiementComponent } from 'app/paiement/list-paiementn/list-paiement/list-paiement.component';
import { AddApptComponent } from 'app/appartement/add-appt/add-appt.component';
import { ModifyApptComponent } from 'app/appartement/modify-appt/modify-appt.component';
import { ViewApptComponent } from 'app/appartement/view-appt/view-appt.component';
import { ListeApptComponent } from 'app/appartement/liste-appt/liste-appt.component';
import { AddFactelectComponent } from 'app/facture_electricite/add-factelect/add-factelect.component';
import { ListFactelectComponent } from 'app/facture_electricite/list-factelect/list-factelect.component';
import { ModifyFactelectComponent } from 'app/facture_electricite/modify-factelect/modify-factelect.component';
import { ViewFactelectComponent } from 'app/facture_electricite/view-factelect/view-factelect.component';
import { ListFacteauComponent } from 'app/facture_eau/list-facteau/list-facteau.component';
import { AddFacteauComponent } from 'app/facture_eau/add-facteau/add-facteau.component';
import { ModifyFacteauComponent } from 'app/facture_eau/modify-facteau/modify-facteau.component';
import { ViewFacteauComponent } from 'app/facture_eau/view-facteau/view-facteau.component';
import { FactEauImpayeeComponent } from 'app/Factureimpayee/fact-eau-impayee/fact-eau-impayee.component';
import { FactElectImpayeeComponent } from 'app/Factureimpayee/fact-elect-impayee/fact-elect-impayee.component';
import { AllUserComponent } from 'app/user/all-user/all-user/all-user.component';
import { AddusersComponent } from 'app/user/all-user/addusers/addusers.component';
import { ListGeneraleElectriciteComponent } from 'app/facture_electricite/list-generale-electricite/list-generale-electricite.component';
import { ListGeneraleEauComponent } from 'app/facture_eau/list-generale-eau/list-generale-eau.component';
import { AuthGuard } from '../auth/auth-guard.service';

//Route for content layout with sidebar, navbar and footer.

export const Full_ROUTES: Routes = [
  
  /*{
    path:'contbail',
    loadChildren: () => import('../../cont_bail/contbail.module').then(m => m.ContBailModule)
  },*/
  {path: 'dashboard',loadChildren: () => import('../../dashboard/dashboard.module').then(m => m.DashboardModule)},
  {path: 'calendar',loadChildren: () => import('../../calendar/calendar.module').then(m => m.CalendarsModule)},
  {path: 'charts',loadChildren: () => import('../../charts/charts.module').then(m => m.ChartsNg2Module)},
  {path: 'forms',loadChildren: () => import('../../forms/forms.module').then(m => m.FormModule)},
  {path: 'maps',loadChildren: () => import('../../maps/maps.module').then(m => m.MapsModule)},
  {path: 'tables',loadChildren: () => import('../../tables/tables.module').then(m => m.TablesModule)},
  {path: 'datatables',loadChildren: () => import('../../data-tables/data-tables.module').then(m => m.DataTablesModule)},
  {path: 'uikit',loadChildren: () => import('../../ui-kit/ui-kit.module').then(m => m.UIKitModule)},
  {path: 'components',loadChildren: () => import('../../components/ui-components.module').then(m => m.UIComponentsModule)},
  {path: 'pages',loadChildren: () => import('../../pages/full-pages/full-pages.module').then(m => m.FullPagesModule)},
  {path: 'cards',loadChildren: () => import('../../cards/cards.module').then(m => m.CardsModule)},
  {path: 'colorpalettes',loadChildren: () => import('../../color-palette/color-palette.module').then(m => m.ColorPaletteModule)},
  {path: 'chat',loadChildren: () => import('../../chat/chat.module').then(m => m.ChatModule)},
  {path: 'chat-ngrx',loadChildren: () => import('../../chat-ngrx/chat-ngrx.module').then(m => m.ChatNGRXModule)},
  {path: 'inbox',loadChildren: () => import('../../inbox/inbox.module').then(m => m.InboxModule)},
  {
    path: 'taskboard',
    loadChildren: () => import('../../taskboard/taskboard.module').then(m => m.TaskboardModule)
  },
  {
    path: 'taskboard-ngrx',
    loadChildren: () => import('../../taskboard-ngrx/taskboard-ngrx.module').then(m => m.TaskboardNGRXModule)
  },
  {
    path: 'player',
    loadChildren: () => import('../../player/player.module').then(m => m.PlayerModule)
  },
  {path:'addcontbail', component:AddContbailComponent, data:{title: 'AddContBail'}, canActivate: [AuthGuard]},
  {path:'listcontbail', component:ListContbailComponent, data:{title: 'ListContBail'},canActivate: [AuthGuard]},
  {path:'modifycontbail', component:ModifyContbailComponent, data:{title: 'ModifyContBail'},canActivate: [AuthGuard]},
  {path:'viewcontbail', component:ViewContbailComponent, data:{title:'ViewContBail'},canActivate: [AuthGuard]},

  {path:'listuser', component:ListUserComponent, data:{title: 'ListUser'},canActivate: [AuthGuard]},
  {path:'modifyuser', component:ModifyUserComponent, data:{title: 'ModifyUser'},canActivate: [AuthGuard]},
  {path:'addlocataire', component:AddUserComponent, data:{title: 'AddLocataire'},canActivate: [AuthGuard]},
  {path: 'all-user',component:AllUserComponent,canActivate: [AuthGuard]},
  {path: 'adduser',component:AddusersComponent,canActivate: [AuthGuard]},

  {path: 'ajoutAppart/:idBati',component: AddApptComponent,canActivate: [AuthGuard]},
  {path: 'modifAppart/:idAppart',component: ModifyApptComponent,canActivate: [AuthGuard]},
  {path: 'viewAppart/:idAppart',component: ViewApptComponent,canActivate: [AuthGuard]},
  {path: 'listAppart',component: ListeApptComponent,canActivate: [AuthGuard]},
  
  {path: 'ajoutFactElect/:idAppart',component: AddFactelectComponent,canActivate: [AuthGuard]},
  {path: 'listFactElect/:idAppart',component: ListFactelectComponent,canActivate: [AuthGuard]},
  {path: 'modifFactElect/:idFact',component: ModifyFactelectComponent,canActivate: [AuthGuard]},
  {path: 'viewFactElect/:idFact',component: ViewFactelectComponent,canActivate: [AuthGuard]},
  {path: 'factelectimp',component: FactElectImpayeeComponent,canActivate: [AuthGuard]},
  {path: 'listGeneralFactElect',component: ListGeneraleElectriciteComponent,canActivate: [AuthGuard]},

  {path: 'ajoutFactEau/:idAppart',component: AddFacteauComponent,canActivate: [AuthGuard]},
  {path: 'listFactEau/:idAppart',component: ListFacteauComponent,canActivate: [AuthGuard]},
  {path: 'modifFactEau/:idFact',component: ModifyFacteauComponent,canActivate: [AuthGuard]},
  {path: 'viewFactEau/:idFact',component: ViewFacteauComponent,canActivate: [AuthGuard]},
  {path: 'facteauimp',component: FactEauImpayeeComponent,canActivate: [AuthGuard]},
  {path: 'listGeneralFactEau',component: ListGeneraleEauComponent,canActivate: [AuthGuard]},


  {path: 'AddBatiment', component:AddBatComponent,canActivate: [AuthGuard]},
  {path: 'ListBatiment', component:ListesBatComponent,canActivate: [AuthGuard]},
  {path: 'ModifyBatiment/:id', component:ModifyBatComponent,canActivate: [AuthGuard]},
  {
    path: 'ViewBatiment', component:ViewBatComponent,canActivate: [AuthGuard]
  },


  {path: 'Addcharge/:id/:ref', component:AddChargComponent,canActivate: [AuthGuard]},
  {path: 'Listcharge', component:ListChargComponent,canActivate: [AuthGuard]},
  {path: 'Modifycharge/:id', component:ModifyChargComponent,canActivate: [AuthGuard]},
  {path: 'Viewcharge/:id', component:ViewChargeComponent,canActivate: [AuthGuard]},
  {path: 'Listprest/:id',component:ListPrestComponent,canActivate: [AuthGuard]},
  {path: 'Modifyprest/:id',component:ModifyPrestComponent,canActivate: [AuthGuard]},
  {path: 'Viewprest/:id',component:ViewPrestComponent,canActivate: [AuthGuard]},
  {path: 'listeChargeresolu/:id',component:ChargeResoluComponent,canActivate: [AuthGuard]},

  
  {path: 'addPaiement',component:AddPaiementComponent,canActivate: [AuthGuard]},
  //{path: 'modifyPaiement',component:ModifyPaiementComponent},
  {path: 'deletePaiement',component:DeletePaiementComponent,canActivate: [AuthGuard]},
  {path: 'listpaiement', component:ListPaiementComponent,canActivate: [AuthGuard]},
  
  { path: 'ajoutReglage', component: AddReglageComponent,canActivate: [AuthGuard]},
  {path: 'ModifReglage', component: ModifyReglageComponent,canActivate: [AuthGuard]},

  {path: 'login', component: LoginPageComponent}

];
