import { Batiment } from './../../batiments/batiment';
import {
  Component,
  OnInit,
  ViewChild,
  ViewEncapsulation,
  Input,
} from "@angular/core";
import { BatimentService } from "app/shared/services/batiment/batiment.service";
import { FormControl, Validators, NgForm } from "@angular/forms";

import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
//import {MatTableDataSource} from "@angular/material/table";

import {
  NgbModal,
  ModalDismissReasons,
  NgbActiveModal,
} from "@ng-bootstrap/ng-bootstrap";

import { Router, ActivatedRoute } from "@angular/router";
import { ordreTaf } from "../charge";
import { ChargeService } from "app/shared/services/charge/charge.service";
import { AppartementService } from "app/shared/services/appartement/appartement.service";
import { FormGroup } from "@angular/forms";
import { PrestataireService } from "app/shared/services/prestataire/prestataire.service";
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { UpdateChargeComponent } from '../update-charge/update-charge.component';

@Component({
  selector: "app-list-charg",
  templateUrl: "./list-charg.component.html",
  styleUrls: ["./list-charg.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class ListChargComponent implements OnInit {
  rows = [];
  temp = [];
  closeResult: string;
  form1: FormGroup;
  form2: FormGroup;
  valide: any;
  @ViewChild("f") floatingLabelForm: NgForm;
  @ViewChild("vform") validationForm: FormGroup;
  editform: FormGroup;
  mobnumPattern = "^[6]+[5|6|7|9|8]+[0-9]{7}$";
  nomb="^[0-9]$";
  unamePattern = "^[a|A-z|Z0-9_-]{5,20}$";
  IdTaf: number;
  prestataire: any;
  ELEMENT_DATA: ordreTaf[];
  batiments = [];
  bats = [];
  donnee = [];
  appartements = [];
  apps = [];
  displayedColumns: string[] = [
    "Categorie",
    "Priorite",
    "IdAppart",
    "DateOcc",
    "vue",
    "Statut",
  ];
  batId:string;
  myDrop: any;
  myDrop1: any;
  dataSource = new MatTableDataSource<ordreTaf>(this.ELEMENT_DATA);
  @ViewChild(MatSort, { static: true }) sorte: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  constructor(
    private service: ChargeService,
    private router: Router,
    private servbat: BatimentService,
    private servAppart: AppartementService,
    private modalService: NgbModal,
    private servprest: PrestataireService,
    private routel: ActivatedRoute,
    private dialog: MatDialog
  ) { }


  ngOnInit(): void {
  
    const obj = localStorage.getItem('bt');
    const objJson = JSON.parse(obj);
    this.batId = objJson.idBat;
    console.log(this.batId);
    this.refresh();
    this.service.readprest(this.batId).subscribe((data:any)=>{
      this.prestataire=data;
      console.log(data);
    })  

    // this.servprest.read().subscribe((data: any) => {
    //   this.prestataire = data;
    //   //console.log(data);
    // });
  }

  refresh() {
    this.servbat.read().subscribe((data: any) => {
      this.bats = data;
    });

    this.servAppart.reads().subscribe((data: any) => {
      this.apps = data;
    });

    this.service.readbat(this.batId).subscribe((data: ordreTaf[]) => {
      this.dataSource.data = data; 
      console.log(data)
      this.dataSource.paginator = this.paginator;

      this.dataSource.sort = this.sorte;
       console.log(this.dataSource.data);
      data.forEach((element, i) => {
        let doe = this.bats.find(
          (m) => parseInt(m["IdBatiment"]) == parseInt(element["IdBatiment"])
        );
        let does = this.apps.find(
          (m) => parseInt(m["IdAppart"]) == parseInt(element["IdAppart"])
        );

        if (doe == undefined) {
          this.donnee.push({});
        } else {
          this.donnee.push(doe.NomBatiment);
          this.dataSource.data[i].IdBatiment = doe.NomBatiment;
        }

        //  console.log(this.dataSource.data[i]);
        if (does == undefined) {
          this.appartements.push({});
        } else {
          this.appartements.push(does);
          this.dataSource.data[i].IdAppart = does.NumAppart;
        }
        // console.log(this.donnee);
        // console.log(this.appartements);
      });
    });

    this.form2 = new FormGroup(
      {
        prestataire: new FormControl("", [Validators.required]),
        cout: new FormControl("", [Validators.required,]),
        nom: new FormControl("", [
          Validators.required,
          Validators.pattern(this.unamePattern),
        ]),
        prenom: new FormControl("", [
          Validators.required,
          Validators.pattern(this.unamePattern)
        ]),
        tel: new FormControl("", [
          Validators.required,
          Validators.pattern(this.mobnumPattern)
        ]),
      },
      { updateOn: "blur" }
    );
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  public getAllReports() {
     const resp = this.service.readbat(toString[this.batId]);
     resp.subscribe(report => this.dataSource.data = report as ordreTaf[]);
 }
  Ondelete(id: number) {
    if (confirm("voulez-vous vraiment supprimer ce batiment??")) {
      this.service.delete(id).subscribe((data) => {
        console.log(data);
        this.refresh();
      });
    }
  }

  changestatut(id, status) {
    const obj = {
      IdTaf: id,
      statut: status,
    };
    console.log(obj);
    this.service.changestat(obj).subscribe(
      (res) => {
        this.refresh();
      },
      (error) => {
        console.log(error);
      }
    );
  }

  open(content, id: number) {
    this.IdTaf=id;
    let ide=this.routel.snapshot.params['id'];
    this.modalService.open(content).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  add() {
    this.prestataire = false;
    this.form2 = new FormGroup(
      {
        prestataire: new FormControl(null, [Validators.required]),
        cout: new FormControl(null, [Validators.required]),
        nom: new FormControl(null, [Validators.required]),
        prenom: new FormControl(null, [
          Validators.required,
          Validators.pattern(this.unamePattern),
        ]),
        tel: new FormControl(null, [Validators.required]),
      },
      { updateOn: "blur" }
    );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  getprestat(event) {
    let val = event.target.value;
    console.log(val);
  }
  Onsubmit(statut: string) {

    const obj = {
      Nom: this.form2.get("nom").value,
      Prenom: this.form2.get("prenom").value,
      Contact: this.form2.get("tel").value,
    };

    let cout= this.form2.get("cout").value;
    let prest= this.form2.get("prestataire").value;
    let prest2= this.form2.get("nom").value + ' ' + this.form2.get("prenom").value;
    let Statut= statut;
    let idTaf=this.IdTaf;

    if (prest) {
      this.service.updateordretravail(idTaf,cout,Statut,prest).subscribe((res) => {
        this.refresh();
      });
    }
    else {
      this.service.updateordretravail(idTaf,cout,Statut,prest2).subscribe((res) => {
        this.refresh();
      });

      console.log(obj)
      this.servprest.create(obj).subscribe((res) => {
        this.refresh();
      });
    }
  }

  openDialog(idTaf) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data ={id:idTaf} ;
    dialogConfig.height = '650px';
    dialogConfig.width = '700px';
    const dialogRef = this.dialog.open(UpdateChargeComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(() => {
      this.refresh();
});
  }
}
