import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { PaiementService } from 'app/shared/services/paiement/paiement.service';
import { ContratBailService } from 'app/shared/services/contratBail/contrat-bail.service';
import { Paiement } from 'app/interface/paiement';

@Component({
  selector: 'app-modify-paiement',
  templateUrl: './modify-paiement.component.html',
  styleUrls: ['./modify-paiement.component.css']
})
export class ModifyPaiementComponent implements OnInit {
  
  monpaiement:any
  MontantAppart:any
  constructor(private router:Router,
              private routes:ActivatedRoute,
              private servicePaiement:PaiementService,
              ) { }

  ngOnInit(): void {
    this.refresh()
  }

  editpaiement(){

  }

  refresh(){
      this.servicePaiement.readLocataire().subscribe((l:[])=>{this.locataires=l})
      this.servicePaiement.getPaiement().subscribe((paiement: Paiement[])=>{
        this.routes.queryParams.subscribe((p)=>{
          if(p && p['idpaiement']){
            this.monpaiement=paiement.find(m=>m['idpaiement']==p['idpaiement'])
            
            console.log(this.monpaiement)
          }
        })
      })
  }

  searchCont(e){

  }

  /*applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }*/


  paiement={
    contrat:"",
    montant:"",
    mois:"",
    annee:""
  }
  locataires=[]
  paiements=[]
  idpaiement:any
  date:any
  i=" "
}
