<div class="row">
    <div class="col-9">
        <div class="card" *ngIf="batiment">
            <div class="card-header">
                <h1><u>{{batiment.Type}}</u></h1>
            </div>

            <div class="px-3">
                <span class="text-center">
                    <img src="assets/img/bat2.jpg" class="card-img-top mx-auto d-block" style="width: 70%;" alt="Accroche HTML">

                </span>
                <div class="card-body text-center">
                    <h2 class="card-title"><u>Batiment {{batiment.NomBatiment}}</u></h2>

                    <span class="row">
                            <span class="col-6"> 
                                <i class="ft-map-pin h5"></i> <span class="text-lowercase"> {{ batiment.Adresse}},{{batiment.Ville}},{{batiment.Region}}</span>
                    </span>
                    <span class="col-6">
                                <i class="ft-smartphone h5"> </i> <span class=""><span class="font-weight-bold h6"> Contact du bailleur:</span><span class="text-reset">{{batiment.Telephone}}</span></span>
                    </span>
                    </span>
                    <span class="row">
                            <span class="col-6">
                                <i class="ft-bar-chart-2 h5"> </i> <span class=""> <span class="font-weight-bold h6"> Nombre d'étage:</span ><span class="text-reset">{{batiment.NombreEtage}} Etages</span></span>
                    </span>
                    <span class="col-6">
                                <i class="ft-home h5"> </i> <span class=""><span class="font-weight-bold h6"> Nombre d'Appart:</span><span class="text-reset">{{batiment.NombreAppart}} Apparts</span></span>
                    </span>
                    </span>
                    <span class="row">
                            <span class="col-6">
                                <i class="ft-layers h5"> </i> <span class=""><span class="font-weight-bold h6"> Appart Crée:</span><span class="text-reset">{{batiment.NbApCr}} Apparts</span></span>
                    </span>
                    <span class="col-6" *ngIf='batiment.statut=="ACTIF"'>
                                <i class="ft-circle h5 text-success"> </i> <span class=""><span class="font-weight-bold h6"> Statut:</span>
                    <span class="text-reset">{{batiment.statut}}</span>
                    </span>
                    </span>
                    <span class="col-6" *ngIf='batiment.statut=="NONACTIF"'>
                                <i class="ft-circle h5 text-warning"> </i> <span class=""><span class="font-weight-bold h6"> Statut:</span>
                    <span class="text-reset">{{batiment.statut}}</span>
                    </span>
                    </span>

                    </span>
                </div>
            </div>

        </div>
    </div>
    <div class="card col-3 div2">
        <div>
            <nav style="min-width: 210px;">
                <div>
                    <div class="card-title entete">
                        <h2>Actions</h2>
                    </div>

                    <ul class="card-body" style="list-style-type: none;">

                        <li>
                            <button class="btn  bg-primary btn-lg btn-block btn-raised btn-sm font-weight-light text-black" routerLink="/ModifyBatiment/{{batId}}" routerLinkActive="router-link-active">
                            <i class="ft-edit-2"></i> Modifier
                          </button>
                        </li>
                        <li>
                            <button class="btn  bg-danger btn-lg btn-block btn-raised btn-sm font-weight-light text-black" (click)="Ondelete()">
                          <i class="ft-trash"></i> Supprimer 
                        </button>
                        </li>
                        <li>
                            <button class="btn  bg-info btn-lg btn-block btn-raised btn-sm font-weight-light text-black" routerLink="/ajoutAppart/{{batId}}" routerLinkActive="router-link-active">
                          <i class="ft-plus"></i> Ajouter un appartement
                        </button>
                        </li>
                        <li>
                            <button class="btn  btn-lg btn-block btn-raised btn-sm font-weight-light text-black" routerLink="/listAppart" routerLinkActive="router-link-active" style="background-color: #c5c3c0d1;">
                          <i class="ft-list"></i> Listes de Appartements
                        </button>
                        </li>
                        <li>
                            <button class="btn  bg-warning btn-lg btn-block btn-raised btn-sm font-weight-light text-black" routerLink="/Listcharge">
                          <i class="ft-triangle"></i> Travaux
                        </button>
                        </li>
                        <li style="margin-top: 40px;">
                            <div class="type-1 row justify-content-center">
                                <a class="btn btn-1" (click)="retour()">
                                    <span class="txt">Retour</span>
                                    <span class="round"><i class="fa fa-chevron-left" style="color: white;"></i></span>
                                </a>
                            </div>
                        </li>

                    </ul>

                </div>
            </nav>
        </div>
    </div>
</div>