import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UrlConfigService } from '../urlConfig/url-config.service';

@Injectable({
  providedIn: 'root'
})
export class FactureEauService {

  url: any
  constructor(private httpClient: HttpClient,
              private urlConfig: UrlConfigService) {
                this.url = this.urlConfig.getUrl()
               }


 create(numFact: string, type: string, prix: number, indexInitial: number,
    indexFinal: number, prixM3: number, prixEntretienCompteur: number, dateLimitePayement: string, mois: string, annee: number,
     idAppart: number) {
    const obj = {
      NumFactEau: numFact,
      TypeCompteur: type,
      Prix: prix,
      IndexInitial: indexInitial,
      IndexFinal: indexFinal,
      PrixM3: prixM3,
      PrixEntretienCompteur: prixEntretienCompteur,
      DateLimitePayement: dateLimitePayement,
      Mois: mois,
      Annee: annee,
      IdAppart: idAppart
    };
    return this.httpClient.post(this.url + 'api_gesap/controllers/Facture_eau/create.php', obj);
  }

  numeroFacture(idAppart: number, NumFact: string) {
    return this.httpClient.post(this.url + 'api_gesap/controllers/Facture_eau/NumeroFacture.php',
    { idAppart: idAppart, numFact: NumFact});
  }

  read(idAppart: number) {
    return this.httpClient.post(this.url + 'api_gesap/controllers/Facture_eau/read.php', { id: idAppart })
  }

  readAll(idBati: string, Profil: string) {
    return this.httpClient.post(this.url + 'api_gesap/controllers/Facture_eau/readAllFacture.php', { id: idBati, profil: Profil })
  }

  find(idFact: number) {
    return this.httpClient.post(this.url + 'api_gesap/controllers/Facture_eau/find.php', { IdFactureEau: idFact})
  }

  delete(idFact: number) {
    return this.httpClient.post(this.url + 'api_gesap/controllers/Facture_eau/delete.php', { IdFactureEau: idFact })
  }
  
  update(idFact: number, numFact: string, prix: number, dateLimitePayement: string, datePaiement: string, mois: string, annee: number,
     indexFinal: number, statut: string) {
    const obj = {
      IdFactureEau: idFact,
      NumFactEau: numFact,
      Prix: prix,
      DateLimitePayement: dateLimitePayement,
      DatePaiement: datePaiement,
      Mois: mois,
      Annee: annee,
      IndexFinal: indexFinal,
      Status: statut
      
    };
    console.log(obj);
    return this.httpClient.post(this.url + 'api_gesap/controllers/Facture_eau/update.php', obj);
  }

  changeStatut(idFact: number, statut: string) {
   const obj = {
     IdFactureEau: idFact,
     Status: statut
   };
   return this.httpClient.post(this.url + 'api_gesap/controllers/Facture_eau/changeStatut.php', obj);
 }

  facture_regle(id)
  {
    return this.httpClient.post(this.url + 'api_gesap/controllers/Facture_eau/fact_regle.php', {id: id})
  }

  facture_no_regle(id)
  {
    return this.httpClient.post(this.url + 'api_gesap/controllers/Facture_eau/fact_no_regle.php', {id: id})
  }

  facture_no_regle_all(id)
  {
    return this.httpClient.post(this.url + 'api_gesap/controllers/Facture_eau/fact_no_regleAll.php', {id: id})
  }
}
