<!--Footer Starts-->
<footer>
    <div class="container-fluid">
        <p class="copyright text-center">
                Copyright  &copy;  {{currentDate | date: 'yyyy'}} <a target="blank" id="pixinventLink" href="https://www.jainliconsulting.com/">Intelligence Consulting</a>, Tous Droits Réserver.          
                <!-- //https://themeforest.net/user/pixinvent/portfolio?ref=pixinvent -->
        </p>
        
    </div>
</footer>
<!--Footer Ends-->