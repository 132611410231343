<!--Registration Page Starts-->
<section id="regestration">
    <div class="container-fluid">
      <div class="row full-height-vh m-0">
        <div class="col-12 d-flex align-items-center justify-content-center">
          <div class="card">
            <div class="card-content">
              <div class="card-body register-img">
                <div class="row m-0">
                  <div class="col-lg-6 d-none d-lg-block py-2 px-3 text-center">
                    <img
                      src="../../assets/img/gallery/regist.jpg"
                      alt=""
                      class="img-fluid mt-3 pl-3"
                      width="400"
                      height="230"
                    />
                  </div>
                  <div class="col-lg-6 col-md-12 pt-3 px-4 bg-white">
                    <form autocomplete="off">
                      <div class="row">
                            <div class="col-md-6 position-relative">
                              <label for="Nom">Nom</label>
                              <input
                                name="name"
                                type="text"
                                class="form-control mb-3 input-lg rounded-0"
                                [ngModel]="user['Nom_utilisateur']"
                                [(ngModel)]="newUser.Nom_utilisateur"
                                required=""
                              />
                            </div>
                            <div class="col-md-6 position-relative">
                              <label for="Nom">Prenom</label>
                              <input
                              name="surname"
                              type="text"
                              class="form-control mb-3"
                              [ngModel]="user['Prenom_utilisateur']"
                              [(ngModel)]="newUser.Prenom_utilisateur"
                              required=""
                            />
                            </div>
                      </div>
  
                      <div class="row">
                        <div class="col-md-12 position-relative">
                            <label for="nomuser">Nom Utilisateur</label>
                            <input
                            name="username"
                            type="text"
                            class="form-control mb-3"
                            [ngModel]="user['Login_utilisateur']"
                            [(ngModel)]="newUser.Login_utilisateur"
                            required=""
                            />
                        </div>
                      </div>
                    <div class="row">
                      <div class="col-md-6 position-relative">
                        <label for="pass">Mot De Passe</label>
                        <input
                        name="password"
                        type="password"
                          class="form-control mb-3 input-lg rounded-0"
                          [ngModel]="user['Pass_utilisateur']"
                          [(ngModel)]="newUser.Pass_utilisateur"
                          required=""
                        />
                      </div>
                      <div class="col-md-6 position-relative">
                        <label for="nomuser">Mot De Passe</label>
                          <input
                          name="confirmPassword"
                          type="password"
                          class="form-control mb-3 input-lg rounded-0"
                          [ngModel]="user['Pass_utilisateur']"
                          [(ngModel)]="newUser.Pass_utilisateur"
                          required=""
                        />
                      </div>
                    </div>
  
                    <div class="row">
                        <div class="col-md-6 position-relative">
                          <label for="nomuser">Téléphone</label>
                          <input
                              name="phone"
                              type="tel"
                              class="form-control mb-3 input-lg rounded-0"
                              [ngModel]="user['phone']"
                              [(ngModel)]="newUser.phone"
                              required=""
                            />
                        </div>
                        <div class="col-md-6 position-relative">
                          <label for="Email">Email</label>
                          <input
                          name="mail"
                          type="email"
                          class="form-control mb-3 input-lg rounded-0"
                          [ngModel]="user['email']"
                          [(ngModel)]="newUser.email"
                          required=""
                        />
                      </div>
                    </div>
                  <div class="row" style="margin-bottom: 10px;">
                    <div class="col-md-12 position-relative">
                      <label for="Email">Profil</label>
                      <select name="profil" [ngModel]="user['profilsID']" [(ngModel)]="newUser.profilsID" class="form-control input-lg rounded-0"  required="">
                            <option value="ADMINISTRATEUR">ADMINISTRATEUR</option>
                            <option value="BAILLEUR">BAILLEUR</option>
                      </select>
                    </div>
                  </div>
                    </form>
                    <div class="fg-actions d-flex justify-content-between">
                      <div class="login-btn">
                        <button (click)="adduser()" style="width:180px;" class="btn btn-outline-primary">
                            Ajouter
                        </button>
                      </div>
                      <div class="recover-pass">
                        <button style="width:180px;" (click)="redirect()" class="btn btn-danger btn-raised" >
                            Annuler
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!--Registration Page Ends-->
  