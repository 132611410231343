import { Injectable } from '@angular/core';
import { UrlConfigService } from '../urlConfig/url-config.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class FactureElectriciteService {

  url: any
  constructor(private httpClient: HttpClient,
              private urlConfig: UrlConfigService) {
                this.url = this.urlConfig.getUrl()
               }
create(numFact: string, type: string, prix: number, indexInitial: number,
    indexFinal: number, prixKWH: number, prixEntretienCompteur: number, dateLimitePayement: string, 
    mois: string, annee: number, idAppart: number) {
    const obj = {
      NumFactElect: numFact,
      TypeCompteur: type,
      Prix: prix,
      IndexInitial: indexInitial,
      IndexFinal: indexFinal,
      PrixKWH: prixKWH,
      PrixEntretienCompteur: prixEntretienCompteur,
      DateLimitePayement: dateLimitePayement,
      Mois: mois,
      Annee: annee,
      IdAppart: idAppart
    };
    console.log(obj);
    return this.httpClient.post(this.url + 'api_gesap/controllers/Facture_electricite/create.php', obj);
  }

  numeroFacture(idAppart: number, NumFact: string) {
    return this.httpClient.post(this.url + 'api_gesap/controllers/Facture_electricite/NumeroFacture.php',
    { idAppart: idAppart, numFact: NumFact});
  }

  read(idAppart: number) {
    return this.httpClient.post(this.url + 'api_gesap/controllers/Facture_electricite/read.php', { id: idAppart })
  }

  readAll(idBati: string, Profil: string) {
    return this.httpClient.post(this.url + 'api_gesap/controllers/Facture_electricite/readAllFacture.php', { id: idBati, profil: Profil })
  }

  find(idFact: number) {
    return this.httpClient.post(this.url + 'api_gesap/controllers/Facture_electricite/find.php', { idFactureElectricite: idFact})
  }

  delete(idFact: number) {
    return this.httpClient.post(this.url + 'api_gesap/controllers/Facture_electricite/delete.php', { idFactureElectricite: idFact })
  }
  
  update(idFact: number, numFact: string, prix: number, dateLimitePayement: string, datePaiement: string, mois: string, annee: number,
          indexFinal: number, statut: string) {
    const obj = {
      idFactureElectricite: idFact,
      NumFactElect: numFact,
      Prix: prix,
      DateLimitePayement: dateLimitePayement,
      DatePaiement: datePaiement,
      Mois: mois,
      Annee: annee,
      IndexFinal: indexFinal,
      Status: statut
      
    };
    console.log(obj);
    return this.httpClient.post(this.url + 'api_gesap/controllers/Facture_electricite/update.php', obj);
  }

  changeStatut(idFact: number, statut: string) {
    const obj = {
      idFactureElectricite: idFact,
      Status: statut
    };
    return this.httpClient.post(this.url + 'api_gesap/controllers/Facture_electricite/changeStatut.php', obj);
  }

  facture_regle(id)
  {
    return this.httpClient.post(this.url + 'api_gesap/controllers/Facture_electricite/fact_regle.php', {id: id})
  }

  facture_no_regle(id)
  {
    return this.httpClient.post(this.url + 'api_gesap/controllers/Facture_electricite/fact_no_regle.php', {id: id})
  }
 
  facture_no_regleAll(id)
  {
    return this.httpClient.post(this.url + 'api_gesap/controllers/Facture_electricite/fact_no_regleAll.php', {id: id})
  }
}
