
import { ToastrService } from 'ngx-toastr';
import { Injectable } from '@angular/core';

@Injectable()
export class NGXToastrService {
    constructor(public toastr: ToastrService) { }

    // Success Type
    typeSuccess() {
        this.toastr.success('Ajout Reussit Avec Succèss !', 'Succès');
    }
    // Success Type
    typeError() {
        this.toastr.error('Veuillez Renseigner Les Champs !', 'Erreur');
    }

    typeTelephone(){
        this.toastr.error('Téléphone Non Valide !', 'Erreur')
    }
    typeEmail(){
        this.toastr.error('Email Non Valide !', 'Erreur')
    }
}