<div class="row">
    <div class="col-9">
        <section id="filter" class="mb-3">
            <div class="row">
                <div class="col-sm-12">
                    <div style="margin-top:30px">
                        <span class="h1 float-left">Facture d'electricité Impayées</span>
                        <input class="float-right" type='text' style='padding:8px;margin:5px auto;width:30%;' placeholder='Rechercher...' (keyup)='updateFilter($event)' />

                    </div>
                    <table mat-table matSort [dataSource]="dataSource" class="mat-elevation-z8" style="width: 100%;">
                        <ng-container matColumnDef="id">
                            <th mat-header-cell *matHeaderCellDef scope="col" mat-sort-header> # </th>
                            <td mat-cell *matCellDef="let i=index">
                                {{i+1}} </td>
                        </ng-container>
                        <ng-container matColumnDef="NumFactElect">
                            <th mat-header-cell *matHeaderCellDef scope="col" mat-sort-header> Numéro de la fature </th>
                            <td mat-cell *matCellDef="let element" routerLink="/viewFactElect/{{element.idFactureElectricite}}">
                                {{element.NumFactElect}} </td>
                        </ng-container>
                        <ng-container matColumnDef="TypeCompteur">
                            <th mat-header-cell *matHeaderCellDef scope="col" mat-sort-header> Type de compteur </th>
                            <td mat-cell *matCellDef="let element">
                                {{element.TypeCompteur}} </td>
                        </ng-container>
                        <ng-container matColumnDef="Prix">
                            <th mat-header-cell *matHeaderCellDef scope="col" mat-sort-header> Prix </th>
                            <td mat-cell *matCellDef="let element">
                                {{element.Prix}} </td>
                        </ng-container>
                        <ng-container matColumnDef="DateLimitePayement">
                            <th mat-header-cell *matHeaderCellDef scope="col" mat-sort-header> Date limite de payements </th>
                            <td mat-cell *matCellDef="let element">
                                {{element.DateLimitePayement}} </td>
                        </ng-container>
                        <ng-container matColumnDef="Status">
                            <th mat-header-cell *matHeaderCellDef scope="col" mat-sort-header> Statut </th>
                            <td mat-cell *matCellDef="let element">
                                {{element.Status}} </td>
                        </ng-container>
                        <ng-container matColumnDef="action">
                            <th mat-header-cell *matHeaderCellDef scope="col" mat-sort-header> Actions </th>
                            <td mat-cell *matCellDef="let element">
                                <a class="info p-0" data-original-title="" title="Vue" routerLinkActive="active" routerLink="/viewFactElect/{{element.idFactureElectricite}}">
                                    <i class="ft-eye font-medium-3 mr-2"></i>
                                </a>
                                <a class="success p-0" data-original-title="" title="Modifier" routerLinkActive="active" routerLink="/modifFactElect/{{element.idFactureElectricite}}">
                                    <i class="ft-edit-2 font-medium-3 mr-2"></i>
                                </a>
                                <a class="danger p-0" data-original-title="" title="Supprimer" (click)="delete(element.idFactureElectricite)">
                                    <i class="ft-trash font-medium-3 mr-2"></i>
                                </a>
                            </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                    <mat-paginator #TableOnePaginator="matPaginator" [pageSize]="5" [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
                </div>

            </div>
        </section>
    </div>
    <div class="card col-3 div2">
        <div>
            <nav style="min-width: 210px;">
                <div>
                    <div class="card-title entete">
                        <h2>Actions</h2>
                    </div>

                    <ul class="card-body" style="list-style-type: none;">
                        <li>
                            <button class="btn  btn-lg btn-block btn-success btn-raised btn-sm font-weight-light" routerLink="/listAppart">
                        <i class="ft-list"></i> Liste des appartements
                      </button>
                        </li>
                        <li style="margin-top: 40px;">
                            <div class="type-1 row justify-content-center">
                                <a routerLink="/dashboard/dashboard2" class="btn btn-1">
                                    <span class="txt">Retour</span>
                                    <span class="round"><i class="fa fa-chevron-left" style="color: white;"></i></span>
                                </a>
                            </div>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
    </div>
</div>

<!-- Filter Datatable Options Ends -->