<input type='text' style='padding:8px;margin:15px auto;width:100%;' placeholder='Rechercher...' (keyup)="applyFilter($event)">

<mat-card>

    <div>
        <span class="dt-responsive table-responsive-xl">
                <table mat-table class="table" [dataSource]="dataSource" matSort style="width: 100%;">
                    <!-- Name Column -->
                    <ng-container matColumnDef="nombatiment">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col" class="thead-dark1">IdBatiment </th>
                        <td mat-cell *matCellDef="let element">
                            {{element.NomBatiment}} </td>
                    </ng-container>
                    <!-- Symbol Column -->
                    <ng-container matColumnDef="region">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col" class="thead-dark1"> Région </th>
                        <td mat-cell *matCellDef="let element"> {{element.Region}} </td>
                    </ng-container>
    
                    <ng-container matColumnDef="ville">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col" class="thead-dark1"> Ville </th>
                        <td mat-cell *matCellDef="let element"> {{element.Ville}} </td>
                    </ng-container>
    
                    <ng-container matColumnDef="adresse">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col" class="thead-dark1">Adresse </th>
                        <td mat-cell *matCellDef="let element"> {{element.Adresse}} </td>
                    </ng-container>
    
                    <ng-container matColumnDef="telephone">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col" class="thead-dark1">Telephone </th>
                        <td mat-cell *matCellDef="let element"> {{element.Telephone}} </td>
                    </ng-container>
    
                    <ng-container matColumnDef="nombreappart">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col" class="thead-dark1"> NombreAppart </th>
                        <td mat-cell *matCellDef="let element"> {{element.NombreAppart}} </td>
                    </ng-container>
    
                    <ng-container matColumnDef="type">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col" class="thead-dark1"> Type </th>
                        <td mat-cell *matCellDef="let element"> {{element.Type}} </td>
                    </ng-container>
    
                    <ng-container matColumnDef="nombreetage">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col" class="thead-dark1"> NombreEtage </th>
                        <td mat-cell *matCellDef="let element"> {{element.NombreEtage}} </td>
                    </ng-container>
    
                    <ng-container matColumnDef="statut">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col" class="thead-dark1"> statut </th>
                        <td mat-cell *matCellDef="let element"> {{element.statut}} </td>
                    </ng-container>
                    <ng-container matColumnDef="action">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col" class="thead-dark1"> action </th>
                        <td mat-cell *matCellDef="let element">
                            <a class="info p-0" data-original-title="" title="Vue" routerLinkActive="active" [queryParams]="{idBat:element.IdBatiment}" routerLink="/ViewBatiment">
                                <i class="ft-eye font-medium-3 mr-2"></i>
                              </a>
                              <a class="success p-0" data-original-title="" routerLink="/ModifyBatiment/{{element.IdBatiment}}" title="" >
                                <i class="ft-edit-2 font-medium-3 mr-2"></i>
                              </a>
                              <a class="danger p-0" data-original-title="" title="" (click)='Ondelete(element.IdBatiment)' >
                                <i class="ft-trash font-medium-3 mr-2"></i>
                              </a>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"  routerLinkActive="router-link-active" ></tr>
                </table>
            </span>
        <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
    </div>

</mat-card>