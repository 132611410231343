<!--Login Page Starts-->
<section id="add-user">
    <div class="container-fluid">
      <div class="row full-height-vh m-0">
        <div class="col-12 d-flex align-items-center justify-content-center">
            <button routerLink="/listpaiement" style="margin-right: -65px; margin-bottom: 520px; background-color: #007acc; color:white" class="btn btn-raised">
                Retour
            </button>    
            <div class="card">
            <div class="card-content">
              <div class="card-body login-img">
                <div class="row m-0">
                  <div
                    class="col-lg-6 d-lg-block d-none py-2 px-3 text-center align-middle"
                  >
                    <img
                      src="../../assets/img/gallery/79.png"
                      alt=""
                      class="img-fluid mt-5"
                      width="400"
                      height="230"
                    />
                  </div>
                  <div class="col-lg-6 col-md-12 bg-white px-4 pt-3">
                    <h4 class="card-title mb-2">PAIEMENT</h4>
                    <p class="card-text mb-3">
                      Renseinger Vos Informations Ci-dessous
                    </p>
                    <form>

                        <div class="row" style="margin-bottom: 15px;">
                            <div class="col-md-12 position-relative">
                                <select
                                (change)="searchCont($event.target.value)"
                                [(ngModel)]="paiement.contrat"
                                name="locataire"
                                class="form-control input-lg rounded-0"
                                placeholder="Locataire"
                                required="">
                                <option value=""></option>
                                <option *ngFor="let l of locataires" value={{l.IdLocataire}}>{{l['NomLocataire']}}{{i}}{{l['PrenomLocataire']}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 position-relative">
                                <input
                                [ngModel]="MontantAppart"
                                [(ngModel)]="paiement.montant"
                                disabled
                                type="number"
                                class="form-control mb-3 input-lg rounded-0"
                                placeholder="Montant"
                                required=""
                                name="montant"
                              />
                              <i class="fa fa-money fa-lg position-absolute"></i>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 position-relative">
                              <input
                              [(ngModel)]="date"
                              type="month"
                              class="form-control mb-3 input-lg rounded-0"
                              placeholder="Mois"
                              required=""
                              name="mois"  
                            />
                          </div>
                        </div>
                  </form>
                    <div class="d-flex justify-content-between mt-2">
                      <div class="remember-me">
                      </div>
                    </div>
                    <div class="fg-actions d-flex justify-content-between">
                      <div class="recover-pass">
                        <button style="width: 150px;" class="btn btn-success btn-raised"
                        (click)="addpaiement()"
                        >
                        <i class="fa fa-plus fa-lg"></i>
                          <a
                            class="text-decoration-none text-white"
                            > Ajouter</a
                          >
                        </button>
                      </div>
                      <div class="recover-pass">
                        <button style="width: 150px;" class="btn btn-danger btn-raised"
                        routerLink="/listpaiement"
                        >
                        <i class="fa fa-times fa-lg"></i>
                          <a
                            class="text-decoration-none text-white"
                            > Annuler</a
                          >
                        </button>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!--Login Page Ends-->
  