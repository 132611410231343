import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-delete-paiement',
  templateUrl: './delete-paiement.component.html',
  styleUrls: ['./delete-paiement.component.css']
})
export class DeletePaiementComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
