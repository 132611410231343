<div class="row">
    <div class="col-9">
      <mat-form-field *ngFor="let filter of filterSelectObj" style="margin-left: 15px;">
        <mat-label>Filtre {{filter.name}}</mat-label>
        <select matNativeControl name="{{filter.columnProp}}" [(ngModel)]="filter.modelValue"
          (change)="filterChange(filter,$event)">
          <option value="">-- Sélectionner {{filter.name}} --</option>
          <option [value]="item" *ngFor="let item of filter.options">{{item}}</option>
        </select>
      </mat-form-field>
      &nbsp;
      <button mat-flat-button color="warn" (click)="resetFilters()">Réinitialiser</button>
      
      <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8" style="width: 100%;">

        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef> # </th>
          <td mat-cell *matCellDef="let i=index">
            {{i+1}} </td>
        </ng-container>
    
        <ng-container matColumnDef="NumFactEau">
          <th mat-header-cell *matHeaderCellDef scope="col" mat-sort-header> Numéro fature </th>
          <td mat-cell *matCellDef="let element" routerLink="/viewFactEau/{{element.IdFactureEau}}"> {{element.NumFactEau}} </td>
        </ng-container>
    
        <ng-container matColumnDef="TypeCompteur">
          <th mat-header-cell *matHeaderCellDef scope="col" mat-sort-header> Type compteur </th>
          <td mat-cell *matCellDef="let element"> {{element.TypeCompteur}} </td>
        </ng-container>
    
        <ng-container matColumnDef="Prix">
          <th mat-header-cell *matHeaderCellDef scope="col" mat-sort-header> Prix </th>
          <td mat-cell *matCellDef="let element"> {{element.Prix}} </td>
        </ng-container>
    
        <ng-container matColumnDef="Mois">
          <th mat-header-cell *matHeaderCellDef scope="col" mat-sort-header> Mois </th>
          <td mat-cell *matCellDef="let element"> {{element.Mois}} </td>
        </ng-container>

        <ng-container matColumnDef="Annee">
          <th mat-header-cell *matHeaderCellDef scope="col" mat-sort-header> Année </th>
          <td mat-cell *matCellDef="let element"> {{element.Annee}} </td>
        </ng-container>
    
        <ng-container matColumnDef="Status">
          <th mat-header-cell *matHeaderCellDef scope="col" mat-sort-header> Statut </th>
          <td mat-cell *matCellDef="let element"> {{element.Status}} </td>
        </ng-container>
        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef scope="col" mat-sort-header> Actions </th>
          <td mat-cell *matCellDef="let element"> 
            <a class="info p-0" data-original-title="" title="Vue" routerLinkActive="active" routerLink="/viewFactEau/{{element.IdFactureEau}}">
              <i class="ft-eye font-medium-3 mr-2"></i>
            </a>
            <a class="success p-0" data-original-title="" title="Modifier" routerLinkActive="active" routerLink="/modifFactEau/{{element.IdFactureEau}}">
              <i class="ft-edit-2 font-medium-3 mr-2"></i>
            </a>
            <a class="danger p-0" data-original-title="" title="Supprimer" (click)="deleteFactureEau(element.IdFactureEau)" >
              <i class="ft-trash font-medium-3 mr-2"></i>
            </a>
            <div class="dropdown btn-group dropup mr-1 mb-1" ngbDropdown style="padding-top: 10px;">
              <button type="button" class="btn btn-success btn-raised">
                Statut
              </button>
              <button type="button" class="btn btn-danger btn-raised dropdown-toggle" ngbDropdownToggle aria-haspopup="true" aria-expanded="false">
                <span class="sr-only">Toggle Dropdown</span>
              </button>
              <div ngbDropdownMenu class="dropdown-menu" role="menu">
                  <a class="dropdown-item" (click)="statut('Payee', element.IdFactureEau)">Payée</a>
                  <a class="dropdown-item" (click)="statut('Non reglee', element.IdFactureEau)">Non réglée</a>
              </div>
            </div>
          </td>
        </ng-container>
    
        <tr mat-header-row *matHeaderRowDef="displayedColumns;"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    
      </table>
      <mat-paginator
                  #TableTwoPaginator="matPaginator"
                  [pageSize]="5"
                  [pageSizeOptions]="[5, 10, 25, 100]"
      ></mat-paginator>
    </div>
    <div class="card col-3 div2" >
      <div>
        <nav>
            <div >
              <div class="card-title entete">
                <h2>Actions</h2>
              </div>
                
                <ul class="card-body" style="list-style-type: none;">
                    <li>
                      <button class="btn  btn-lg btn-block btn-success btn-raised btn-sm font-weight-light"  routerLink="/ajoutFactEau/{{idAppart}}">
                        <i class="ft-plus"></i> Ajouter
                      </button>
                    </li>

                    <li style="margin-top: 40px;">
                      <div class="type-1 row justify-content-center">
                          <a routerLink="/viewAppart/{{idAppart}}" class="btn btn-1">
                            <span class="txt">Retour</span>
                            <span class="round"><i class="fa fa-chevron-left" style="color: white;"></i></span>
                          </a>
                        </div>
                  </li>
                </ul>
            </div>
        </nav>
      </div>
    </div>
</div>
