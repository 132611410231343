import { BatimentService } from './../../shared/services/batiment/batiment.service';
import { AppartementService } from './../../shared/services/appartement/appartement.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';



@Component({
  selector: 'app-add-appt',
  templateUrl: './add-appt.component.html',
  styleUrls: ['./add-appt.component.css']
})
export class AddApptComponent implements OnInit {

  form: FormGroup;
  idBati: number;
  nomBati: string;
  appart = '^[a|A-z|Z0-9]{5,20}$';
  
  constructor(private formBuilder: FormBuilder, private router: Router, private route: ActivatedRoute,
              private service: AppartementService, private serviceBatiment: BatimentService, public toastr: ToastrService) { }

  ngOnInit(): void {
    this.idBati = this.route.snapshot.params['idBati'];
    this.serviceBatiment.find(this.idBati).subscribe(data => {
      console.log(data);
        this.nomBati = data['NomBatiment'];
    });
    this.initForm();
  }

  initForm() {
    this.form = this.formBuilder.group({
      numAppart: ['', [Validators.required, Validators.pattern(this.appart)]],
      nbreSalon: ['', [Validators.required]],
      nbreChambre: ['', [Validators.required]],
      nbreCuisine: ['', [Validators.required]],
      nbreSalleEau: ['', [Validators.required]],
      nbreSalleBain: ['', [Validators.required]]
    });
  }

  onSubmitForm() {
    const numAppart = this.form.get('numAppart').value;
    const nbreSalon = this.form.get('nbreSalon').value;
    const nbreChambre = this.form.get('nbreChambre').value;
    const nbreCuisine = this.form.get('nbreCuisine').value;
    const nbreSalleEau = this.form.get('nbreSalleEau').value;
    const nbreSalleBain = this.form.get('nbreSalleBain').value;
    //console.log(numAppart, nbreSalon, nbreCuisine, nbreChambre, nbreSalleBain, nbreSalleEau, this.idBati);

    this.service.numeroAppartement(this.idBati, numAppart).subscribe(data => {
      if(data == 1) {
          if(confirm('Ce numéro existe déjà. \n\n Voulez-vous le modifier?????')) {
              
          } else {
            alert("Les informations de l\'appartement n\'ont pas été enregistré");
            this.router.navigate(['/listAppart']);
          }
      } else {
          this.service.create(numAppart, nbreSalon, nbreCuisine, nbreChambre, nbreSalleBain, nbreSalleEau, this.idBati).subscribe(data => {
            this.router.navigate(['/listAppart']);
            this.toastr.success('Appartement crée!', 'Succèss!');
        });
      }
  });
    

  }

}
