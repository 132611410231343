<div class="row">
    <div class="card col-9 mb-3">
        <div class="card-img">
            <img class="card-img-top img-fluid" src="assets/img/archiving.png" alt="Card image cap">
        </div>
        <h4 class="card-title text-dark text-center" style="margin-top:5px;">Ordre de travail</h4>
        <span>
            <div class="card-body text-center" *ngIf='statut=="attente"'>
                <div class="row">
                    <div class="col">
                        <p class=""><span class="card-text  font-weight-bold h6"><i class="fa fa-building-o" aria-hidden="true"></i> Batiment</span><br><span class="text-reset">{{Nombat}}</span></p>
        <p><span class="card-text  font-weight-bold h6"><i class="fa fa-home" aria-hidden="true"></i> Appartement</span><br><span class="text-reset">{{numapp}}</span></p>
    </div>
    <div class="col">
        <p class=""><span class="card-text  font-weight-bold h6"><i class="fa fa-sitemap" aria-hidden="true"></i> Categorie</span><br><span class="text-reset">{{categorie}}</span></p>
        <p>
            <span class="card-text  font-weight-bold h6">
                            <i class="fa fa-exchange fa-rotate-90"></i>
                            Priorite:</span><br>
            <span class="text-reset">bas</span>
            <span *ngIf="priorite==2" class="text-reset">Moyen</span>
            <span *ngIf="priorite==3" class="text-reset">Haute</span>
        </p>
    </div>
    <div class="col">
        <p><span class="card-text font-weight-bold h6"><i class="fa fa-calendar" aria-hidden="true"></i> Déclarée le</span><br><span class="text-reset">{{DateOcc}}</span></p>
        <p class=""><span class="card-text  font-weight-bold h6"><i class="fa fa-newspaper-o" aria-hidden="true"></i> Description</span><br><span class="text-reset"> {{Description}}</span></p>
    </div>
</div>
<p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
</div>
<div class="card-body text-center" *ngIf='statut=="resolu"'>
    <div class="row">
        <div class="col">
            <p class=""><span class="card-text  font-weight-bold h6"><i class="fa fa-building-o" aria-hidden="true"></i> Batiment</span><br><span class="text-reset">{{Nombat}}</span></p>
            <p class=""><span class="card-text  font-weight-bold h6"><i class="fa fa-sitemap" aria-hidden="true"></i> Categorie</span><br><span class="text-reset">{{categorie}}</span></p>
            <p class=""><span class="card-text  font-weight-bold h6"><i class="fa fa-newspaper-o" aria-hidden="true"></i> Description</span><br><span class="text-reset"> {{Description}}</span></p>
        </div>
        <div class="col">
            <p><span class="card-text  font-weight-bold h6"><i class="fa fa-home" aria-hidden="true"></i> Appartement</span><br><span class="text-reset">{{numapp}}</span></p>
            <p>
                <span class="card-text  font-weight-bold h6">
                                <i class="fa fa-exchange fa-rotate-90"></i>
                                Priorite:</span><br>
                <span class="text-reset">bas</span>
                <span *ngIf="priorite==2" class="text-reset">Moyen</span>
                <span *ngIf="priorite==3" class="text-reset">Haute</span>
            </p>
            <p><span class="card-text font-weight-bold h6"><i class="fa fa-calendar" aria-hidden="true"></i> Déclarée le</span><br><span class="text-reset">{{DateOcc | date:'EEEE d MMMM y'}}</span></p>
        </div>
        <div class="col">
            <p class=""><span class="card-text font-weight-bold h6"><i class="fa fa-circle-thin" aria-hidden="true"></i> Statut</span><br><span class="text-reset"> {{statut}}</span></p>
            <p class=""><span class="card-text font-weight-bold h6"><i class="fa fa-calendar-o" aria-hidden="true"></i> Résolu le</span><br><span class="text-reset">{{DateResol | date:'EEEE d MMMM y'}}</span></p>
            <p class=""><span class="card-text font-weight-bold h6 "><i class="fa fa-user" aria-hidden="true"></i> Prestataire</span><br><span class="text-reset">{{NomPrestataire}}</span></p>
        </div>
    </div>
</div>
</span>

</div>
<div class="card col-3 div2">
    <div>
        <nav>
            <div>
                <div class="card-title entete">
                    <h2>Actions</h2>
                </div>

                <ul class="card-body" style="list-style-type: none;">

                    <li>
                        <button class="btn  bg-primary btn-lg btn-block btn-raised btn-sm font-weight-light text-white" routerLink="/Modifycharge/{{idtaf}}" routerLinkActive="router-link-active">
                            <i class="ft-edit-2"></i> Modifier
                        </button>
                    </li>
                    <li>
                        <button class="btn  bg-danger btn-lg btn-block btn-raised btn-sm font-weight-light text-white" (click)="Ondelete()">
                          <i class="ft-trash"></i> Supprimer 
                        </button>
                    </li>
                    <li *ngIf='statut=="resolu"'>
                        <button class="btn  bg-info btn-lg btn-block btn-raised btn-sm font-weight-light text-white" (click)="openDialog()">
                          <i class="ft-user"></i> Info du Prestataire 
                        </button>
                    </li>
                    <li style="margin-top: 40px;">
                        <div class="type-1 row justify-content-center">
                            <a class="btn btn-1" routerLink="/Listcharge">
                                <span class="txt">Retour</span>
                                <span class="round"><i class="fa fa-chevron-left" style="color: white;"></i></span>
                            </a>
                        </div>
                    </li>

                </ul>

            </div>
        </nav>
    </div>
</div>
</div>