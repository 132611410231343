
<!--Toastr Starts-->
<section id="regestration">
    <div class="container-fluid">
        <div class="row full-height-vh m-0">
            <div class="col-12 d-flex align-items-center justify-content-center">
                <div class="card">
                    <div class="card-content">
                      <div class="card-body">
                        <div class="row m-0">
                          <div class="col-lg-12 col-md-12">
                            <h4 class="card-title mb-2">Modifier une facture d'eau</h4>
                            <ng-container *ngIf="form" >
                                <form [formGroup]="form" (ngSubmit)="onSubmitForm()">
                                  <div class="row" *ngIf="type == 'Forfait'" >
                                    <div class="col-6">
                                      <div class="form-group">
                                        <label for="idAppart" class="control-label mb-1">Appartement</label>
                                        <div class="position-relative has-icon-left">
                                          <input type="text" id="idAppart" class="form-control" [value]="nomAppart" disabled>
                                            <div class="form-control-position">
                                                <i class="fa fa-institution"></i>
                                            </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col-6">
                                      <div class="form-group" >
                                        <label for="moisAnnee" class="control-label mb-1">Facture du Mois</label>
                                        <div class="position-relative has-icon-left">
                                          <input  id="moisAnnee" type="month" class="form-control" aria-required="true" aria-invalid="false" formControlName="moisAnnee" required>
                                            <div class="form-control-position">
                                                <i class="ft-calendar h5"></i>
                                            </div>
                                        </div>
                                        <span *ngIf="form.controls['moisAnnee'].invalid && (form.controls['moisAnnee'].dirty
                                            || form.controls['moisAnnee'].touched)">
                                            <div *ngIf="form.controls['moisAnnee'].errors.required" style="color: red;">
                                                Champ obligatoire.
                                            </div>
                                        </span> 
                                      </div>
                                    </div>
                                </div>
                                  <div class="form-group" *ngIf="type == 'Camwater'">
                                    <label for="idAppart" class="control-label mb-1">Appartement</label>
                                    <div class="position-relative has-icon-left">
                                      <input type="text" id="idAppart" class="form-control" [value]="nomAppart" disabled>
                                        <div class="form-control-position">
                                            <i class="fa fa-institution"></i>
                                        </div>
                                    </div>
                                  </div>
                                  <div class="form-group" *ngIf="type == 'Camwater'">
                                    <label for="numero" class="control-label mb-1">Numéro de la fature</label>
                                    <input  id="numero" type="text" class="form-control" aria-required="true" aria-invalid="false" formControlName="numFact" required>
                                    <span *ngIf="form.controls['numFact'].invalid && (form.controls['numFact'].dirty
                                        || form.controls['numFact'].touched)">
                                        <div *ngIf="form.controls['numFact'].errors.required" style="color: red;">
                                          Champ obligatoire.
                                        </div>
                                    </span>
                                    <span *ngIf="form.controls.numFact.hasError('pattern')" style="color: red;">
                                        Champ invalide.
                                    </span>
                                  </div>
                                      <div class="row" *ngIf="type == 'Camwater'">
                                        <div class="col-6">
                                          <div class="form-group" >
                                            <label for="prix">Montant</label>
                                            <div class="position-relative has-icon-left">
                                              <input type="number" id="prix" class="form-control" formControlName="prix" required>
                                              <div class="form-control-position">
                                                <i class="fa fa-money"></i>
                                              </div>
                                            </div>
                                            <span *ngIf="form.controls['prix'].invalid && (form.controls['prix'].dirty
                                                || form.controls['prix'].touched)">
                                                <div *ngIf="form.controls['prix'].errors.required" style="color: red;">
                                                    Champ obligatoire.
                                                </div>
                                            </span>
                                            <span *ngIf="form.controls.prix.hasError('pattern')" style="color: red;">
                                              Champ invalide.
                                            </span> 
                                          </div>
                                        </div>
                                        <div class="col-6">
                                          <div class="form-group" >
                                            <label for="moisAnnee" class="control-label mb-1">Facture du Mois</label>
                                            <div class="position-relative has-icon-left">
                                              <input  id="moisAnnee" type="month" class="form-control" aria-required="true" aria-invalid="false" formControlName="moisAnnee" required>
                                                <div class="form-control-position">
                                                    <i class="ft-calendar h5"></i>
                                                </div>
                                            </div>
                                            <span *ngIf="form.controls['moisAnnee'].invalid && (form.controls['moisAnnee'].dirty
                                                || form.controls['moisAnnee'].touched)">
                                                <div *ngIf="form.controls['moisAnnee'].errors.required" style="color: red;">
                                                    Champ obligatoire.
                                                </div>
                                            </span> 
                                          </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-6">
                                          <div class="form-group">
                                            <label for="dateLimite">Date limite de payements</label>
                                            <div class="position-relative has-icon-left">
                                              <input type="date" id="dateLimite" class="form-control" formControlName="dateLimite" required>
                                              <div class="form-control-position">
                                                <i class="ft-calendar h5"></i>
                                              </div>
                                            </div>
                                            <span *ngIf="form.controls['dateLimite'].invalid && (form.controls['dateLimite'].dirty
                                                || form.controls['dateLimite'].touched)">
                                                <div *ngIf="form.controls['dateLimite'].errors.required" style="color: red;">
                                                    Champ obligatoire.
                                                </div>
                                            </span> 
                                          </div>
                                        </div>
                                        <div class="col-6">
                                          <div class="form-group">
                                            <label class="d-block">Statut</label>
                                            <div class="input-group" required>
                                              <div class="custom-control custom-radio d-inline-block float-left">
                                                <input type="radio" formControlName="statut" id="customRadioInline4" class="custom-control-input" value="Payee">
                                                <label class="custom-control-label" for="customRadioInline4">Réglée</label>
                                              </div>
                                              <div class="custom-control custom-radio d-inline-block float-left ml-1">
                                                <input type="radio" formControlName="statut" id="customRadioInline3" class="custom-control-input" color="warn" value="Non reglee">
                                                <label class="custom-control-label" for="customRadioInline3">Non réglée</label>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                    </div>
                                    <div class="row" *ngIf="type == 'Forfait'" >
                                      <div class="col-sm-6 col-lg-6">
                                        <div class="form-group">
                                            <label for="indexInitial">Ancien Index</label>
                                            <div class="position-relative has-icon-left">
                                              <input type="text" id="indexInitial" class="form-control" [value]="indexInitial" disabled>
                                                <div class="form-control-position">
                                                    <i class="fa fa-tachometer"></i>
                                                </div>
                                            </div>
                                        </div>
                                      </div> 
                                        <div class="col-sm-6 col-lg-6">
                                            <div class="form-group">
                                                <label for="indexFinal">Nouveau Index </label>
                                                <div class="position-relative has-icon-left">
                                                  <input type="number" id="indexFinal" class="form-control" formControlName="indexFinal" required>
                                                  <div class="form-control-position">
                                                    <i class="fa fa-fw fa-dashboard"></i>
                                                  </div>
                                                </div>
                                                <span *ngIf="form.controls['indexFinal'].invalid && (form.controls['indexFinal'].dirty
                                                    || form.controls['indexFinal'].touched)">
                                                    <div *ngIf="form.controls['indexFinal'].errors.required" style="color: red;">
                                                        Champ obligatoire.
                                                    </div>
                                                </span>
                                                <span *ngIf="form.controls.indexFinal.hasError('pattern')" style="color: red;">
                                                  Champ invalide.
                                                </span> 
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row" *ngIf="type == 'Forfait'">
                                        <div class="col-sm-6 col-lg-6">
                                          <div class="form-group">
                                            <label for="prixM3">Prix du M3</label>
                                            <div class="position-relative has-icon-left">
                                              <input type="text" id="prixKwh" class="form-control" [value]="prixM3" disabled>
                                              <div class="form-control-position">
                                                <i class="fa fa-tachometer"></i>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="col-sm-6 col-lg-6">
                                          <div class="form-group">
                                            <label for="prixEntretien">Prix entretien compteur</label>
                                            <div class="position-relative has-icon-left">
                                              <input type="text" id="prixEntretien" class="form-control" [value]="prixEntretien" disabled>
                                              <div class="form-control-position">
                                                <i class="fa fa-money"></i>
                                              </div>
                                            </div> 
                                        </div>
                                        </div>
                                    </div>
                                    <div class="form-actions right row justify-content-center">
                                        <button type="submit" class="btn btn-raised btn-primary" [disabled]="form.invalid" style="margin-right: 30px;">
                                            <i class="ft-edit-2"></i> Modifier
                                          </button>
                                        <button type="reset" routerLinkActive="active" routerLink="/listFactEau/{{idAppart}}" class="btn btn-raised btn-warning mr-1">
                                          <i class="ft-x"></i> Annuler
                                        </button>
                                        
                                    </div>
                                </form>
                            </ng-container>
                            
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    
    
</section>
  
