import { Component, OnInit, ViewChild } from '@angular/core';
import { FactureElectriciteService } from './../../shared/services/factureElectricite/facture-electricite.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FactureElectricite } from './../../interface/factureElectricite';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-list-factelect',
  templateUrl: './list-factelect.component.html',
  styleUrls: ['./list-factelect.component.css']
})
export class ListFactelectComponent implements OnInit {

  idAppart: number;
  ELEMENT_DATA: FactureElectricite[];
  factureElectricite: any;
  facture: any;

  temp = [];

  filterValues = {};
  dataSource = new MatTableDataSource();
  displayedColumns: string[] = ['id', 'NumFactElect',  'TypeCompteur', 'Prix', 'Mois', 'Annee', 'Status', 'action'];
  @ViewChild(MatSort, { static: true }) sorte: MatSort;
  @ViewChild( 'TableTwoPaginator' ) tableTwoPaginator: MatPaginator;

  filterSelectObj = [];
  constructor(private route: ActivatedRoute, private service: FactureElectriciteService, public toastr: ToastrService
  ) {

    // Object to create Filter for
    this.filterSelectObj = [
      {
        name: 'NUMERO FACTURE',
        columnProp: 'NumFactElect',
        options: []
      }, {
        name: 'TYPE COMPTEUR',
        columnProp: 'TypeCompteur',
        options: []
      }, {
        name: 'ANNEE',
        columnProp: 'Annee',
        options: []
      }, {
        name: 'MOIS',
        columnProp: 'Mois',
        options: []
      }, {
        name: 'STATUT',
        columnProp: 'Status',
        options: []
      }
    ]
  }

  ngOnInit() {
    this.idAppart = this.route.snapshot.params['idAppart'];
    this.getRemoteData();

    // Overrride default filter behaviour of Material Datatable
    this.dataSource.filterPredicate = this.createFilter();
  }

  // Get Uniqu values from columns to build filter
  getFilterObject(fullObj, key) {
    const uniqChk = [];
    fullObj.filter((obj) => {
      if (!uniqChk.includes(obj[key])) {
        uniqChk.push(obj[key]);
      }
      return obj;
    });
    return uniqChk;
  }

  // Get remote serve data using HTTP call
  getRemoteData() {
    this.service.read(this.idAppart).subscribe((data1: FactureElectricite[]) => {
      this.factureElectricite = data1;
      this.temp = [...this.factureElectricite];
      this.dataSource.data = data1;
      this.dataSource.sort = this.sorte;
      this.dataSource.paginator = this.tableTwoPaginator;
      console.log(data1);
      
        this.filterSelectObj.filter((o) => {
          o.options = this.getFilterObject(this.factureElectricite, o.columnProp);
        });
    });
    
  }

  refresh() {
    this.service.read(this.idAppart).subscribe((data: FactureElectricite[]) => {
      this.factureElectricite = data;
      this.temp = [...this.factureElectricite];
      this.dataSource.data = data;
      this.dataSource.paginator = this.tableTwoPaginator;
      this.dataSource.sort = this.sorte;
    });
  }

  // Called on Filter change
  filterChange(filter, event) {
    //let filterValues = {}
    this.filterValues[filter.columnProp] = event.target.value.trim().toLowerCase()
    this.dataSource.filter = JSON.stringify(this.filterValues)
  }

  // Custom filter method fot Angular Material Datatable
  createFilter() {
    let filterFunction = function (data: any, filter: string): boolean {
      let searchTerms = JSON.parse(filter);
      let isFilterSet = false;
      for (const col in searchTerms) {
        if (searchTerms[col].toString() !== '') {
          isFilterSet = true;
        } else {
          delete searchTerms[col];
        }
      }

      console.log(searchTerms);

      let nameSearch = () => {
        let found = false;
        if (isFilterSet) {
          for (const col in searchTerms) {
            searchTerms[col].trim().toLowerCase().split(' ').forEach(word => {
              if (data[col].toString().toLowerCase().indexOf(word) != -1 && isFilterSet) {
                found = true
              }
            });
          }
          return found
        } else {
          return true;
        }
      }
      return nameSearch()
    }
    return filterFunction
  }


  // Reset table filters
  resetFilters() {
    this.filterValues = {}
    this.filterSelectObj.forEach((value, key) => {
      value.modelValue = undefined;
    })
    this.dataSource.filter = "";
  }

  delete(id) {
    this.service.find(id).subscribe(data => {
      this.facture = data;
      if (confirm(" Voulez-vous vraiment supprimer la facture "+this.facture.NumFactElect+" d\'électricité?????")) {
        this.service.delete(id).subscribe(data1 => {
          this.refresh() ;
          this.toastr.error('Facture d\'électricité supprimée.', 'Suppression!');
        });
      }
    })
    
  }

  statut(val, id) {
    if(val == 'Non reglee') {
      this.toastr.info('La fature a déjà le statut non payée.', 'Information');
    } else if(val == 'Payee') {
      this.service.find(id).subscribe(data => {
        this.facture = data;
        if ( this.facture.Status == 'Payee') {
          this.toastr.info('La fature est déjà payée.', 'Information');
        } else if ( this.facture.Status == 'Non reglee') {
          this.service.changeStatut(id, val).subscribe(data1 => {
            this.refresh() ;
            this.toastr.success('La fature a été payée.', 'Succès!');
          });
        }
      });
    }
  }

  /*displayedColumns: string[] = ['id', 'NumFactElect',  'TypeCompteur', 'Prix', 'DateLimitePayement', 'Mois', 'Status', 'action'];
  dataSource = new MatTableDataSource<FactureElectricite>(this.ELEMENT_DATA);
  @ViewChild('MatSort') sorte: MatSort;
  @ViewChild( 'TableOnePaginator' ) tableOnePaginator: MatPaginator;
  
  constructor(private router: Router, private route: ActivatedRoute, private service: FactureElectriciteService,
    public toastr: ToastrService) { }

  ngOnInit(): void {
    this.idAppart = this.route.snapshot.params['idAppart'];
    this.refresh();
  }

  refresh() {
    this.service.read(this.idAppart).subscribe((data: FactureElectricite[]) => {
      this.factureElectricite = data;
      this.temp = [...this.factureElectricite];
      this.dataSource.data = data;
      this.dataSource.paginator = this.tableOnePaginator;
      this.dataSource.sort = this.sorte;
      console.log(data);
      console.log(this.factureElectricite);
    });
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();

    // filter our data
    const temp = this.temp.filter(function (d) {
        return d.NumFactElect.toLowerCase().indexOf(val) !== -1 || !val ||
          d.Status.toLowerCase().indexOf(val) !== -1 || d.TypeCompteur.toLowerCase().indexOf(val) !== -1 ||
          d.Prix.toLowerCase().indexOf(val) !== -1 || d.DateLimitePayement.toLowerCase().indexOf(val) !== -1 ||
          d.Mois.toLowerCase().indexOf(val) !== -1 || d.Annee.toLowerCase().indexOf(val) !== -1;
    });

    // update the rows
    this.dataSource.data = temp;
    
  }
  
  delete(id) {
    this.service.find(id).subscribe(data => {
      this.facture = data;
      if (confirm(" Voulez-vous vraiment supprimer la facture "+this.facture.NumFactElect+" d\'électricité?????")) {
        this.service.delete(id).subscribe(data1 => {
          this.refresh();
          this.toastr.error('Facture d\'électricité supprimée.', 'Suppression!');
        });
      }
    })
    
  }*/

}
