import { ReglageService } from 'app/shared/services/reglage/reglage.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BatimentService } from './../../shared/services/batiment/batiment.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-modify-reglage',
  templateUrl: './modify-reglage.component.html',
  styleUrls: ['./modify-reglage.component.css']
})
export class ModifyReglageComponent implements OnInit {

  form: FormGroup;
  nombre = '^[0-9]{1,10}$';
  nomBati: string;
  idBati: number;
  reglages: any;
  constructor(private formBuilder: FormBuilder, private service: ReglageService, private serviceBatiment: BatimentService,
    private router: Router, public toastr: ToastrService) { }

  ngOnInit(): void {

    const obj = localStorage.getItem('bt');
    const objJson = JSON.parse(obj);
    this.serviceBatiment.find(objJson.idBat).subscribe(data => {
      console.log(data);
        this.idBati = data['IdBatiment'];
        this.nomBati = data['NomBatiment'];
    });
    this.service.read(objJson.idBat).subscribe(data => {
      this.reglages = data;
      console.log(this.reglages);
      this.initForm();
    });
    
  }

  initForm() {
    this.form = this.formBuilder.group({
       prixKwh: [this.reglages.PrixKwh, [Validators.required, Validators.pattern(this.nombre)]],
      prixM3: [this.reglages.PrixM3, [Validators.required, Validators.pattern(this.nombre)]],
      prixEntretienElectricite: [this.reglages.PrixEntretienCompteurElectricite, [Validators.required, Validators.pattern(this.nombre)]],
      prixEntretienEau: [this.reglages.PrixEntretienCompteurEau, [Validators.required, Validators.pattern(this.nombre)]]
    });

  }

  onSubmitForm() {
    const prixKwh = this.form.get('prixKwh').value;
    const prixM3 = this.form.get('prixM3').value;
    const prixEntretienElectricite = this.form.get('prixEntretienElectricite').value;
    const prixEntretienEau = this.form.get('prixEntretienEau').value;
    console.log(prixKwh, prixM3, prixEntretienElectricite, prixEntretienEau, this.idBati);
    const obj = {
      IdReglage: this.reglages.IdReglage,
      PrixKwh: prixKwh,
      PrixM3: prixM3,
      PrixEntretienCompteurEau: prixEntretienEau,
      PrixEntretienCompteurElectricite: prixEntretienElectricite
    };
    this.service.update(obj).subscribe(data => {
        this.router.navigate(['/dashboard/dashboard1']);
        this.toastr.success('Réglage modifier!', 'Succèss!');
    });
  }
}