import { BatimentService } from 'app/shared/services/batiment/batiment.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AppartementService } from './../../shared/services/appartement/appartement.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-view-appt',
  templateUrl: './view-appt.component.html',
  styleUrls: ['./view-appt.component.scss']
})
export class ViewApptComponent implements OnInit {

  idAppart: number;
  appart: any;
  batiment: any;
  nomBati: string;

  constructor(private router: Router, private service: AppartementService,
          private route: ActivatedRoute, private serviceBati: BatimentService, public toastr: ToastrService) { }

  ngOnInit(): void {
    this.idAppart = this.route.snapshot.params['idAppart'];
    this.service.find(this.idAppart).subscribe(data => {
      this.appart = data;
      this.serviceBati.find(this.appart.IdBatiment).subscribe(data1 => {
          this.batiment = data1;
          this.nomBati = this.batiment.NomBatiment;
      })
    });
  }

  createAppart() {
    if ( this.batiment.NombreAppart == this.batiment.NbApCr ) {
       alert("Vous ne pouvez plus créer un appartement"); 
    } else {
      this.router.navigate(['/ajoutAppart', this.batiment.IdBatiment]);
    }
  }


  delete1(id) {
    if (confirm(" Voulez-vous vraiment supprimer l'appartement "+this.appart.NumAppart+" ?????")) {
      this.service.delete(id).subscribe(data => {
        this.router.navigate(['/listAppart', this.appart.IdBatiment]);
        this.toastr.error('Appartement supprimé.', 'Suppression!');
      });
    }
  }

}
