import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ContratBailService } from 'app/shared/services/contratBail/contrat-bail.service';
import { StorageMap } from '@ngx-pwa/local-storage';
import { LocataireService } from 'app/shared/services/locataire/locataire.service';
import { AppartementService } from 'app/shared/services/appartement/appartement.service';
//import { LOCAL_STORAGE, StorageService } from "ngx-webstorage-service";
@Component({
  selector: 'app-modify-contbail',
  templateUrl: './modify-contbail.component.html',
  styleUrls: ['./modify-contbail.component.css']
})
export class ModifyContbailComponent implements OnInit{

  constructor(private routes:ActivatedRoute,
              private storage: StorageMap,
              private serviceContract: ContratBailService,
              private serviceLocataire: LocataireService,
              private serviceAppartement: AppartementService,
              private router: Router
              ) {}

  ngOnInit(): void {
        this.routes.queryParams.subscribe(param=>{
        if(param && param['id']){
        this.storage.get('contract').subscribe((rep:[])=>{console.log(rep)
        let c=rep.find(m=>Number.parseInt(m['IdBail'])==Number.parseInt(param['id']))
        this.contract=c
        console.log(this.contract)
        this.serviceLocataire.find(c['IdLocataire']).subscribe((l)=>{
          this.nomlocSelect=l['PrenomLocataire']+' '+l['NomLocataire']
          this.idloc=l['IdLocataire']
          console.log(l['IdLocataire'])
        })
        console.log(this.e)
        this.serviceAppartement.find(c['IdAppart']).subscribe((a)=>{
          console.log(a);this.nomappartSelect=a['NumAppart']
        })
      })
    }
  })

  }

  register(){
    if(this.montant != null) this.contract['MontantBail']=this.montant
    //if(this.locataire != null) this.contract['IdLocataire']=this.locataire
    //if(this.Idappart != null) this.contract['IdAppart']=this.Idappart
    if(this.datefin != null) this.contract['datefin']=this.datefin
    console.log(this.contract)
    this.serviceContract.update(this.contract).subscribe((res)=>{
      if(res['statut']==true && res['message']=='good') this.router.navigateByUrl('listcontbail')
    })
  }

  back(){
    this.router.navigate(['/viewcontbail', this.idloc])
  }
    locataires=[]
    appartements=[]
    appartement:any
    contract={}
    montant:string;
    locataire:string;
    datefin:string;
    Idappart:string;
    nomlocSelect:string
    nomappartSelect:string
    e:any
    idloc:any
}
