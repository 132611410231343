import { Component, OnInit, ViewChild } from '@angular/core';
import { DatatableComponent } from "@swimlane/ngx-datatable";
import { Router, ActivatedRoute } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Appartement } from 'app/interface/appartement';
import { Paiement } from '../../../interface/paiement';
import { AppartementService } from 'app/shared/services/appartement/appartement.service';
import { BatimentService } from 'app/shared/services/batiment/batiment.service';
import { PaiementService } from 'app/shared/services/paiement/paiement.service';
import { LocataireService } from 'app/shared/services/locataire/locataire.service';
import {
  NgbModal,
  ModalDismissReasons,
  NgbActiveModal,
} from "@ng-bootstrap/ng-bootstrap";



@Component({
  selector: 'app-list-paiement',
  templateUrl: './list-paiement.component.html',
  styleUrls: ['./list-paiement.component.css']
})
export class ListPaiementComponent implements OnInit {

  single:any;
  closeResult: string;
  ELEMENT_DATA: Paiement[];
  idBati: number;
  appartement: any;
  batiment: any;
  paiements:any;
  locataires=[];
  NomLocataire;
  PrenomLocataire;
  appart: any;
    rows = [];

    temp = [];
  displayedColumns: string[] = ['id', 'Locataire',  'MontantPaiement', 'Mois', 'Annee', 'action'];
  dataSource = new MatTableDataSource<Paiement>(this.ELEMENT_DATA);
  @ViewChild(MatSort, { static: true }) sorte: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(private service: AppartementService, private router: Router, private route: ActivatedRoute,
             private serviceBatiment: BatimentService,
             private servicePaiement: PaiementService,
             private serviceLocataire: LocataireService,
             private modalService: NgbModal)
  {}


  ngOnInit(): void {
    const obj = localStorage.getItem('bt');
    const objJson = JSON.parse(obj);
    this.idBati = objJson.idBat;
    console.log(this.dataSource);
    this.refresh();
  }


  redirect() {
    this.router.navigate(['/addPaiement'])
      // if ( this.batiment.NombreAppart == this.batiment.NbApCr ) {
      //    alert("Vous ne pouvez plus créer un appartement"); 
      // } else {
      //   this.router.navigate(['/ajoutAppart', this.idBati]);
      // }
  }

  retour(){
    if(JSON.parse(localStorage.getItem('bt'))['profil']=='ADMINISTRATEUR'){
      this.router.navigateByUrl('/dashboard/dashboard1')
    }else{
      this.router.navigateByUrl('/dashboard/dashboard2')
    }
  }



  refresh() {
    this.servicePaiement.getPaiement().subscribe((result: Paiement[])=>{
      this.paiements=result;
      this.dataSource.data=result;
      this.temp = [...this.paiements]
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sorte;
      result.forEach(paiement => {
          this.serviceLocataire.find(paiement.idlocataire).subscribe((locataire)=>{this.locataires.push(locataire)})
      });
       console.log(result)
      })
  }

  updateFilter(event) {
      const val = event.target.value.toLowerCase();

      // filter our data
      const temp = this.temp.filter(function (d) {
          return d.idlocataire.toLowerCase().indexOf(val) !== -1 || !val || d.montantpaiement.toLowerCase().indexOf(val) !== -1 || d.Annee.toLowerCase().indexOf(val) !== -1 || d.Mois.toLowerCase().indexOf(val) !== -1;
      });

      // update the rows
      this.dataSource.data = temp;
      
  }

  open(content,elt) {
    this.modalService.open(content).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
    this.single=elt;
    console.log(this.single);
  }
  
  

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }


  delete(id) {
    this.servicePaiement.deletepaiement(id).subscribe((res)=>{console.log(res);this.refresh()})
    /*this.service.find(id).subscribe(data1 => {
        this.appart = data1;
        if (confirm("Voulez-vous vraiment supprimer l'appartement "+ this.appart.NumAppart+ " ?????")) {
          this.service.delete(id).subscribe(data => {
            this.refresh();
          });
        }
    });*/
    
  }
  //mois=['Janvier','Fevrier','Mars','Avril','Mai','Juin','Juillet','Aout','Septembre','Novembre','Deccembre'];

  

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }


  mois=[
    {
      name:'Janvier',
      id:0
    },
    {
      name:'Fevrier',
      id:1
    },

  ]

}
