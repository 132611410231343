import { MatPaginator } from '@angular/material/paginator';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UserService } from 'app/shared/services/user/user.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import {
  NgbModal,
  ModalDismissReasons,
  NgbActiveModal,
} from "@ng-bootstrap/ng-bootstrap";
import { ViewEncapsulation } from '@angular/compiler/src/core';
import {User} from '../../../../app/interface/User';
import { ActivatedRoute, Router } from '@angular/router';
import { NGXToastrService } from '../addusers/addusers-toast';
@Component({
  selector: 'app-all-user',
  templateUrl: './all-user.component.html',
  styleUrls: ['./all-user.component.css'],
  providers: [NGXToastrService]
})
export class AllUserComponent implements OnInit {


  ELEMENT_DATA: User[];
  single:any;
  closeResult: string;
  displayedColumns: string[] = ['Nom_utilisateur','Prenom_utilisateur','Login_utilisateur','phone','email','statut','profilsID','Action'];
  dataSource = new MatTableDataSource<User>(this.ELEMENT_DATA);
  @ViewChild(MatSort, { static: true }) sorte: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  constructor(private serviceUtilisateur:UserService,
    private service:NGXToastrService,
    private routes:ActivatedRoute,
    private modalService: NgbModal,
    private router:Router) { }

  ngOnInit(): void {
    this.refresh()
  }

  refresh(){
    this.routes.queryParams.subscribe((p)=>{
        if(p && p['bailleur']){
          this.serviceUtilisateur.reabailleur().subscribe((b:User[])=>{
            this.dataSource.data=b
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sorte;  
          })
          }else{
        this.serviceUtilisateur.getUsers().subscribe((u:User[])=>{
        this.dataSource.data=u
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sorte;  
        })
      }

    })
  }

  open(content,elt) {
    this.modalService.open(content).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
    this.single=elt;
    console.log(this.single);
  }

  retour(){
    if(JSON.parse(localStorage.getItem('bt'))['profil']=='ADMINISTRATEUR'){
      this.router.navigateByUrl('/dashboard/dashboard1')
    }else{
      this.router.navigateByUrl('/dashboard/dashboard2')
    }
  }


  typeSuppression(){
      this.service.typeDelete()
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  delete(id){
    console.log(id)
      this.serviceUtilisateur.deleteUser(id).subscribe((res)=>{
          console.log(res)
          if(res['statut']==true && res['message']=='good'){
            this.refresh();
            this.typeSuppression()
            }
      })
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

}
