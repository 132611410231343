import { Component, OnInit } from '@angular/core';
import { ContratBailService } from 'app/shared/services/contratBail/contrat-bail.service';
import { LocataireService } from 'app/shared/services/locataire/locataire.service';
import { AppartementService } from 'app/shared/services/appartement/appartement.service';
import { Router } from '@angular/router';
import { StorageMap } from '@ngx-pwa/local-storage';

@Component({
  selector: 'app-list-contbail',
  templateUrl: './list-contbail.component.html',
  styleUrls: ['./list-contbail.component.css']
})
export class ListContbailComponent {

  constructor(private serviceContract: ContratBailService,
              private serviceLocataire: LocataireService,
              private serviceAppartement: AppartementService,
              private router:Router,
              private storage: StorageMap) 
  {   
      this.serviceContract.read(JSON.parse(localStorage.getItem('bt'))['idBat']).subscribe((res:[])=>{console.log(res)
      this.storage.set('contract', res).subscribe((r)=>{})
      res.forEach(elt=>{
      this.serviceLocataire.find(elt['IdLocataire']).subscribe((response)=>{
        this.serviceAppartement.find(elt['IdAppart']).subscribe((result)=>{
          let e={
            id:elt['IdBail'],
            numappart:result['NumAppart'],
            MontantBail:elt['MontantBail'],
            DateEffetBail:elt['DateEffetBail'],
            DateFinBail:elt['DateFinBail'],
            locataire:response['NomLocataire']+' '+response['PrenomLocataire']
            }
            this.contract.push(e)
          })
        })
      })
      }, (err)=>{console.log(err)})
   }

   delete(i){
    this.serviceContract.delete(i).subscribe((res)=>{
      if(res['statut']===true && res['message']==='good'){
        this.router.navigateByUrl('listcontbail')
      }
    },(err)=>{console.log(err)})
   }

   edit(i){
   }

  contract=[]
  locataire=[]
  appartement:any
}
