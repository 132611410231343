<!--Extended Table starts-->
<div class="row">
    <div class="col-9">
        <div class="card">

            <div class="card-body">
                <span class="card-title h2 float-left">Liste Utilisateur</span>
                <input type='text' style='padding:8px;margin:15px auto;width:30%;' class="float-right" placeholder='Rechercher...' (keyup)="applyFilter($event)">
                <div>
                    <span class="dt-responsive table-responsive-xl">
                  <table mat-table class="table" [dataSource]="dataSource" matSort style="width: 100%;">
                      <!-- Name Column -->
                      <ng-container matColumnDef="Nom_utilisateur">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col" class="thead-dark1">Nom </th>
                          <td mat-cell *matCellDef="let element; let i=index">
                            {{element['Nom_utilisateur']}} </td>
                      </ng-container>
                      <!-- Symbol Column -->
                      <ng-container matColumnDef="Prenom_utilisateur">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col" class="thead-dark1"> Prenom </th>
                          <td mat-cell *matCellDef="let element"> {{element.Prenom_utilisateur}} </td>
                      </ng-container>
        
                      <ng-container matColumnDef="Login_utilisateur">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col" class="thead-dark1">Nom Utilisateur </th>
                        <td mat-cell *matCellDef="let element"> {{element.Login_utilisateur}}  </td>
                    </ng-container>
                    
                      <ng-container matColumnDef="phone">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col" class="thead-dark1"> Telephone </th>
                          <td mat-cell *matCellDef="let element"> {{element.phone}} </td>
                      </ng-container>
                                    
                      <ng-container matColumnDef="email">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col" class="thead-dark1">Email</th>
                          <td mat-cell *matCellDef="let element"> {{element.email}} </td>
                      </ng-container>
                
                      <ng-container matColumnDef="statut">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col" class="thead-dark1"> statut </th>
                          <td mat-cell *matCellDef="let element">  {{element.statut}}  </td>
                      </ng-container>

                      <ng-container matColumnDef="profilsID">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col" class="thead-dark1"> profil </th>
                        <td mat-cell *matCellDef="let element"> {{element.profilsID }}   </td>
                    </ng-container>
                    <ng-container matColumnDef="Action">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col" class="thead-dark1"> Actions </th>
                        <td mat-cell *matCellDef="let element">
                          <!-- <a class="info p-0" data-original-title="" title="Vue Contrat" routerLinkActive="active" [queryParams]="{idloc:element['IdLocataire']}" routerLink="/viewcontbail">
                            <i class="ft-eye font-medium-3 mr-2"></i>
                          </a> -->
                          <a class="success p-0" data-original-title="" title="Modifier" routerLinkActive="active" [queryParams]="{id:element['utilisateursID']}" routerLink="/adduser">
                            <i class="ft-edit-2 font-medium-3 mr-2"></i>
                          </a>
                          <a class="danger p-0" data-original-title="" title="Supprimer" (click)="delete(element.utilisateursID)" >
                            <i class="ft-trash font-medium-3 mr-2"></i>
                          </a>    
                        </td>
                  </ng-container>
                      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                      <tr mat-row *matRowDef="let row; columns: displayedColumns;"  routerLinkActive="router-link-active" ></tr>
                  </table>
              </span>
                    <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>

                </div>
            </div>
        </div>
    </div>
    <div class="card col-3 div2">
        <div>
            <nav>
                <div>
                    <div class="card-title entete">
                        <h2>Actions</h2>
                    </div>

                    <ul class="card-body" style="list-style-type: none;">
                        <li>
                            <button class="btn  btn-lg btn-block btn-success btn-raised btn-sm font-weight-light" routerLink="/adduser">
                          <i class="ft-plus"></i> Ajout Utilisateur
                        </button>
                        </li>
                        <li style="margin-top: 40px;">
                            <div class="type-1 row justify-content-center">
                                <a (click)="retour()" class="btn btn-1">
                                    <span class="txt">Retour</span>
                                    <span class="round"><i class="fa fa-chevron-left" style="color: white;"></i></span>
                                </a>
                            </div>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
    </div>
</div>