import { NgModule, Component, ViewChild, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule, FormGroup, NgForm, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute, Routes } from '@angular/router';
import { LoginService } from 'app/shared/services/login/login.service';
import { State } from 'app/enums/enum';
import { StorageMap } from '@ngx-pwa/local-storage';
import { BatimentService } from 'app/shared/services/batiment/batiment.service';
import {isEmpty} from "lodash";
import { NGXToastrService } from './login-Toasts';

/*import { Component, ViewChild, OnInit, NgModule } from '@angular/core';
import { NgForm, FormControl, FormGroup, FormBuilder, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router, ActivatedRoute } from "@angular/router";
import { LoginService } from 'app/shared/services/login/login.service';*/

@Component({
    selector: 'app-login-page',
    templateUrl: './login-page.component.html',
    styleUrls: ['./login-page.component.scss'],
    providers: [NGXToastrService]
})

export class LoginPageComponent implements OnInit {

    @ViewChild('f') loginForm: NgForm;
    userForm:FormGroup;
    passPattern = "^[a|A-z|Z0-9]{4,20}$";
    constructor(private router: Router,
                private route: ActivatedRoute,
                private loginService: LoginService,
                private formBuilder: FormBuilder,
                private storage: StorageMap,
                private serviceBatiment: BatimentService,
                private service: NGXToastrService,
                ) {
                }

    // Success Type
    typeSuccess() {
        this.service.typeSuccess();
    }
    typeError(){
        this.service.typeError();
    }

    ngOnInit() {
        //this.initForm()
    }
    initForm() {
        this.userForm = this.formBuilder.group({
          username: ['', Validators.required],
          password: ['',  [Validators.required, Validators.pattern(this.passPattern)]],
        });
    }
    // On submit button click
    onSubmit() {
        this.loginForm.reset();
    }
    // On Forgot password link click
    onForgotPassword() {
        this.router.navigate(['forgotpassword'], { relativeTo: this.route.parent });
    }
    // On registration link click
    onRegister() {
        this.router.navigate(['register'], { relativeTo: this.route.parent });
    }

    login(){
        if(this.username.trim().length==0 || this.password.trim().length==0){
            this.typeError()
        }else{

            let user={
                username:this.username,
                password:this.password
            }
            this.loginService.login(user).subscribe((response)=>{
                console.log(response)
            if(isEmpty(response)){
                this.typeError()
                this.resetInput()
            }else{
                if(response['profilsID']=='ADMINISTRATEUR'){
                    const bt={idUser:response['utilisateursID'],idBat:'All',profil:response['profilsID']};
                    localStorage.setItem('bt', JSON.stringify(bt));
                    this.router.navigateByUrl('dashboard/dashboard1');console.log(response)
                }else if(response['profilsID']=='BAILLEUR'){
                    this.serviceBatiment.readAll(response['utilisateursID']).subscribe((u)=>{
                        console.log(u);

                        if(isEmpty(u)){
                            const bt={idUser:response['utilisateursID'], idBat:0,profil:response['profilsID']};
                            localStorage.setItem('bt', JSON.stringify(bt));
                            this.router.navigateByUrl('/AddBatiment');console.log(response)
                            this.typeSuccess()
                        }else{
                            const bt={idUser:response['utilisateursID'], idBat:u[0]['IdBatiment'],profil:response['profilsID']};
                            localStorage.setItem('bt', JSON.stringify(bt));
                            this.router.navigateByUrl('dashboard/dashboard2');console.log(response)
                            this.typeSuccess()
                        }
                        console.log(localStorage.getItem('bt'))
                    })
                }
            }

            }, (error)=>{
                this.resetInput();
            })
        }
    }

    resetInput(){
        this.username=""
        this.password=""
    }
    tableau=[]
    username=""
    password=""
}
