<!--Login Page Starts-->
<section id="login">
    <!-- <div class="card text-left">
        <div class="card-content">
            <div class="card-body">
                <div class="row mt-1">
                  <div class="col-md-4 col-sm-12 text-center">
                    <button type="button" class="btn btn-info btn-block btn-raised mb-5" id="type-info" (click)="typeInfo()">Connexion Reussit Effectuer !</button>
                  </div>
                </div>
            </div>
        </div>
  </div> -->


    <div class="container-fluid">
        <div class="row full-height-vh m-0">
            <div class="col-12 d-flex align-items-center justify-content-center">
                <div class="card">
                    <div class="card-content">
                        <div class="card-body login-img">
                            <div class="row m-0">
                                <div class="col-lg-6 d-lg-block d-none py-2 px-3 text-center align-middle">
                                    <img src="assets/img/gallery/login-2.png" alt="" class="img-fluid mt-5" width="400" height="230" />
                                </div>
                                <div class="col-lg-6 col-md-12 bg-white px-4 pt-3">
                                    <h4 class="card-title mb-2">CONNEXION</h4>
                                    <p class="card-text mb-3">
                                        Bienvenu veuillez vous connecter a votre compte
                                    </p>
                                    <form>
                                        <div class="form-group position-relative">
                                            <input type="text" class="form-control mb-3 input-lg rounded-0" placeholder="Nom D'utilisateur" required="" name="username" [(ngModel)]="username" />
                                            <i class="fa fa-user fa-lg position-absolute"></i>
                                        </div>
                                        <div class="form-group position-relative">
                                            <input type="password" class="form-control mb-3 input-lg rounded-0" placeholder="Mot De Passe" required="" name="password" [(ngModel)]="password" />
                                            <i class="fa fa-lock fa-lg position-absolute"></i>
                                        </div>
                                    </form>
                                    <div class="fg-actions d-flex justify-content-between">
                                        <div class="login-btn">
                                            <button style="width:160px;" class="btn btn-outline-primary">
                        <a
                        [routerLink]="['/register']"
                          class="text-decoration-none"
                          >Inscription</a
                        >
                      </button>
                                        </div>
                                        <div class="recover-pass">
                                            <button style="width: 160px;" class="btn btn-primary" (click)="login()">
                        <a
                          class="text-decoration-none text-white"
                          >Connexion</a
                        >
                      </button>
                                        </div>
                                    </div>
                                    <hr class="m-0" />
                                    <div class="d-flex justify-content-between mt-3">
                                        <div class="option-login">
                                            <h6 class="text-decoration-none text-primary">
                                                ou bien avec
                                            </h6>
                                        </div>
                                        <div class="social-login-options">
                                            <a class="btn btn-social-icon mr-2 btn-facebook">
                                                <span class="fa fa-facebook"></span>
                                            </a>
                                            <a class="btn btn-social-icon mr-2 btn-twitter">
                                                <span class="fa fa-twitter"></span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--Login Page Ends-->