<!--Registration Page Starts-->
<section id="regestration">
    <div class="container-fluid">
      <div class="row full-height-vh m-0">
        <div class="col-12 d-flex align-items-center justify-content-center">
          <!-- <button (click)="back()" style="margin-top: -600px; background-color: #007acc; color:white" class="btn btn-raised">
            Retour
            </button> -->
      
          <div class="card">
            <div class="card-content">
              <div class="card-body register-img">
                <div class="row m-0">
                  <div class="col-lg-6 d-none d-lg-block py-2 px-3 text-center">
                    <img
                      src="../../assets/img/gallery/sign.jpg"
                      alt=""
                      class="img-fluid mt-3 pl-3"
                      width="400"
                      height="230"
                    />
                  </div>
                  <div class="col-lg-6 col-md-12 pt-3 px-4 bg-white">
                    <h4 class="card-title mb-2">Edition De Contrat</h4>
                    <p class="card-text mb-3">
                      Renseignez vos informations ci-dessous
                    </p>
                    <form autocomplete="off">
                        <select disabled name="locataire" required="" class="browser-default custom-select" style="margin-bottom: 20px;">
                          <option selected >{{nomlocSelect}}</option>
                        </select>
                        <select disabled name="appartement" required="" class="browser-default custom-select" style="margin-bottom: 20px;">
                            <option selected >{{nomappartSelect}}</option>
                        </select>
                            <input
                                [(ngModel)]="montant"
                                [ngModel]="contract['MontantBail']"
                                name="montant"
                                type="number"
                                class="form-control mb-3"
                                placeholder="Montant"
                                required=""
                            />
                            <input
                              [(ngModel)]="datefin"
                              [ngModel]="contract['DateFinBail']"
                              name="datefin"
                              type="datetime-local"
                              class="form-control mb-3"
                              placeholder="Date Fin"
                              required=""
                            />
                    </form>
                    <div class="fg-actions d-flex justify-content-between">
                      <div class="recover-pass">
                        <button style="width: 150px;" type="button" class="btn btn-primary btn-block btn-raised" (click)="register()">
                          <i class="fa fa-edit fa-lg"></i>
                          <a class="text-decoration-none text-white">
                            Modifier
                          </a>
                      </button>
                      </div>
                    </div>
                    <div class="fg-actions d-flex justify-content-between">
                      <div class="recover-pass">
                        <button routerLink="/listuser" style="margin-left: 170px; margin-top: -75px; width: 150px;" type="button" class="btn btn-danger btn-raised">
                          <i class="fa fa-times fa-lg"></i>
                          <a class="text-decoration-none text-white">
                            Annuler
                          </a>
                        </button>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!--Registration Page Ends-->
  