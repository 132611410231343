<!--Extended Table starts-->
<div class="row text-left">
    <div class="col-12">
      <div class="content-header">Liste De Contrat</div>
      <p class="content-sub-header"></p>
    </div>
  </div>
  <section id="extended">
    <div class="row text-left">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-header">
            <h4 class="card-title">Contrat</h4>
          </div>
          <div class="card-content">
            <div class="card-body">
              <table class="table table-responsive-md text-center">
                <thead>
                  <tr>
                    <th>#</th>
                    <th></th>
                    <th>Locataire</th>
                    <th>Appartement</th>
                    <th>Montant</th>
                    <th>Date Initial</th>
                    <th>Date Final</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of contract; let i=index">
                    <td>{{i+1}}</td>
                    <td>
                        <div class="custom-control custom-checkbox m-0">
                            <input type="checkbox" class="custom-control-input" id="item1">
                            <label class="custom-control-label col-form-label" for="item1"></label>
                        </div>
                    </td>
                    <td>

                        {{item['locataire']}}
                    </td>
                    <td>{{item['numappart']}}</td>
                    <td>{{item['MontantBail']}}</td>
                    <td>{{item['DateEffetBail']}}</td>
                    <td>{{item['DateFinBail']}}</td>
                    <td>
                      <a class="success p-0" data-original-title="" title="">
                        <i class="ft-edit-2 font-medium-3 mr-2" [queryParams]="{id:item['id']}" routerLink="/modifycontbail"></i>
                      </a>
                      <a class="danger p-0" data-original-title="" title="">
                        <i class="ft-x font-medium-3 mr-2" (click)="delete(item['id'])"></i>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!--Extended Table Ends-->
  <!--Shopping cart starts-->
  <!-- <section id="shopping-cart">
    <div class="row text-left">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-header">
            <h4 class="card-title">Shopping Cart</h4>
          </div>
          <div class="card-content">
            <div class="card-body">
              <table class="table table-responsive-md text-center">
                <thead>
                  <tr>
                    <th>Image</th>
                    <th>Product</th>
                    <th>Weight</th>
                    <th>Price</th>
                    <th>Quantity</th>
                    <th>Amount</th>
                    <th>Delete</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td><img class="media-object round-media" src="assets/img/elements/01.png" alt="Generic placeholder image"
                        style="height: 75px;" /></td>
                    <td>Product 1</td>
                    <td>38.9 Ounce</td>
                    <td>$9.97</td>
                    <td>
                      2
                      <div class="btn-group ml-1">
                        <a class="bg-info px-1 py-1 white font-medium-5" href="javascript:void(0)">-</a>
                        <a class="bg-info px-1 py-1 white font-medium-5" href="javascript:void(0)">+</a>
                      </div>
                    </td>
                    <td>$19.94</td>
                    <td>
                      <a class="danger" data-original-title="" title="">
                        <i class="ft-trash font-medium-3"></i>
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td><img class="media-object round-media" src="assets/img/elements/07.png" alt="Generic placeholder image"
                        style="height: 75px;" /></td>
                    <td>Product 2</td>
                    <td>15.9 Ounce</td>
                    <td>$6.00</td>
                    <td>
                      2
                      <div class="btn-group ml-1">
                        <a class="bg-info px-1 py-1 white font-medium-5" href="javascript:void(0)">-</a>
                        <a class="bg-info px-1 py-1 white font-medium-5" href="javascript:void(0)">+</a>
                      </div>
                    </td>
                    <td>$12.00</td>
                    <td>
                      <a class="danger" data-original-title="" title="">
                        <i class="ft-trash font-medium-3"></i>
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td><img class="media-object round-media" src="assets/img/elements/11.png" alt="Generic placeholder image"
                        style="height: 75px;" /></td>
                    <td>Product 3</td>
                    <td>20.9 Ounce</td>
                    <td>$15.78</td>
                    <td>
                      1
                      <div class="btn-group ml-1">
                        <a class="bg-info px-1 py-1 white font-medium-5" href="javascript:void(0)">-</a>
                        <a class="bg-info px-1 py-1 white font-medium-5" href="javascript:void(0)">+</a>
                      </div>
                    </td>
                    <td>$15.78</td>
                    <td>
                      <a class="danger" data-original-title="" title="">
                        <i class="ft-trash font-medium-3"></i>
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td><img class="media-object round-media" src="assets/img/elements/14.png" alt="Generic placeholder image"
                        style="height: 75px;" /></td>
                    <td>Product 4</td>
                    <td>90 Ounce</td>
                    <td>$24.51</td>
                    <td>
                      3
                      <div class="btn-group ml-1">
                        <a class="bg-info px-1 py-1 white font-medium-5" href="javascript:void(0)">-</a>
                        <a class="bg-info px-1 py-1 white font-medium-5" href="javascript:void(0)">+</a>
                      </div>
                    </td>
                    <td>$73.53</td>
                    <td>
                      <a class="danger" data-original-title="" title="">
                        <i class="ft-trash font-medium-3"></i>
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
  
                    <td><b>Total</b></td>
                    <td><b>$101.31</b></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td><button class="btn btn-success btn-raised">Continue</button> </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section> -->
  <!--Shopping cart ends-->
  