<!--Registration Page Starts-->
<section id="regestration">
  <div class="container-fluid">
    <div class="row full-height-vh m-0">
      <div class="col-12 d-flex align-items-center justify-content-center">
        <div class="card">
          <div class="card-content">
            <div class="card-body register-img">
              <div class="row m-0">
                <div class="col-lg-6 d-none d-lg-block py-2 px-3 text-center">
                  <img
                    src="../../assets/img/gallery/regist.jpg"
                    alt=""
                    class="img-fluid mt-3 pl-3"
                    width="400"
                    height="230"
                  />
                </div>
                <div class="col-lg-6 col-md-12 pt-3 px-4 bg-white">
                  <h4 class="card-title mb-2">Créer  Votre Compte</h4>
                  <p class="card-text mb-3">
                    Observez le formulaire ci-dessous afin de créer un compte.
                  </p>
                  <form autocomplete="off">
                    <div class="row">
                          <div class="col-md-6 position-relative">
                              <input
                              name="name"
                              type="text"
                              class="form-control mb-3 input-lg rounded-0"
                              placeholder="Nom"
                              [(ngModel)]="name"
                              required=""
                            />
                            <i class="fa fa-user fa-lg position-absolute"></i>
                          </div>
                          <div class="col-md-6 position-relative">
                            <input
                            name="surname"
                            type="text"
                            class="form-control mb-3"
                            placeholder="Prenom"
                            [(ngModel)]="surname"
                            required=""
                          />
                          <i class="fa fa-user fa-lg position-absolute"></i>
                          </div>
                    </div>

                    <div class="row">
                    <div class="col-md-12 position-relative">
                        <input
                        name="username"
                        type="text"
                        class="form-control mb-3"
                        placeholder="Nom Utilisateur"
                        [(ngModel)]="username"
                        required=""
                      />
                      <i class="fa fa-user fa-lg position-absolute"></i>
                    </div>
                    </div>
                  <div class="row">
                    <div class="col-md-6 position-relative">
                        <input
                      name="password"
                      type="password"
                        class="form-control mb-3 input-lg rounded-0"
                        placeholder="Mot De Passe"
                        [(ngModel)]="password"
                        required=""
                      />
                      <i class="fa fa-lock fa-lg position-absolute"></i>
                  </div>
                    <div class="col-md-6 position-relative">
                        <input
                        name="confirmPassword"
                        type="password"
                        class="form-control mb-3 input-lg rounded-0"
                        placeholder="Confirmation"
                        [(ngModel)]="password"
                        required=""
                      />
                      <i class="fa fa-lock fa-lg position-absolute"></i>
                    </div>
                  </div>

                  <div class="row">
                      <div class="col-md-6 position-relative">
                           <input
                            name="phone"
                            type="tel"
                            class="form-control mb-3 input-lg rounded-0"
                            placeholder="Telephone"
                            [(ngModel)]="phone"
                            required=""
                          />
                          <i class="fa fa-phone fa-lg position-absolute"></i>
                      </div>
                      <div class="col-md-6 position-relative">
                        <input
                        name="mail"
                        type="email"
                        class="form-control mb-3 input-lg rounded-0"
                        placeholder="Email"
                        [(ngModel)]="mail"
                        required=""
                      />
                      <i class="fa fa-envelope fa-lg position-absolute"></i>
                    </div>

                  </div>
                </form>
                  <div class="fg-actions d-flex justify-content-between">
                    <div class="login-btn">
                      <button style="width:180px;" class="btn btn-outline-primary">
                        <a
                          href="login-page.html"
                          class="text-decoration-none"
                          [routerLink]="['/pages/login']"
                        >
                          Connexion
                        </a>
                      </button>
                    </div>
                    <div class="recover-pass">
                      <button style="width:180px;" class="btn btn-primary" (click)="inscription()">
                        <a class="text-decoration-none text-white">
                          Inscription
                        </a>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--Registration Page Ends-->
