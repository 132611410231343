<!--Login Page Starts-->
<section id="add-user">
    <div class="container-fluid">
        <div class="row full-height-vh m-0">
            <div class="col-12 d-flex align-items-center justify-content-center">
                <div class="card">
                    <div class="card-content">
                        <div class="card-body login-img">
                            <div class="row">
                                <div class="type-1 row" style="margin-left: 15px;">
                                    <a routerLink="/listuser" class="btn btn-1">
                                        <span class="txt">Retour</span>
                                        <span class="round"><i class="fa fa-chevron-left" style="color: white;"></i></span>
                                    </a>
                                </div>
                            </div>
                            <div class="row m-0">
                                <div class="col-lg-6 d-lg-block d-none py-2 px-3 text-center align-middle">
                                    <img src="assets/img/gallery/128c8445.png" alt="" class="img-fluid mt-5" width="400" height="230" />
                                </div>
                                <div class="col-lg-6 col-md-12 bg-white px-4 pt-3">
                                    <h4 class="card-title mb-2">LOCATAIRE</h4>
                                    <p class="card-text mb-3">
                                        Renseinger Vos Informations Ci-dessous
                                    </p>
                                    <form>
                                        <div class="row">
                                            <div class="col-md-6 position-relative">
                                                <label for="">Nom</label>
                                                <input type="text" class="form-control mb-3 input-lg rounded-0" required="" name="name" [(ngModel)]="locataire.name" />
                                            </div>
                                            <div class="col-md-6 position-relative">
                                                <label for="">Prenom</label>
                                                <input type="text" class="form-control mb-3 input-lg rounded-0"  required="" name="surname" [(ngModel)]="locataire.surname" />
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6 position-relative">
                                                <label for="">CNI</label>
                                                <input type="text" class="form-control mb-3 input-lg rounded-0" required="" name="cni" [(ngModel)]="locataire.cni" />
                                            </div>
                                            <div class="col-md-6 position-relative">
                                                <label for="">Metier</label>
                                                <input type="text" class="form-control mb-3 input-lg rounded-0" required="" name="metier" [(ngModel)]="locataire.metier" />
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6 position-relative">
                                                <label for="">Telephone</label>
                                                <input type="tel" class="form-control mb-3 input-lg rounded-0" required="" name="phone" [(ngModel)]="locataire.phone" />
                                            </div>
                                            <div class="col-md-6 position-relative">
                                                <label for="">Nombre De Personne</label>
                                                <input type="number" class="form-control mb-3 input-lg rounded-0" required="" name="nbr" [(ngModel)]="locataire.nbr" />
                                            </div>
                                        </div>

                                        <div class="form-group position-relative">
                                            <label for="">Email</label>
                                            <input type="email" class="form-control mb-3 input-lg rounded-0" required="" name="email" [(ngModel)]="locataire.email" />
                                        </div>


                                        <div class="row">
                                            <div class="col-md-6 position-relative">
                                                <label for="">Batiment</label>
                                                <select name="batiment" [(ngModel)]="bat" (select)="changeBat(bat)" class="form-control input-lg rounded-0" id="type" disabled required="">
                                            <option selected value="">{{NumBat}}</option>
                                            <option *ngFor="let a of bats" value="{{a}}">{{a['NomBatiment']}}</option>
                                            </select>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <label for="">Appartement</label>
                                                            <select name="appartement" [(ngModel)]="locataire.IdAppartement" class="form-control" id="type" required="">
                                            <option selected value="">Appartement</option>
                                            <option *ngFor="let a of aparts" value={{a.IdAppart}}>{{a['NumAppart']}}</option>
                                            </select>
                                            </div>
                                        </div>

                                    </form>

                                    
                                    <div class="d-flex justify-content-between mt-2">
                                        <div class="remember-me">
                                        </div>
                                    </div>

                                    <div class="fg-actions d-flex justify-content-between">
                                        <div class="recover-pass">
                                            <button style="width: 150px;" class="btn btn-info btn-raised" (click)="create()">
                                                <i class="fa fa-plus fa-lg"></i>
                                                <a
                                                    class="text-decoration-none text-white"
                                                    > Ajouter
                                                </a>
                                            </button>
                                        </div>
                                    <div class="recover-pass">

                                    <button style="width: 150px;" class="btn btn-danger btn-raised" routerLink="/listuser">
                        <i class="fa fa-times fa-lg"></i>
                          <a
                            class="text-decoration-none text-white"
                            > Annuler</a
                          >
                        </button>
                                        </div>

                                    </div>

                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--Login Page Ends-->