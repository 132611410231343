import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UrlConfigService {
  url:any
  constructor() {
    //this.url ='http://localhost/';
    this.url ='https://app.gestionappartements.cm/';
   }
  public getUrl(){
    return this.url;
  }
}
