<!--Registration Page Starts-->
<section id="regestration">
    <div class="container-fluid">
      <div class="row full-height-vh m-0">
        <div class="col-12 d-flex align-items-center justify-content-center">
          <button routerLink="/listuser" style="margin-top: -570px;margin-right: -70px;margin-bottom: 85px; background-color: #007acc; color:white" class="btn btn-raised">
            Retour
            </button>
          <div class="card">
            <div class="card-content">
              <div class="card-body register-img">
                <div class="row m-0">
                  <div class="col-lg-6 d-none d-lg-block py-2 px-3 text-center">
                    <img
                      src="../../assets/img/gallery/sign.jpg"
                      alt=""
                      class="img-fluid mt-3 pl-3"
                      width="400"
                      height="230"
                    />
                  </div>
                  <div class="col-lg-6 col-md-12 pt-3 px-4 bg-white">
                    <h4 class="card-title mb-2">Ajout De Contrat</h4>
                    <p class="card-text mb-3">
                      Renseignez vos informations ci-dessous
                    </p>
                    <form autocomplete="off">
                            <div class="row">
                                  <div class="col-md-6">
                                    <label for="Locataire">Locataire</label>
                                    <input 
                                    type="text"
                                    [ngModel]="locataire"
                                    class="form-control mb-3"
                                    disabled
                                    id="Locataire"
                                    name="locataire"
                                    >
                                  </div>
                                  <div class="col-md-6">
                                    <label for="appartement">Appartement</label>
                                    <input 
                                    type="text"
                                    class="form-control mb-3"
                                    name="appartement"
                                    disabled
                                    id="appartement"
                                    [ngModel]="appartement"
                                    >
                                  </div>
                            </div>
                            <div class="row">
                                  <div class="col-md-12 position-relative">
                                    <label for="versement">Versement Initial</label>
                                    <input
                                        [(ngModel)]="montantverse"
                                        name="montant"
                                        type="number"
                                        class="form-control mb-3 input-lg rounded-0"
                                        placeholder="xxxxx"
                                        id="versement"
                                        required=""
                                    />
                                    <i class="fa fa-money fa-lg position-absolute"></i>
                                  </div>
                            </div>
                            <hr>

                            <div class="row">
                                <div class="col-md-6 position-relative">
                                    <label for="mensualite">Montant Mensuel</label>
                                    <input
                                    [(ngModel)]="montant"
                                    name="montant"
                                    type="number"
                                    class="form-control mb-3 input-lg rounded-0"
                                    placeholder="xxxxx"
                                    id="mensualite"
                                    required=""
                                    />
                                    <i class="fa fa-money fa-lg position-absolute"></i>
                                </div>
                                <div class="col-md-6 position-relative">
                                    <label for="caution">Caution</label>
                                    <input 
                                      [(ngModel)]="caution"
                                      type="number"
                                      name="caution"
                                      class="form-control mb-3 input-lg rounded-0"
                                      placeholder="xxxxx"
                                      id="caution"
                                      required=""
                                    >
                                    <i class="fa fa-money fa-lg position-absolute"></i>
                                </div>
                            </div>
                            <div class="row">
                                  <div class="col-md-6">
                                    <label for="date">Date Effectuelle</label>
                                    <input 
                                    type="month" 
                                    name="dateEffetBail"
                                    [(ngModel)]="dateBail"
                                    class="form-control mb-3"
                                    placeholder="Date Initial Bail"
                                    id="date"
                                    required=""
                                    />
                                  </div>
                                  <div class="col-md-6">
                                    <label for="">Mois De Paiement</label>
                                    <input 
                                    type="month" 
                                    name="dateEffetBail"
                                    [(ngModel)]="datePaye"
                                    class="form-control mb-3"
                                    placeholder="Date Initial Bail"
                                    required=""
                                    />
                                  </div>
  
                            </div>
                            <label for="statutContrat">Statut Contrat</label>
                            <select [(ngModel)]="selectedvalue"  name="statutContrat" required="" id="statutContrat" class="browser-default custom-select" style="margin-bottom: 20px;">
                              <option value="1">Déterminé</option>
                              <option value="2">Indéterminé</option>
                            </select>
                            <div *ngIf="selectedvalue==1" class="row">
                                <div class="col-md-12">
                                    <input
                                    [(ngModel)]="datefin"                            
                                    name="datefin"
                                    type="datetime-local"
                                    class="form-control mb-3"
                                    placeholder="Date Fin"
                                    required=""
                                    />  
                                </div>
                            </div>
                            <hr>
                    </form>
                    <div class="fg-actions d-flex justify-content-between">
                      <div class="recover-pass">
                        <button [disabled]="contrat" style="width: 150px;" type="button" class="btn btn-primary btn-block" (click)="register()">
                          <a class="text-decoration-none text-white">
                            Enregister
                          </a>
                        </button>
                      </div>
                    </div>
                    <div class="fg-actions d-flex justify-content-between">
                      <div class="recover-pass">
                        <button  style="width: 150px;margin-left: 170px; margin-top:-72px;" type="button" class="btn btn-danger" routerLink="/listuser">
                          <a class="text-decoration-none text-white">
                            Annuler
                          </a>
                        </button>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!--Registration Page Ends-->
  