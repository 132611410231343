import { FactureElectriciteService } from './../../shared/services/factureElectricite/facture-electricite.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AppartementService } from './../../shared/services/appartement/appartement.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ReglageService } from 'app/shared/services/reglage/reglage.service';
import {isEmpty} from "lodash";
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-add-factelect',
  templateUrl: './add-factelect.component.html',
  styleUrls: ['./add-factelect.component.css']
})
export class AddFactelectComponent implements OnInit {

  idAppart: number;
  form: FormGroup;
  valeur = 'NULL';
  appartement: any;
  numeroFacture: string;
  factureElectricite: any;
  NumAppartement: string;
  indexInitial: number;
  indexFinal: number;
  prixKwh: number;
  prixEntretien: number;
  prix: number;
  NumFact = '^[a|A-z|Z0-9]{4,20}$';
  nombre = '^[0-9]{1,10}$';
  numero: string;
  i = [];
  compteurdivisionnaire = [];
  taille: number;
  reglage: any;
  factureMois: Array<string>;
  moisAnnee: any;
  mois1: any;
  annee: any;

  constructor(private formBuilder: FormBuilder, private serviceAppart: AppartementService,
                private router: Router, private route: ActivatedRoute, private service: FactureElectriciteService,
                private serviceReglage: ReglageService, public toastr: ToastrService) { }

  ngOnInit(): void {
    this.idAppart = this.route.snapshot.params['idAppart'];
    this.serviceAppart.find(this.idAppart).subscribe(data => {
      this.appartement = data;
      this.NumAppartement = this.appartement.NumAppart;
      this.serviceReglage.read(this.appartement.IdBatiment).subscribe(data1 => {
        this.reglage = data1;
        if ( isEmpty(data1)) {
          this.toastr.info('Veuillez paramètrer le batiment avant de créer une facture d\'électricité', 'Information');
          this.router.navigate(['/ajoutReglage']);
        } else {
          this.prixKwh = this.reglage.PrixKwh;
          this.prixEntretien = this.reglage.PrixEntretienCompteurElectricite;
        }
        console.log(data1);
      })
        console.log(data);
    });
    this.service.read(this.idAppart).subscribe(data => {
      this.factureElectricite = data;
      this.taille = this.factureElectricite.length;
      this.factureElectricite.forEach(element => {
          if ( element['TypeCompteur'] == 'Divisionnaire') {
            this.compteurdivisionnaire.push(element);
          }
      });
      if(this.taille == 0 ) {
        this.numeroFacture = 'Fact_00';
        this.indexInitial = 0;
      } else {
        if(this.compteurdivisionnaire.length == 0) {
          this.numeroFacture = 'Fact_00';
          this.indexInitial = 0;
        } else {
          this.numeroFacture = this.compteurdivisionnaire[0]['NumFactElect'];
          this.indexInitial = this.compteurdivisionnaire[0]['IndexFinal'];
        }
      }
      console.log(data, this.numeroFacture, this.taille);
    });
   
    this.initForm();
  }

  initForm() {
    this.form = this.formBuilder.group({
      numFact: ['0000', [Validators.required, Validators.pattern(this.NumFact)]],
      type: ['', [Validators.required]],
      moisAnnee: ['', Validators.required],
      prix: [0, [Validators.required, Validators.pattern(this.nombre)]],
      dateLimite: ['', [Validators.required]],
      indexFinal: [0, [Validators.required, Validators.pattern(this.nombre)]],
    });
  }

  TypeFacture(valeur) {
    this.valeur = valeur.target.value;
  }

  onSubmitForm() {
    const numFact = this.form.get('numFact').value;
    const type = this.form.get('type').value;
    const prix = this.form.get('prix').value;
    const dateLimite = this.form.get('dateLimite').value;
    const moisAnnee = this.form.get('moisAnnee').value;
    const indexFinal = this.form.get('indexFinal').value;

    this.moisAnnee = moisAnnee.split('-');
    this.annee = this.moisAnnee[0];
    this.mois1 = this.moisAnnee[1];

    if( numFact == '0000' || prix == 0 &&  type == 'Eneo') {
      this.toastr.error('Le numéro de la facture et le montant sont incorrectes', 'Inconcevable');
    } else { 
      switch (this.mois1) {
        case '01' :
            this.mois1 = 'Janvier';
        break;
        case '02' :
            this.mois1 = 'Fevrier';
        break;
        case '03':
            this.mois1 = 'Mars';
        break;
        case '04':
            this.mois1 = 'Avril';
        break;
        case '05':
            this.mois1 = 'Mai';
        break;
        case '06':
            this.mois1 = 'Juin';
        break;
        case '07':
            this.mois1 = 'Juillet';
            break;
        case '08':
            this.mois1 = 'Août';
            break;
        case '09':
            this.mois1 = 'Semptembre';
            break;
        case '10' :
            this.mois1 = 'Octobre';
            break;
        case '11' :
            this.mois1 = 'Novembre';
            break;
        case '12' :
            this.mois1 = 'Decembre';
        break;
      }
  
      this.factureMois = [];
      this.factureElectricite.forEach(element => {
        if ( element['Mois'] == this.mois1 && element['Annee'] == this.annee) {
          this.factureMois.push(element);
        }
      });
      if ( this.factureMois.length >= 1) { 
        this.toastr.info('Il existe déjà une fature d\'électricité pour le mois de '+this.mois1 + ' ' + this.annee, 'Information');
      } else {
        if ( type == 'Eneo' ) {
          this.numero = numFact;
          this.indexInitial = 0;
          this.indexFinal = 0;
          this.prixKwh = 0;
          this.prixEntretien = 0;
          this.prix = prix;
        } else if ( type == 'Divisionnaire' ) {
          this.i = this.numeroFacture.split('_');
          this.i[1]++;
          this.numero = this.i.join('_');
          console.log(this.numeroFacture, this.i);
          this.indexInitial = this.indexInitial;
          this.indexFinal =  indexFinal;
          this.prixKwh = this.prixKwh;
          this.prixEntretien = this.prixEntretien;
          this.prix = parseInt(((this.indexFinal - this.indexInitial) * this.prixKwh).toString()) + parseInt(this.prixEntretien.toString());
        }
        console.log(this.numero, type, this.prix, this.indexInitial, this.indexFinal, this.prixKwh, this.prixEntretien,  dateLimite,
          this.mois1, this.annee, this.idAppart);
        if( this.indexFinal <= this.indexInitial && type == 'Divisionnaire') {
          this.toastr.error('L\'ancien index n\'est jamais inférieur ou égal au nouveau index.', 'Inconcevable!');
        } else {
          this.service.numeroFacture(this.idAppart, this.numero).subscribe(data => {
            if(data == 1) {
                if(confirm('Le numéro de facture '+this.numero+' existe déjà. \n\n Voulez-vous le modifier?????')) {
                    
                } else {
                  this.toastr.info("La facture n\'a pas été enregistré", 'Information');
                  this.router.navigate(['/listFactElect', this.idAppart]);
                }
            } else {
                this.service.create(this.numero, type, this.prix, this.indexInitial, this.indexFinal, this.prixKwh, this.prixEntretien,  
                  dateLimite, this.mois1, this.annee, this.idAppart).subscribe(data => {
                  this.router.navigate(['/listFactElect', this.idAppart]);
                  this.toastr.success('Facture d\'électricité créée!', 'Succèss!');
                  
              });
            }
          });
        }
      }
    }
    
  }

}
