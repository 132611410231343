import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageMap } from '@ngx-pwa/local-storage';
import { UserService } from 'app/shared/services/user/user.service';
import { LocataireService } from 'app/shared/services/locataire/locataire.service';
import { AppartementService } from 'app/shared/services/appartement/appartement.service';

@Component({
  selector: 'app-modify-user',
  templateUrl: './modify-user.component.html',
  styleUrls: ['./modify-user.component.css']
})
export class ModifyUserComponent {

  constructor(private routes:ActivatedRoute,
              private storage:StorageMap,
              private serviceLocataire:LocataireService,
              private serviceAppartement:AppartementService,
              private router:Router) {
    this.serviceAppartement.read(JSON.parse(localStorage.getItem('bt'))['idBat']).subscribe((a:[])=>{this.apparts=a})
    this.routes.queryParams.subscribe((param)=>{
      if(param && param['id']){
        this.serviceLocataire.find(param['id']).subscribe((U)=>{console.log(U)
        this.serviceAppartement.find(U['IdAppartement']).subscribe((A)=>{this.appartement=A['NumAppart']})
        this.user=U
        })
        /*this.storage.get('users').subscribe((user:[])=>{console.log(user)
          user.forEach((user) => {
            if(user['utilisateursID']==param['id']){
              this.user=user;
              console.log(this.user)
            }
          });
        })*/
      }
    })
   }

   edit(){
     if(this.phone !=null) this.user['Telephone']=this.phone
     if(this.name !=null) this.user['NomLocataire']=this.name
     if(this.surname !=null) this.user['PrenomLocataire']=this.surname
     if(this.metier !=null) this.user['Metier']=this.metier
     if(this.email !=null) this.user['Email']=this.email
     if(this.statut !=null) this.user['Statue']=this.statut
     if(this.cni !=null) this.user['CNI']=this.cni
     if(this.nbr !=null) this.user['NbrePersonnes']=this.nbr
     if(this.newappart !=null) this.user['IdAppartement']=this.newappart['IdAppart']
      console.log(this.user)
     this.serviceLocataire.update(this.user).subscribe((res)=>{
      if(res['statut']==true && res['message']=='good') this.router.navigateByUrl("listuser")})
   }

   ap(numero){
    this.newappart=this.apparts.find(m=>m['NumAppart']==numero)
    console.log(this.newappart)
   }

     user={};
     phone:string
     name:string
     surname:string
     metier:string
     statut:string
     email:string
     cni:string
     nbr:string
     idap:string
     appartement:string
     apparts=[]
     newappart:any
}
