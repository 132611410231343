<div class="row">
    <div class="col">
        <input type='text' style='padding:8px;margin:15px auto;width:100%;' placeholder='Rechercher...' (keyup)="applyFilter($event)">
        <mat-card>
            <div class="t-responsive table-responsive-xl">
                <table mat-table matSort [dataSource]="dataSource" style="width: 100%;">
                    <!-- Name Column -->
                    <ng-container matColumnDef="Nom">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col" class="thead-dark1">
                            Nom
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.Nom }}
                        </td>
                    </ng-container>
                    <!-- Weight Column -->
                    <ng-container matColumnDef="Prenom">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col" class="thead-dark1">
                            Prenom
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.Prenom }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="Contact">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col" class="thead-dark1">
                            Contact
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.Contact }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="DateAjout">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col" class="thead-dark1">
                            enregistré le
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.DateAjout }}
                        </td>
                    </ng-container>
                    <!-- Symbol Column -->
                    <ng-container matColumnDef="vue">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col" class="thead-dark1">
                            Vue
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <a class="info p-0" data-original-title="" title="vue" routerLinkActive="active"  (click)="openDialog(element.Idprestataire)">
                                <i class="ft-eye font-medium-3 mr-2"></i>
                            </a>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                </table>
                <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
            </div>
            <div class="type-1 row justify-content-center">
                <a class="btn btn-1" routerLink="/Listcharge/{{batId}}">
                  <span class="txt">Retour</span>
                  <span class="round"><i class="fa fa-chevron-left" style="color: white;"></i></span>
                </a>
              </div>
        </mat-card>
    </div>
 
  </div>