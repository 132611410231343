<div class="row">
  <div class="col-9">
    <section id="filter" class="mb-3">
      <div class="row">
          <div class="col-sm-12">
              <input type='text' style='padding:8px;margin:15px auto;width:100%;' placeholder='Rechercher...' (keyup)='updateFilter($event)'
              />
              <table mat-table matSort [dataSource]="dataSource" class="mat-elevation-z8" style="width: 100%;">
                <ng-container matColumnDef="id">
                  <th mat-header-cell *matHeaderCellDef scope="col" mat-sort-header> # </th>
                  <td mat-cell *matCellDef="let i=index">
                    {{i+1}} </td>
                </ng-container>
                  <ng-container matColumnDef="Locataire">
                    <th mat-header-cell *matHeaderCellDef scope="col" mat-sort-header> Locataire </th>
                    <td mat-cell *matCellDef="let element; let i=index"  routerLinkActive="active">
                        {{locataires[i].NomLocataire }}  {{ locataires[i].PrenomLocataire}}  </td>
                  </ng-container>
                  <ng-container matColumnDef="MontantPaiement">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col" mat-sort-header> Montant Paiement </th>
                      <td mat-cell *matCellDef="let element" >
                         {{element.montantpaiement}} </td>
                  </ng-container>
                  <ng-container matColumnDef="Mois">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col" mat-sort-header> Mois </th>
                    <td mat-cell *matCellDef="let element; let i=index">
                        {{element.Mois}} </td>
                </ng-container>
                <ng-container matColumnDef="Annee">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col" mat-sort-header> Annee </th>
                  <td mat-cell *matCellDef="let element" >
                      {{element.Annee}} </td>
              </ng-container>

              <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col" class="thead-dark1"> Actions </th>
                <td mat-cell *matCellDef="let element">
                  <a class="danger p-0" (click)="delete(element.idpaiement)" data-original-title="" title="Supprimer">
                    <i class="ft-trash font-medium-3 mr-2"></i>
                  </a>
                </td>
            </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
           
            <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" showFirstLastButtons></mat-paginator>
              
                
          </div>
      </div>
    </section>
  </div>

  <div class="card col-3 div2" >
    <div>
      <nav>
          <div >
            <div class="card-title entete">
              <h2>Actions</h2>
            </div>
              
              <ul class="card-body" style="list-style-type: none;">
                  <li>
                    <button class="btn  btn-lg btn-block btn-success btn-raised btn-sm font-weight-light" (click)="redirect()">
                      <i class="ft-plus"></i> Ajouter
                    </button>
                  </li>

                  <li style="margin-top: 40px;">
                    <div class="type-1 row justify-content-center">
                        <a (click)="retour()" class="btn btn-1">
                          <span class="txt">Retour</span>
                          <span class="round"><i class="fa fa-chevron-left" style="color: white;"></i></span>
                        </a>
                      </div>
                </li>
              </ul>
          </div>
      </nav>
    </div>
  </div>

</div>

