import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { UrlConfigService } from '../urlConfig/url-config.service';


@Injectable({
  providedIn: 'root'
})
export class AppartementService {
  url: any
  constructor(private httpClient: HttpClient,
              private urlconfig: UrlConfigService) {
                this.url = this.urlconfig.getUrl()
               }

  create(numAppart: number, nbreSalon: number,
          nbreCuisine: number, nbreChambre: number,
          nbreSalleBain: number, nbreSalleEau: number, idBatiment: number) {
    const obj = {
      NumAppart: numAppart,
      NombreSalon: nbreSalon,
      NombreCuisine: nbreCuisine,
      NombreChambre: nbreChambre,
      NombreSalleBain: nbreSalleBain,
      NombreSalleEau:  nbreSalleEau,
      IdBatiment: idBatiment
    };
      return this.httpClient.post(this.url + 'api_gesap/controllers/Appartements/create.php', obj);
  }

  read(idBati: number) {
    console.log(idBati)
    return this.httpClient.post(this.url + 'api_gesap/controllers/Appartements/read.php', {id: idBati});
  }
  readAppartbyProfil(idBati: string, profils: string) {
    return this.httpClient.post(this.url + 'api_gesap/controllers/Appartements/readAppartbyProfil.php', {id: idBati, profil: profils});
  }
  reads() {
    return this.httpClient.post(this.url + 'api_gesap/controllers/Appartements/reads.php', {});
  }
  contratAppartBat(idBat){
    return this.httpClient.post(this.url + 'api_gesap/controllers/Contrat_bail/contratAppartBat.php', {id: idBat})
  }
  readByNonContract(idBat){
    return this.httpClient.post(this.url + 'api_gesap/controllers/Appartements/readByNonContract.php', {id: idBat})
  }

  numeroAppartement(idBatiment: number, NumAppart: string) {
    return this.httpClient.post(this.url + 'api_gesap/controllers/Appartements/NumeroAppartement.php',
    { idBati: idBatiment, numAppart: NumAppart});
  }

  find(idAppart) {
    return this.httpClient.post(this.url + 'api_gesap/controllers/Appartements/find.php', { id: idAppart } )
  }

  findAll(id=2){
    return this.httpClient.post(this.url+'api_gesap/controllers/Appartements/read.php', {id})
  }

  delete(idAppart: number) {
    return this.httpClient.post(this.url + 'api_gesap/controllers/Appartements/delete.php', {id: idAppart})
  }

  update(idAppart: number, numAppart: number, nbreSalon: number,
        nbreCuisine: number, nbreChambre: number,
        nbreSalleBain: number, nbreSalleEau: number) {
    const obj = {
      IdAppart: idAppart,
      NumAppart: numAppart,
      NombreSalon: nbreSalon,
      NombreCuisine: nbreCuisine,
      NombreChambre: nbreChambre,
      NombreSalleBain: nbreSalleBain,
      NombreSalleEau:  nbreSalleEau,
    };
    return this.httpClient.post(this.url + 'api_gesap/controllers/Appartements/update.php', obj);
  }

  appartOfBat(id) {
    return this.httpClient.post(this.url + 'api_gesap/controllers/Appartements/appartOfBat.php', { id: id })
  }


}
