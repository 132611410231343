import { Appartement } from './../../interface/appartement';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AppartementService } from './../../shared/services/appartement/appartement.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { BatimentService } from './../../shared/services/batiment/batiment.service';
import { ToastrService } from 'ngx-toastr';




@Component({
  selector: 'app-liste-appt',
  templateUrl: './liste-appt.component.html',
  styleUrls: ['./liste-appt.component.css']
})
export class ListeApptComponent implements OnInit {

  ELEMENT_DATA: any;
  idBati: string;
  appartement: any;
  profil: string;
  batiment: any;

  appart: any;
    rows = [];

    temp = [];

    filterValues = {};

  dataSource = new MatTableDataSource()
  displayedColumns: string[] = ['id', 'NumAppart',  'NombreSalon', 'NombreCuisine', 'NombreChambre', 'NomBatiment', 'action'];
  @ViewChild(MatSort) sorte: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  filterSelectObj = [];

  constructor(private service: AppartementService, private router: Router, private route: ActivatedRoute,
             private serviceBatiment: BatimentService, public toastr: ToastrService)
  {
    this.filterSelectObj = [
      {
        name: 'NUMERO APPARTEMENT',
        columnProp: 'NumAppart',
        options: []
      }, {
        name: 'NOM BATIMENT',
        columnProp: 'NomBatiment',
        options: []
      }
    ]
  }


  ngOnInit(): void {
    const obj = localStorage.getItem('bt');
    const objJson = JSON.parse(obj);
    this.idBati = objJson.idBat;
    this.profil = objJson.profil;
    if(this.idBati != 'All' && this.profil != 'ADMINISTRATEUR') {
      this. serviceBatiment.find(this.idBati).subscribe(data => {
        this.batiment = data;
      });
    }
    this.getRemoteData();
   
    // Overrride default filter behaviour of Material Datatable
    this.dataSource.filterPredicate = this.createFilter();
  }


  // Get Uniqu values from columns to build filter
  getFilterObject(fullObj, key) {
    const uniqChk = [];
    fullObj.filter((obj) => {
      if (!uniqChk.includes(obj[key])) {
        uniqChk.push(obj[key]);
      }
      return obj;
    });
    return uniqChk;
  }

  // Get remote serve data using HTTP call
  getRemoteData() {
    this.service.readAppartbyProfil(this.idBati, this.profil).subscribe((data: any) => {
      this.appartement = data;
      this.temp = [...this.appartement];
      this.dataSource.data = data;
      this.dataSource.sort = this.sorte;
      this.dataSource.paginator = this.paginator;
      console.log(data);
      console.log(this.appartement);
  
      this.filterSelectObj.filter((o) => {
          o.options = this.getFilterObject(this.appartement, o.columnProp);
      });
      
    });
    
  }

  refresh() {
    this.service.readAppartbyProfil(this.idBati, this.profil).subscribe((data: Appartement[]) => {
      this.appartement = data;
      this.dataSource.data = data;
      this.dataSource.sort = this.sorte;
      this.dataSource.paginator = this.paginator;
      this.temp = [...this.appartement];
      
        console.log(data);
    });
  }

  
  // Called on Filter change
  filterChange(filter, event) {
    //let filterValues = {}
    this.filterValues[filter.columnProp] = event.target.value.trim().toLowerCase()
    this.dataSource.filter = JSON.stringify(this.filterValues)
  }

  // Custom filter method fot Angular Material Datatable
  createFilter() {
    let filterFunction = function (data: any, filter: string): boolean {
      let searchTerms = JSON.parse(filter);
      let isFilterSet = false;
      for (const col in searchTerms) {
        if (searchTerms[col].toString() !== '') {
          isFilterSet = true;
        } else {
          delete searchTerms[col];
        }
      }

      console.log(searchTerms);

      let nameSearch = () => {
        let found = false;
        if (isFilterSet) {
          for (const col in searchTerms) {
            searchTerms[col].trim().toLowerCase().split(' ').forEach(word => {
              if (data[col].toString().toLowerCase().indexOf(word) != -1 && isFilterSet) {
                found = true
              }
            });
          }
          return found
        } else {
          return true;
        }
      }
      return nameSearch()
    }
    return filterFunction
  }


  // Reset table filters
  resetFilters() {
    this.filterValues = {}
    this.filterSelectObj.forEach((value, key) => {
      value.modelValue = undefined;
    })
    this.dataSource.filter = "";
  }

  createAppart() {
    console.log(this.idBati);
      if ( this.batiment.NombreAppart == this.batiment.NbApCr ) {
        this.toastr.info('Vous ne pouvez plus créer un appartement.', 'Info!');
         //alert("Vous ne pouvez plus créer un appartement"); 
      } else {
        this.router.navigate(['/ajoutAppart', this.idBati]);
      }
  }


  

  updateFilter(event) {
      const val = event.target.value.toLowerCase();

      // filter our data
      const temp = this.temp.filter(function (d) {
          return d.NumAppart.toLowerCase().indexOf(val) !== -1 || !val;
      });

      // update the rows
      this.dataSource.data = temp;
      
  }

  delete(id) {
    this.service.find(id).subscribe(data1 => {
        this.appart = data1;
        if (confirm("Voulez-vous vraiment supprimer l'appartement "+ this.appart.NumAppart+ " ?????")) {
          this.service.delete(id).subscribe(data => {
            this.refresh();
            this.toastr.error('Appartement supprimé.', 'Suppression!');
          });
        }
    });
    
  }

}
