import { Component, OnInit, ViewChild } from '@angular/core';
import { UserService } from 'app/shared/services/user/user.service';
import { StorageMap } from '@ngx-pwa/local-storage';
import { Router } from '@angular/router';
import { AppartementService } from 'app/shared/services/appartement/appartement.service';
import { LocataireService } from 'app/shared/services/locataire/locataire.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import {
  NgbModal,
  ModalDismissReasons,
  NgbActiveModal,
} from "@ng-bootstrap/ng-bootstrap";
import { ViewEncapsulation } from '@angular/compiler/src/core';
import { Utilisateur } from 'app/user/Utilisateur';
import { ContratBailService } from 'app/shared/services/contratBail/contrat-bail.service';

@Component({
  selector: 'app-list-user',
  templateUrl: './list-user.component.html',
  styleUrls: ['./list-user.component.css'],
  //encapsulation: ViewEncapsulation.None
})
export class ListUserComponent implements OnInit{

  c:any
  cont=[]
  tab=[]
  locataires:Utilisateur[]
  idBati:number;
  users:any
  ELEMENT_DATA: Utilisateur[];
  single:any;
  closeResult: string;
  displayedColumns: string[] = ['NomLocataire','PrenomLocataire','Telephone','NbrePersonnes','Email','CNI','Action'];
  dataSource = new MatTableDataSource<Utilisateur>(this.ELEMENT_DATA);
  @ViewChild(MatSort, { static: true }) sorte: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;


  constructor(private serviceUser:UserService,
              private serviceAppartement:AppartementService,
              private serviceLocataire: LocataireService,
              private serviceContrat: ContratBailService,
              private storage:StorageMap,
              private router:Router,
              private modalService: NgbModal) {}
  
  ngOnInit(){
    this.refresh();
  }

  refresh(){
    console.log('bt',localStorage.getItem('bt'))
    const obj = localStorage.getItem('bt');
    const objJson = JSON.parse(obj);
    this.idBati = objJson.idBat;

    let a=JSON.parse(localStorage.getItem('bt'))
    this.serviceAppartement.findAll(a['idBat']).subscribe((apart:[])=>{
      console.log(apart)
      if(apart.length !=0){this.tab=apart;
        this.serviceLocataire.findAll(a['idBat']).subscribe((locataire:Utilisateur[])=>{
          this.locataires=locataire
          console.log(locataire)
          this.dataSource.data=locataire
          locataire.forEach(elt => {
          this.serviceContrat.findByIdLocataire(elt['IdLocataire']).subscribe((contrat)=>{console.log(contrat);this.cont.push(contrat)})
         });
   
      })
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sorte;  
      }})
  }

  open(content,elt) {
    this.modalService.open(content).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
    this.single=elt;
    console.log(this.single);
  }
  

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }


  delete(id){
    console.log(id)
      this.serviceLocataire.delete(id).subscribe((res)=>{
          console.log(res)
          if(res['statut']==true && res['message']=='good'){
            alert('Suppression Reussit !')
            this.refresh();
            }
      })
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  click(id){
    this.storage.set('i', id)
    return true
  }

  retour(){
    if(JSON.parse(localStorage.getItem('bt'))['profil']=='ADMINISTRATEUR'){
      this.router.navigateByUrl('/dashboard/dashboard1')
    }else{
      this.router.navigateByUrl('/dashboard/dashboard2')
    }
  }
  
}
