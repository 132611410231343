
import { ToastrService } from 'ngx-toastr';
import { Injectable } from '@angular/core';

@Injectable()
export class NGXToastrService {
    constructor(public toastr: ToastrService) { }

    // Success Type
    typeSuccess() {
        this.toastr.success('Ajout Du Contrat Reussi Avec Succèss !', 'Succès');
    }
    // Success Type
    typeError() {
        this.toastr.error('Veuillez Renseigner Les Champs !', 'Erreur');
    }

    typeMontant(){
        this.toastr.error('Veuillez Entrer un montant Valide !', 'Erreur')
    }
    typeEmail(){
        this.toastr.error('Email Non Valide !', 'Erreur')
    }
    typeInferieur(){
        this.toastr.error('Le Versement doit etre supérieur ou équivalent au Montant Mensuel')
    }
    typeDuplication(){
        this.toastr.error('Mois Selectionnée déja Payer !')
    }
}