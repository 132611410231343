import { Component, OnInit,ViewChild ,ViewEncapsulation} from '@angular/core';
import { BatimentService } from 'app/shared/services/batiment/batiment.service';
import { Batiment } from '../batiment';
import { Router, ActivatedRoute } from '@angular/router';
import {
  NgbModal,
  ModalDismissReasons,
  NgbActiveModal,
} from "@ng-bootstrap/ng-bootstrap";

import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
@Component({
  selector: 'app-listes-bat',
  templateUrl: './listes-bat.component.html',
  styleUrls: ['./listes-bat.component.css'],
  encapsulation: ViewEncapsulation.None

})
export class ListesBatComponent implements OnInit {
  ELEMENT_DATA: Batiment[];
  single:any;
  closeResult: string;
  displayedColumns: string[] = ['nombatiment','region','ville','adresse','telephone','nombreappart','type','nombreetage','statut','action'];
  dataSource = new MatTableDataSource<Batiment>(this.ELEMENT_DATA);
  id:any
  @ViewChild(MatSort, { static: true }) sorte: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  constructor(private service:BatimentService,
              private router:Router,
              private routes:ActivatedRoute,
              private modalService: NgbModal
    ) { 
        this.id=JSON.parse(localStorage.getItem('bt'))['idUser']
        console.log(localStorage.getItem('bt'))
    }

  ngOnInit(): void {
    //this.getAllReports();
    this.refresh();
  }
 refresh()
  {
            this.service.read().subscribe((allBatiment:Batiment[])=>{
              console.log(allBatiment)
              this.dataSource.data=allBatiment;
              this.dataSource.paginator = this.paginator;
              this.dataSource.sort = this.sorte;
          })
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

Ondelete(id: number) {
  if (confirm('voulez-vous vraiment supprimer ce batiment??')) {
    this.service.delete(id).subscribe((data) => {
      console.log(data);
      this.refresh();
      this.router.navigate(['/ListBatiment']);
    });
  }
}

open(content,elt) {
  this.modalService.open(content).result.then(
    (result) => {
      this.closeResult = `Closed with: ${result}`;
    },
    (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    }
  );
  this.single=elt;
  console.log(this.single);
}

private getDismissReason(reason: any): string {
  if (reason === ModalDismissReasons.ESC) {
    return "by pressing ESC";
  } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
    return "by clicking on a backdrop";
  } else {
    return `with: ${reason}`;
  }
}

}
