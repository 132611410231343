<!-- Reactive Form Validaton Starts -->
<div>
    <div class="card">
        <div class="card-header">
            <h4 class="card-title">Ajouter une Tâche</h4>
        </div>
        <div class="card-content">
            <div class="card-body" *ngIf="editform">
                <form [formGroup]="editform" novalidate class="">
                    <div class="row">
                        <div class="col form-group">
                            <label for="IdBatiment">Batiment</label>
                            <input type="text" class="form-control" id="IdBatiment" formControlName="IdBatiment" required>

                            <small class="form-text text-muted danger" *ngIf="
                !editform.get('IdBatiment').valid &&
                (editform.get('IdBatiment').dirty ||
                  editform.get('IdBatiment').touched)
              ">champ obligatoire</small>
                        </div>
                        <div class="col form-group">

                            <label for="Appart">Appartement</label>
                            <span>
                  <select class="form-control" id="IdAppart" formControlName="IdAppart" required>
                    <option value="All">All</option>
                    <option *ngFor="let appart of appart" value="{{appart.IdAppart }}">{{
                                    appart.NumAppart
                    }}</option>
                  </select>
                </span>
                            <small class="form-text text-muted danger" *ngIf="
                    !editform.get('IdAppart').valid &&
                    (editform.get('IdAppart').dirty ||
                      editform.get('IdAppart').touched)
                  ">champ obligatoire</small>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col form-group">
                            <label for="categorie">Categorie</label>
                            <select class="form-control" id="categorie" formControlName="categorie" required>
                  <option>---</option>
                  <option *ngFor="let genre of Genre" value="{{ genre }}">
                    {{genre}}
                  </option>
                </select>
                            <small class="form-text text-muted danger" *ngIf="
                  !editform.get('categorie').valid &&
                  (editform.get('categorie').dirty ||
                    editform.get('categorie').touched)
                ">champ obligatoire
                </small>
                        </div>
                        <div class="col form-group">
                            <label for="Priorite">Priorite</label>
                            <select class="form-control" id="Genre" formControlName="Priorite" required>
                  <option value=0>Basse</option>
                  <option value=1>Moyen</option>
                  <option value=2>Haute</option>
                </select>

                            <small class="form-text text-muted danger" *ngIf="
                  !editform.get('Priorite').valid &&
                  (editform.get('Priorite').dirty ||
                    editform.get('Priorite').touched)
                ">champ obligatoire</small>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col form-group">
                            <label for="Description">Description</label>
                            <input type="text" class="form-control" id="Description" formControlName="Description" required />
                            <small class="form-text text-muted danger" *ngIf="
              !editform.get('Description').valid &&
              (editform.get('Description').dirty ||
                editform.get('Description').touched)
            ">champ obligatoire</small>
                        </div>
                        <div class="col form-group" *ngIf='Cout!=0'>
                            <label for="cout">Coût</label>
                            <input type="text" class="form-control" id="cout" formControlName="Cout" required />
                            <small class="form-text text-muted danger" *ngIf="
              !editform.get('Cout').valid &&
              (editform.get('Cout').dirty ||
                editform.get('Cout').touched)
            ">champ obligatoire</small>
                        </div>
                    </div>

                    <div class="form-group text-center">
                        <button type="button" class="btn bg-danger btn-raised mr-1 text-white" routerLink="/Viewcharge/{{id}}">
                Annuler
              </button>
                        <button type="button" class="btn bg-success btn-raised text-white" [disabled]="!editform.valid" (click)="Onsubmit()">
                Enregistrer
              </button>
                    </div>
                </form>
            </div>
            <div class="type-1 row justify-content-center">
                <a class="btn btn-1" routerLink="/Viewcharge/{{id}}">
                    <span class="txt">Retour</span>
                    <span class="round"><i class="fa fa-chevron-left" style="color: white;"></i></span>
                </a>
            </div>
        </div>
    </div>
</div>