import { Component, OnInit, ViewChild } from '@angular/core';
import { FactureElectriciteService } from './../../shared/services/factureElectricite/facture-electricite.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FactureElectricite } from './../../interface/factureElectricite';

@Component({
  selector: 'app-fact-elect-impayee',
  templateUrl: './fact-elect-impayee.component.html',
  styleUrls: ['./fact-elect-impayee.component.css']
})
export class FactElectImpayeeComponent implements OnInit {

  idAppart: number;
  idbat:number;
  ELEMENT_DATA: FactureElectricite[];
  factureElectricite: any;
  facture: any;
  temp = [];
  displayedColumns: string[] = ['id', 'NumFactElect',  'TypeCompteur', 'Prix', 'DateLimitePayement', 'Status', 'action'];
  dataSource = new MatTableDataSource<FactureElectricite>(this.ELEMENT_DATA);
  @ViewChild('MatSort') sorte: MatSort;
  @ViewChild( 'TableOnePaginator' ) tableOnePaginator: MatPaginator;
  
  constructor(private router: Router, private route: ActivatedRoute, private service: FactureElectriciteService) { }

  ngOnInit(): void {
    this.refresh();
  }

  refresh() {
    this.idbat = JSON.parse(localStorage.getItem("bt"))["idBat"];

    this.service. facture_no_regleAll(this.idbat).subscribe((data: FactureElectricite[]) => {
      this.factureElectricite = data;
      //this.temp = [...this.factureElectricite];
      this.dataSource.data = data;
      this.dataSource.paginator = this.tableOnePaginator;
      this.dataSource.sort = this.sorte;
      console.log(data);
      console.log(this.factureElectricite);
    });
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    // filter our data
    const temp = this.temp.filter(function (d) {
        return d.NumFactElect.toLowerCase().indexOf(val) !== -1 || !val;
    });
    // update the rows
    this.dataSource.data = temp;
  }
  
  delete(id) {
    this.service.find(id).subscribe(data => {
      this.facture = data;
      if (confirm(" Voulez-vous vraiment supprimer la facture "+this.facture.NumFactElect+" d\'électricité?????")) {
        this.service.delete(id).subscribe(data1 => {
          this.refresh();
        });
      }
    })
    
  }
}
