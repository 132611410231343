import { NgModule } from '@angular/core';
import { MatSelectModule } from '@angular/material/Select';
import { MatTabsModule } from '@angular/material/tabs';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatDividerModule } from '@angular/material/divider';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

const material =
    [
        MatSelectModule,
        MatTabsModule,
        MatPaginatorModule,
        MatCardModule,
        MatFormFieldModule,
        MatInputModule,
        MatSortModule,
        MatTableModule,
        MatDividerModule,
        MatDialogModule,
        MatProgressSpinnerModule

    ];
@NgModule({
    imports: [material],
    exports: [material]
})
export class MaterialModule { }
