<div class="row">
    <div class="col-9">
        <span class="h1" text-center> Liste des Travaux Resolus</span>

        <input type='text' style='padding:8px;margin:15px auto;width:100%;' placeholder='Rechercher...' (keyup)="applyFilter($event)">
        <mat-card>
            <div class="t-responsive table-responsive-xl">
                <table mat-table matSort [dataSource]="dataSource" style="width: 100%;">
                    <!-- Name Column -->
                    <ng-container matColumnDef="Categorie">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col" class="thead-dark1">
                            Catégorie du problème
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.Categorie }}
                        </td>
                    </ng-container>
                    <!-- Weight Column -->
                    <!-- Symbol Column -->
                    <ng-container matColumnDef="Priorite">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col" class="thead-dark1">
                            Priorité
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <span *ngIf="element.Priorite == 3">Haute</span>
                            <span *ngIf="element.Priorite == 2">Moyenne</span>
                            <span *ngIf="element.Priorite == 1">Base</span>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="IdAppart">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col" class="thead-dark1">
                            Appartement
                        </th>
                        <td mat-cell *matCellDef="let element">{{ element.IdAppart }}</td>
                    </ng-container>

                    <ng-container matColumnDef="Cout">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col" class="thead-dark1">
                            Appartement
                        </th>
                        <td mat-cell *matCellDef="let element">{{ element.Cout }}</td>
                    </ng-container>

                    <ng-container matColumnDef="DateOcc">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col" class="thead-dark1">
                            Enregistee Le
                        </th>
                        <td mat-cell *matCellDef="let element">{{element.DateOcc | date:'EEEE d MMMM y'}}</td>
                    </ng-container>

                    <ng-container matColumnDef="Reolule">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col" class="thead-dark1">
                            Resolu Le
                        </th>
                        <td mat-cell *matCellDef="let element">{{element.DateResol | date:'EEEE d MMMM y'}}</td>
                    </ng-container>

                    <ng-container matColumnDef="vue">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col" class="thead-dark1">
                            Vue
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <a class="info p-0" data-original-title="" title="vue" routerLinkActive="active" routerLink="/Viewcharge/{{element.IdTaf}}">
                                <i class="ft-eye font-medium-3 mr-2"></i>
                            </a>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="Statut">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col" class="thead-dark1">
                            Statut
                        </th>
                        <td mat-cell *matCellDef="let element; let i = index">
                            <div class="dropdown btn-group dropup mr-1 mb-1" ngbDropdown *ngIf='(element.Statut=="attente") || (element.Statut=="non resolu")'>
                                <button type="button" class="btn btn-secondary btn-raised" *ngIf='element.Statut=="non resolu"'>
                                {{ element.Statut }}
                              </button>
                                <button type="button" class="btn btn-success btn-raised" *ngIf='element.Statut=="resolu"'>
                                {{ element.Statut }}
                              </button>
                                <button type="button" class="btn btn-info btn-raised" *ngIf='element.Statut=="attente"'>
                                {{ element.Statut }}
                              </button>
                                <button type="button" class="btn btn-danger btn-raised dropdown-toggle" ngbDropdownToggle aria-haspopup="true" aria-expanded="false">
                                <span class="sr-only">Toggle Dropdown</span>
                              </button>
                                <div ngbDropdownMenu class="dropdown-menu" role="menu">
                                    <a class="dropdown-item" (click)="changestatut(element.IdTaf, 'attente')">Attente</a>
                                    <a class="dropdown-item" (click)="openDialog(element.IdTaf)">Resolu</a>
                                </div>
                            </div>
                            <div class="dropdown btn-group dropup mr-1 mb-1" ngbDropdown *ngIf='element.Statut=="resolu"'>
                                <button type="button" class="btn btn-success btn-raised" disabled>
                                {{ element.Statut }}
                              </button>
                                <button type="button" class="btn btn-danger btn-raised dropdown-toggle" ngbDropdownToggle aria-haspopup="true" aria-expanded="false" disabled>
                                <span class="sr-only">Toggle Dropdown</span>
                              </button>
                                <div ngbDropdownMenu class="dropdown-menu" role="menu">
                                    <a class="dropdown-item" (click)="changestatut(element.IdTaf, 'attente')">Attente</a>
                                    <a class="dropdown-item" (click)="openDialog(element.IdTaf)">Resolu</a>
                                </div>
                            </div>
                            <!--<div *ngIf='element.Statut=="resolu"'>
                                <a class="success p-0" data-original-title="" routerLink="" title="">
                                    <i class="ft-edit-2 font-medium-3 mr-2"></i>
                                </a>
                                <a class="danger p-0" data-original-title="" title="" (click)="Ondelete(element.IdTaf)">
                                    <i class="ft-x font-medium-3 mr-2"></i>
                                </a>
                            </div>-->
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                </table>
                <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
            </div>
        </mat-card>
    </div>
    <div class="card col-3 div2">
        <div>
            <nav style="min-width: 210px;">
                <div>
                    <div class="card-title entete">
                        <h2>Actions</h2>
                    </div>

                    <ul class="card-body" style="list-style-type: none;">
                        <li class="nav-item" ngbDropdown display="dynamic">
                            <button id="dropdownBasic3" ngbDropdownToggle class="btn btn-lg btn-block bg-primary btn-raised btn-sm font-weight-light">
                          <i class="ft-plus font-medium-3 blue-grey darken-4"></i> Ajout Tâche
                        </button>

                            <div ngbDropdownMenu aria-labelledby="dropdownBasic3" class="dropdownLang text-left">
                                <a class="dropdown-item py-1 lang" styleclass="dropdown-item py-1" routerLink="/Addcharge/{{batId}}/idBat">
                                    <i class="ft-plus mr-2"></i> En General
                                </a>
                                <a class="dropdown-item py-1 lang" styleclass="dropdown-item py-1" routerLink="/Addcharge/null/idAppart">
                                    <i class="ft-list mr-2"></i> Pour un Appartement
                                </a>
                            </div>
                        </li>
                        <li>
                            <button class="btn  bg-info btn-lg btn-block btn-raised btn-sm font-weight-light" routerLink="/Listprest/{{batId}}">
                          <i class="ft-list"></i> Listes des Prestataires
                        </button>
                        </li>
                        <li style="margin-top: 40px;">
                            <div class="type-1 row justify-content-center">
                                <a class="btn btn-1" routerLink="/dashboard/dashboard2">
                                    <span class="txt">Retour</span>
                                    <span class="round"><i class="fa fa-chevron-left" style="color: white;"></i></span>
                                </a>
                            </div>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
    </div>
</div>