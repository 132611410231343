import { Component, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators, NgForm } from "@angular/forms";
import { BatimentService } from "app/shared/services/batiment/batiment.service";
import { ActivatedRoute, Router } from "@angular/router";
import { AppartementService } from "app/shared/services/appartement/appartement.service";
import { ChargeService } from "app/shared/services/charge/charge.service";
import { Batiment } from "app/batiments/batiment";

@Component({
  selector: "app-add-charg",
  templateUrl: "./add-charg.component.html",
  styleUrls: ["./add-charg.component.css"],
})
export class AddChargComponent implements OnInit {
  @ViewChild("f") floatingLabelForm: NgForm;
  @ViewChild("vform") validationForm: FormGroup;
  editform: FormGroup;
  mobnumPattern = "^[6]+[5|6|7|9]+[0-9]{7}$";
  unamePattern = "^[a|A-z|Z0-9_-| ]{5,200}$";
  options: Array<string>;
  Genre = [
    "électricité",
    "eau",
    "plomberie",
    "maçonnerie",
    "nettoyage",
    "autre",
  ];
  bat: any;
  appart: any;
  id;
  ref:any;
  idbat;

  constructor(
    private servbat: BatimentService,
    private servappart: AppartementService,
    private service: ChargeService,
    private router: Router,
    private route: ActivatedRoute,

  ) { }

  ngOnInit() {
   

    this.refresh();
  }

  refresh(){
    let nombat;
    this.idbat = JSON.parse(localStorage.getItem('bt'))['idBat'];
    this.id= this.route.snapshot.params['id'];
    this.ref= this.route.snapshot.params['ref'];
    this.servbat.find(this.idbat).subscribe((data:any)=>{
      console.log(data);
      this.bat=data;
      nombat=this.bat.NomBatiment;
      
    if(this.ref=='idBat')
    {  
      this.editform = new FormGroup(
        {
          categorie: new FormControl(null, [Validators.required]),
          Description: new FormControl(null, [
            Validators.required,
            Validators.pattern(this.unamePattern),
          ]),
          Priorite: new FormControl(null, [Validators.required]),
          IdBatiment: new FormControl({value: nombat, disabled: true}, [Validators.required]),
          IdAppart: new FormControl("All", [Validators.required]),
        },
        { updateOn: "blur" }
      );
     
    }
    else if(this.ref=='idAppart'){
             if(this.id=='null'){
                this.servappart.read(this.idbat).subscribe((data:any)=>{
                  this.appart=data;
                  console.log(data);
                  this.editform = new FormGroup(
                    {
                      categorie: new FormControl(null, [Validators.required]),
                      Description: new FormControl(null, [
                        Validators.required,
                        Validators.pattern(this.unamePattern),
                      ]),
                      Priorite: new FormControl(null, [Validators.required]),
                      IdBatiment: new FormControl({value: nombat, disabled: true}, [Validators.required]),
                      IdAppart: new FormControl("All", [Validators.required]),
                    },
                    { updateOn: "blur" }
                  );
                });
               
             }else{

              this.servappart.find(this.id).subscribe((data1:any)=>{
                console.log(data1);
                this.appart=data1;
                this.editform = new FormGroup(
                  {
                    categorie: new FormControl(null, [Validators.required]),
                    Description: new FormControl(null, [
                      Validators.required,
                      Validators.pattern(this.unamePattern),
                    ]),
                    Priorite: new FormControl(null, [Validators.required]),
                    IdBatiment: new FormControl({value: nombat, disabled: true}, [Validators.required]),
                    IdAppart: new FormControl({value: this.appart.NumAppart, disabled: true}, [Validators.required]),
                  },
                  { updateOn: "blur" }
                );
               
              });
        
             }
    }
    });  
  }
  public Onsubmit() {
    const obj = {
      Categorie: this.editform.get("categorie").value,
      Description: this.editform.get("Description").value,
      Priorite: this.editform.get("Priorite").value,
      IdBatiment:  this.idbat,
      IdAppart:this.editform.get("IdAppart").value,
    };
    console.log(obj);
    this.service.create(obj).subscribe((res) => {
      this.router.navigate(["/Listcharge",this.idbat]);
    });
  }
}
