import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { UrlConfigService } from '../urlConfig/url-config.service';

@Injectable({
  providedIn: 'root'
})
export class ReglageService {

  url: any

  constructor(private httpClient: HttpClient,
    private urlconfig: UrlConfigService) {
      this.url = this.urlconfig.getUrl() }

  create(PrixKwh: number, PrixM3: number,
    PrixEntretienCompteurEau: number, PrixEntretienCompteurElectricite: number, IdBatiment: number) {
    const obj = {
      PrixKwh: PrixKwh,
      PrixM3: PrixM3,
      PrixEntretienCompteurEau: PrixEntretienCompteurEau,
      PrixEntretienCompteurElectricite: PrixEntretienCompteurElectricite,
      IdBatiment: IdBatiment,
    };
      return this.httpClient.post(this.url + 'api_gesap/controllers/Reglage/create.php', obj);
  }

  read(idBati: number) {
    return this.httpClient.post(this.url + 'api_gesap/controllers/Reglage/read.php', {id: idBati});
  }

  find(idReglage) {
    return this.httpClient.post(this.url + 'api_gesap/controllers/Reglage/find.php', { id: idReglage } )
  }

  update(obj) {
    return this.httpClient.post(this.url + 'api_gesap/controllers/Reglage/update.php', obj)
  }
}
