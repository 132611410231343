<!--Toastr Starts-->
<section id="regestration">
    <div class="container-fluid">
        <div class="row full-height-vh m-0">
            <div class="col-12 d-flex align-items-center justify-content-center">
                <div class="card">
                    <div class="card-content">
                      <div class="card-body">
                        <div class="row m-0">
                          <div class="col-lg-12 col-md-12">
                            <h4 class="card-title mb-2">Modifier un appartement</h4>
                            <ng-container *ngIf="form">
                                <form [formGroup]="form" (ngSubmit)="onSubmitForm()" style="margin-top: 30px;">
                                    <div class="form-group">
                                        <label for="idAppart" class="control-label mb-1">Batiment</label>
                                        <div class="position-relative has-icon-left">
                                          <input type="text" id="idAppart" class="form-control" [value]="nomBati" disabled>
                                            <div class="form-control-position">
                                                <i class="fa fa-institution"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-6">
                                            <div class="form-group">
                                                <label for="numero" class="control-label mb-1">Numéro appart</label>
                                                <input  id="numero" type="text" class="form-control" aria-required="true" aria-invalid="false" formControlName="numAppart" required>
                                                <span *ngIf="form.controls['numAppart'].invalid && (form.controls['numAppart'].dirty
                                                    || form.controls['numAppart'].touched)">
                                                    <div *ngIf="form.controls['numAppart'].errors.required" style="color: red;">
                                                        Numéro de l'appartement obligatoire.
                                                    </div>
                                                </span>
                                                <span *ngIf="form.controls.numAppart.hasError('pattern')" style="color: red;">
                                                    Champ invalide.
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="form-group">
                                              <label for="Salon" class="control-label mb-1">Nombre de salons</label>
                                              <select id="Salon" class="form-control" formControlName="nbreSalon" required>
                                                  <option value="0">0</option>
                                                  <option value="1">1</option>
                                                  <option value="2">2</option>
                                                  <option value="3">3</option>
                                                  <option value="4">4</option>
                                                  <option value="5">5</option>
                                              </select>
                                              <span *ngIf="form.controls['nbreSalon'].invalid && (form.controls['nbreSalon'].dirty
                                                  || form.controls['nbreSalon'].touched)">
                                                  <div *ngIf="form.controls['nbreSalon'].errors.required" style="color: red;">
                                                      Nombre de salons obligatoire.
                                                  </div>
                                              </span>
                                            </div> 
                                        </div>
                                  </div>
                                  <div class="row">
                                        <div class="col-6">
                                            <div class="form-group">
                                                <label for="Chambre" class="control-label mb-1">Nombre de chambres</label>
                                                <select id="Chambre" class="form-control" formControlName="nbreChambre" required>
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                    <option value="4">4</option>
                                                    <option value="5">5</option>
                                                  </select>
                                                <span *ngIf="form.controls['nbreChambre'].invalid && (form.controls['nbreChambre'].dirty
                                                    || form.controls['nbreChambre'].touched)">
                                                    <div *ngIf="form.controls['nbreChambre'].errors.required" style="color: red;">
                                                        Nombre de chambres obligatoire.
                                                    </div>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="form-group">
                                                <label for="nbreCuisine" class="control-label mb-1">Nombre de cuisines</label>
                                                <select id="nbreCuisine" class="form-control" formControlName="nbreCuisine" required>
                                                    <option value="0">0</option>
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                    <option value="4">4</option>
                                                    <option value="5">5</option>
                                                </select>
                                                <span *ngIf="form.controls['nbreCuisine'].invalid && (form.controls['nbreCuisine'].dirty
                                                    || form.controls['nbreCuisine'].touched)">
                                                    <div *ngIf="form.controls['nbreCuisine'].errors.required" style="color: red;">
                                                        Nombre de cuisines obligatoire.
                                                    </div>
                                                </span>
                                            </div>
                                        </div>
                                  </div>
                                    <div class="row">
                                        <div class="col-sm-6 col-lg-6">
                                            <div class="form-group">
                                                <label for="nbreSalleEau" class="control-label mb-1">Nombre de salles d'eau</label>
                                                <select id="nbreSalleEau" class="form-control" formControlName="nbreSalleEau" required>
                                                    <option value="0">0</option>
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                    <option value="4">4</option>
                                                    <option value="5">5</option>
                                                  </select>
                                                <span *ngIf="form.controls['nbreSalleEau'].invalid && (form.controls['nbreSalleEau'].dirty
                                                    || form.controls['nbreSalleEau'].touched)">
                                                    <div *ngIf="form.controls['nbreSalleEau'].errors.required" style="color: red;">
                                                        Nombre de salles d'eau obligatoire.
                                                    </div>
                                                </span>
                                            </div>
                                        </div> 
                                        <div class="col-sm-6 col-lg-6">
                                            <div class="form-group">
                                                <label for="nbreSalleBain" class="control-label mb-1">Nombre de salles de bains</label>
                                                <select id="nbreSalleBain" class="form-control" formControlName="nbreSalleBain" required>
                                                    <option value="0">0</option>
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                    <option value="4">4</option>
                                                    <option value="5">5</option>
                                                </select>
                                                <span *ngIf="form.controls['nbreSalleBain'].invalid && (form.controls['nbreSalleBain'].dirty
                                                    || form.controls['nbreSalleBain'].touched)">
                                                    <div *ngIf="form.controls['nbreSalleBain'].errors.required" style="color: red;">
                                                        Nombre de salles bains obligatoire.
                                                    </div>
                                                </span>
                                            </div>
                                        </div> 
                                    </div>
                                    <div class="form-actions right row justify-content-center">
                                        <button type="submit" class="btn btn-raised btn-primary" [disabled]="form.invalid" style="margin-right: 30px;">
                                            <i class="fa fa-pencil"></i> Modifier
                                          </button>
                                        <button type="reset" class="btn btn-raised btn-warning mr-1" routerLinkActive="active" routerLink="/listAppart">
                                          <i class="ft-x"></i> Annuler
                                        </button>
                                        
                                    </div>
                                </form>
                            </ng-container>
                            
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    
    
</section>


