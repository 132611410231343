<!--Extended Table starts-->
<div class="row">
    <div class="col-9">
        <input type='text' style='padding:8px;margin:15px auto;width:100%;' placeholder='Rechercher...' (keyup)="applyFilter($event)">
        <mat-card>

            <div>
                <span class="dt-responsive table-responsive-xl">
          <table mat-table class="table" [dataSource]="dataSource" matSort class="mat-elevation-z8" style="width: 100%;">
              <!-- Name Column -->
              <ng-container matColumnDef="NomLocataire">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col" class="thead-dark1">Nom Locataire </th>
                  <td mat-cell *matCellDef="let element; let i=index">
                      {{element.NomLocataire}} </td>
              </ng-container>
              <!-- Symbol Column -->
              <ng-container matColumnDef="PrenomLocataire">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col" class="thead-dark1"> Prenom Locataire </th>
                  <td mat-cell *matCellDef="let element"> {{element.PrenomLocataire}} </td>
              </ng-container>

              <ng-container matColumnDef="Telephone">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col" class="thead-dark1"> Telephone </th>
                  <td mat-cell *matCellDef="let element"> {{element.Telephone}} </td>
              </ng-container>

              <ng-container matColumnDef="NbrePersonnes">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col" class="thead-dark1">Nombre </th>
                  <td mat-cell *matCellDef="let element"> {{element.NbrePersonnes}} </td>
              </ng-container>

              <ng-container matColumnDef="Email">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col" class="thead-dark1"></th>
                  <td mat-cell *matCellDef="let element"></td>
              </ng-container>

              <ng-container matColumnDef="CNI">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col" class="thead-dark1"> CNI </th>
                  <td mat-cell *matCellDef="let element"> {{element.CNI}} </td>
              </ng-container>

              <ng-container matColumnDef="statut">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col" class="thead-dark1"> statut </th>
                  <td mat-cell *matCellDef="let element"> {{element.Statue}} </td>
              </ng-container>
              <ng-container matColumnDef="Action">
                <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col" class="thead-dark1"> Actions </th>
                <td mat-cell *matCellDef="let element">
                  <a class="info p-0" data-original-title="" title="Vue Contrat" routerLinkActive="active" [queryParams]="{idloc:element['IdLocataire']}" routerLink="/viewcontbail">
                    <i class="ft-eye font-medium-3 mr-2"></i>
                  </a>
                  <a class="success p-0" data-original-title="" title="Modifier" routerLinkActive="active" [queryParams]="{id:element['IdLocataire']}" routerLink="/modifyuser">
                    <i class="ft-edit-2 font-medium-3 mr-2"></i>
                  </a>
                  <a class="danger p-0" data-original-title="" title="Supprimer" (click)="delete(element.IdLocataire)" >
                    <i class="ft-trash font-medium-3 mr-2"></i>
                  </a>
                  <a *ngIf="click(element.IdLocataire)" class="primary p-0" data-original-title="" title="Ajouter Un Contrat" [queryParams]="{id:element.IdLocataire}" routerLink="/addcontbail" >
                    <button style="margin-top: 13px;" class="btn btn-primary btn-raised">
                      <i class="ft-edit font-medium-3 mr-2"></i>
                      Ajout Contrat
                    </button>
                  </a>

                </td>
          </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"  routerLinkActive="router-link-active" ></tr>
          </table>
      </span>
                <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
                <div>
                    <a routerLink="/addlocataire" class="btn btn-primary btn-raised">Ajout Locataire</a>
                </div>
            </div>

        </mat-card>

    </div>

</div>