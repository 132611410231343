import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UrlConfigService } from '../urlConfig/url-config.service';
import { State } from 'app/enums/enum';
import { json } from 'ngx-custom-validators/src/app/json/validator';
import { Http } from '@angular/http';
@Injectable({
  providedIn: 'root'
})
export class LocataireService {
  [x: string]: any;

  url:any;
  constructor(private httpClient: HttpClient,
              private urlConfig:UrlConfigService) {
                  this.url=this.urlConfig.getUrl()
              }

  create(user){
    console.log(user)
    return this.httpClient.post(this.url+'api_gesap/controllers/Locataire/create.php', 
    {NomLocataire:user['name'],
    PrenomLocataire:user['surname'],
    CNI:user['cni'],
    Metier:user['metier'],
    Telephone:user['phone'],
    Email:user['email'],
    NbrePersonnes:user['nbr'],
    statut:1,
    IdAppartement:user['IdAppartement']
    })
  }

  find(id){
    return this.httpClient.post(this.url+'api_gesap/controllers/Locataire/find.php', {id:id})
  }

  findAll(idBat){
    return this.httpClient.post(this.url+'api_gesap/controllers/Locataire/reads.php', {id:idBat})
  }

  delete(id){
    return this.httpClient.post(this.url+'api_gesap/controllers/Locataire/delete.php', {id:id})
  }
  
  update(user){
    return this.httpClient.post(this.url+'api_gesap/controllers/Locataire/update.php', 
    {IdLocataire:user['IdLocataire'],
    NomLocataire:user['NomLocataire'],
    PrenomLocataire:user['PrenomLocataire'],
    CNI:user['CNI'],
    Metier:user['Metier'],
    Telephone:user['Telephone'],
    Email:user['Email'],
    NbrePersonnes:user['NbrePersonnes'],
    Statue:user['Statue'],
    IdAppartement:user['IdAppartement']}
    )
  }
}
