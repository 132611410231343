import { Component, OnInit,ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators, NgForm } from '@angular/forms';
import { BatimentService } from 'app/shared/services/batiment/batiment.service';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-modify-bat',
  templateUrl: './modify-bat.component.html',
  styleUrls: ['./modify-bat.component.css']
})
export class ModifyBatComponent implements OnInit {
  @ViewChild('f') floatingLabelForm: NgForm;
  @ViewChild('vform') validationForm: FormGroup;
  editform: FormGroup;
  mobnumPattern = "^[6]+[5|6|7|9]+[0-9]{7}$";
  unamePattern = "^[a|A-z|Z0-9_-]{5,20}$";
  options: Array<string>;
  Region = ['Adamaoua', 'Centre', 'EST', 'Extreme-Nord', 'Littoral', 'Nord', 'Nord-Ouest','Sud', 'Sud-Ouest', 'Ouest'];
  regim=null;
  types=null;
  batId;
  batiment;

  constructor(private service:BatimentService,  private router: Router, private routel: ActivatedRoute){

  }
  ngOnInit() {
    this.batId=this.routel.snapshot.params['id'];

    this.service.find(this.batId).subscribe((data)=>{
      this.batiment=data;
      console.log(this.batiment);
      this.types=this.batiment.Type;
     
      this.editform = new FormGroup({
        'nom': new FormControl(this.batiment.NomBatiment, [Validators.required,Validators.pattern(this.unamePattern)]),
        'nbretage': new FormControl(this.batiment.NombreEtage, [Validators.required]),
        'type': new FormControl(this.batiment.Type, [Validators.required]),
        'region': new FormControl(this.batiment.Region, [Validators.required]),
        'ville': new FormControl(this.batiment.Ville, [Validators.required,Validators.pattern(this.unamePattern)]),
        'adresse': new FormControl(this.batiment.Adresse, [Validators.required]),
        'tel': new FormControl(this.batiment.Telephone, [Validators.required,Validators.pattern(this.mobnumPattern)]),
        'nbrappart': new FormControl(this.batiment.NombreAppart, [Validators.required])                                                                                                                                                                                                               
    }, {updateOn: 'blur'});
    this.editform.patchValue({
        ville: this.batiment.Ville
      });
    this.region1(this.batiment.Region);
    });

  }

  type(events:any)
  {
    let types = events.target.value;
    this.types=types;
    this.editform.patchValue({
     type: types
   });
 
  }
  region(events:any)  {
  
   let region = events.target.value;
   this.regim=region;
   this.editform.patchValue({
    region: this.regim
  });

   //console.log(this.editform.get('region').value);
      switch (region) {
            case 'Littoral':
                this.options = ['DOUALA', 'EDEA', 'YABASSI', 'NKONGSAMBA'];
                console.log(this.options);
                break;
            case 'Centre' :
                this.options = ['YAOUNDE', 'NANGA-EBOKO', 'MONATELE', 'BAFIA', 'NTUI', 'MFOU',
                                 'NGOUMOU', 'ESEKA', 'AKONOLINGA', 'MBALMAYO'];
                console.log(this.options);
                break;
            case 'Ouest' :
                this.options = ['MBOUDA', 'BAFANG', 'BAHAM', 'BANDJOUN', 'DSCHANG', 'BAFOUSSAM',
                                'BANGANGTE', 'FOUMBAN'];
                                console.log(this.options);
                break;
            case 'Adamaoua' :
                this.options = ['TIBABI', 'TIGNERE', 'BANYO', 'MEIGANGA', 'NGAOUNDERE'];
                console.log(this.options);
                break;
            case 'EST' :
                this.options = ['YOKADOUMA', 'ABONG-MBANG', 'BATOURI', 'BERTOUA'];
                console.log(this.options);
                break;
            case 'Extreme-Nord' :
                this.options = ['MAROUA', 'KOUSSERI', 'YAGOUA', 'KAELE', 'MORA', 'MOKOLO'];
                console.log(this.options);
                break;
            case 'Nord' :
                this.options = ['GAROUA', 'POLI', 'GUIDER', 'TCHOLLIRE'];
                console.log(this.options);
                break;
            case 'Nord-Ouest' :
                this.options = ['FUNDONG', 'KUMBO', 'NKAMBE', 'WUM', 'BAMENDA', 'MBENGWI', 'NDOP'];
                console.log(this.options);
                break;
            case 'Sud' :
                this.options = ['SANGMELIMA', 'EBOLOWA', 'KRIBI', 'AMBAM'];
                console.log(this.options);
                break;
            case 'Sud-Ouest' :
                this.options = ['LIMBE', 'BANGEM', 'MENJI', 'MAMFE', 'KUMBA', 'MUNDEMBA'];
                console.log(this.options);
                break;
            default:
                this.options = ['NULL'];
                break;
      }
  }
  region1(events:any)  {
  
    let region = events
    this.regim=region;
    this.editform.patchValue({
     region: this.regim
   });
 
    //console.log(this.editform.get('region').value);
       switch (region) {
             case 'Littoral':
                 this.options = ['DOUALA', 'EDEA', 'YABASSI', 'NKONGSAMBA'];
                 console.log(this.options);
                 break;
             case 'Centre' :
                 this.options = ['YAOUNDE', 'NANGA-EBOKO', 'MONATELE', 'BAFIA', 'NTUI', 'MFOU',
                                  'NGOUMOU', 'ESEKA', 'AKONOLINGA', 'MBALMAYO'];
                 console.log(this.options);
                 break;
             case 'Ouest' :
                 this.options = ['MBOUDA', 'BAFANG', 'BAHAM', 'BANDJOUN', 'DSCHANG', 'BAFOUSSAM',
                                 'BANGANGTE', 'FOUMBAN'];
                                 console.log(this.options);
                 break;
             case 'Adamaoua' :
                 this.options = ['TIBABI', 'TIGNERE', 'BANYO', 'MEIGANGA', 'NGAOUNDERE'];
                 console.log(this.options);
                 break;
             case 'EST' :
                 this.options = ['YOKADOUMA', 'ABONG-MBANG', 'BATOURI', 'BERTOUA'];
                 console.log(this.options);
                 break;
             case 'Extreme-Nord' :
                 this.options = ['MAROUA', 'KOUSSERI', 'YAGOUA', 'KAELE', 'MORA', 'MOKOLO'];
                 console.log(this.options);
                 break;
             case 'Nord' :
                 this.options = ['GAROUA', 'POLI', 'GUIDER', 'TCHOLLIRE'];
                 console.log(this.options);
                 break;
             case 'Nord-Ouest' :
                 this.options = ['FUNDONG', 'KUMBO', 'NKAMBE', 'WUM', 'BAMENDA', 'MBENGWI', 'NDOP'];
                 console.log(this.options);
                 break;
             case 'Sud' :
                 this.options = ['SANGMELIMA', 'EBOLOWA', 'KRIBI', 'AMBAM'];
                 console.log(this.options);
                 break;
             case 'Sud-Ouest' :
                 this.options = ['LIMBE', 'BANGEM', 'MENJI', 'MAMFE', 'KUMBA', 'MUNDEMBA'];
                 console.log(this.options);
                 break;
             default:
                 this.options = ['NULL'];
                 break;
       }
   }
  option(events:any)
  {
    let option = events.target.value;
    this.editform.patchValue({
     ville: option
   });
 
  }
  
  public Onsubmit() {
    const obj = {
      IdBatiment: this.batId,
      NomBatiment: this.editform.get('nom').value,
      NombreEtage: this.editform.get('nbretage').value,
      Type: this.editform.get('type').value,
      Region: this.editform.get('region').value,
      Ville: this.editform.get('ville').value,
      Adresse: this.editform.get('adresse').value,
      Telephone: this.editform.get('tel').value,
      NombreAppart: this.editform.get('nbrappart').value
    };
    console.log(obj);
    this.service.update(obj).subscribe((res) => {
      this.router.navigate(['/ListBatiment']);
    });
  }
}
