<div class="modal-header">
    <h4 class="modal-title">Veillez renseignez les informations suivantes</h4>
    <button type="button" class="close" mat-dialog-close aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
    <div class="px-3" *ngIf="form2">
        <form [formGroup]="form2" novalidate>
            <div *ngIf="prestataire">
                <div class="form-body">
                    <div>
                        <h4 class="form-section"><i class="ft-user"></i>Prestataire</h4>
                        <div class="form-group">
                            <label for="Priorite">Selectionner</label>
                            <select class="form-control" id="Genre" (change)="getprestat($event)" formControlName="prestataire" required>
                <option>---</option>
                <option
                  *ngFor="let prest of prestataire"
                  value="{{ prest.Idprestataire }}"
                >
                  {{ prest.Nom}} {{ prest.Prenom}}</option
                >
              </select>
                            <small class="form-text text-muted danger" *ngIf="
                  !form2.get('prestataire').valid &&
                  (form2.get('prestataire').dirty ||
                    form2.get('prestataire').touched)
                ">champ obligatoire</small
              >
              <br />
              <span class="float-right">
                <a
                  class="success p-0"
                  data-original-title=""
                  title=""
                  (click)="add()"
                >
                  <i class="ft-plus font-medium-3 mr-2"></i>ajouter
                </a>
              </span>
            </div>
          </div>
        </div>
        <h4 class="form-section">
          <i class="ft-file-text"></i> Coût total du travail effectué
        </h4>

        <div>
          <fieldset>
            <div class="form-group row">
              <label class="col-md-3 label-control" for="projectinput3"
                >coût du travail:
              </label>
              <div class="col-md-9">
                <input
                  type="number"
                  id="projectinput3"
                  class="form-control"
                  formControlName="cout"
                />
                <small
                  class="form-text text-muted danger"
                  *ngIf="
                    !form2.get('cout').valid &&
                    (form2.get('cout').dirty || form2.get('cout').touched)
                  "
                  >champ obligatoire</small
                >
              </div>
            </div>
          </fieldset>
        </div>

        <div class="form-actions">
          <button type="button" class="btn btn-raised btn-warning mr-1" mat-dialog-close>
            <i class="ft-x"></i>Fermer
          </button>
          <button
            type="button"
            class="btn btn-raised btn-primary"
            [disabled]="
              !form2.get('prestataire').valid || !form2.get('cout').valid
            "
            (click)="Onsubmit('resolu')"
          >
            <i class="fa fa-check-square-o"></i>Enregistrer
          </button>
        </div>
      </div>
      <div *ngIf="!prestataire">
        <div class="form-body">
          <div>
            <h4 class="form-section">
              <i class="ft-user"></i>Information du Prestataire
            </h4>
            <div class="form-group row">
              <label class="col-md-3 label-control" for="nom">Nom: </label>
              <div class="col-md-9">
                <input
                  type="text"
                  id="nom"
                  class="form-control"
                  formControlName="nom"
                />
                <small
                  class="form-text text-muted danger"
                  *ngIf="
                    !form2.get('nom').valid &&
                    (form2.get('nom').dirty ||
                      form2.get('nom').touched ||
                      form2.controls.nom.hasError('pattern'))
                  "
                  >champ obligatoire</small
                >
              </div>
            </div>
            <div class="form-group row">
              <label class="col-md-3 label-control" for="prenom"
                >Prenom:
              </label>
              <div class="col-md-9">
                <input
                  type="text"
                  id="prenom"
                  class="form-control"
                  formControlName="prenom"
                />
                <small
                  class="form-text text-muted danger"
                  *ngIf="
                    !form2.get('prenom').valid &&
                    (form2.get('prenom').dirty ||
                      form2.get('prenom').touched ||
                      form2.controls.prenom.hasError('pattern'))
                  "
                  >champ obligatoire</small
                >
              </div>
            </div>
            <div class="form-group row">
              <label class="col-md-3 label-control" for="tel"
                >Numnéro de telephone:
              </label>
              <div class="col-md-9">
                <input
                  type="number"
                  id="tel"
                  class="form-control"
                  formControlName="tel"
                />
                <small
                  class="form-text text-muted danger"
                  *ngIf="
                    !form2.get('tel').valid &&
                    (form2.get('tel').dirty ||
                      form2.get('tel').touched ||
                      form2.controls.tel.hasError('pattern'))
                  "
                  >champ obligatoire</small
                >
              </div>
            </div>
          </div>
        </div>
        <h4 class="form-section">
          <i class="ft-file-text"></i> Coût total du travail effectué
        </h4>

        <div>
          <fieldset>
            <div class="form-group row">
              <label class="col-md-3 label-control" for="cout"
                >coût du travail:
              </label>
              <div class="col-md-9">
                <input
                  type="number"
                  id="cout"
                  class="form-control"
                  formControlName="cout"
                />
                <small
                  class="form-text text-muted danger"
                  *ngIf="
                    !form2.get('cout').valid &&
                    (form2.get('cout').dirty ||
                      form2.get('cout').touched ||
                      form2.controls.nom.hasError('pattern'))
                  "
                  >champ obligatoire</small
                >
              </div>
            </div>
          </fieldset>
        </div>

        <div class="form-actions">
          <button type="button" class="btn btn-raised btn-warning mr-1"  mat-dialog-close>
            <i class="ft-x"></i>Fermer
          </button>
          <button
            type="button"
            class="btn btn-raised btn-primary"
            [disabled]="
              !form2.get('cout').valid ||
              !form2.get('nom').valid ||
              !form2.get('prenom').valid ||
              !form2.get('tel').valid
            "
            (click)="Onsubmit('resolu')"
          >
            <i class="fa fa-check-square-o"></i>Enregistrer
          </button>
        </div>
      </div>
    </form>
  </div>
</div>