import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-modify-prest',
  templateUrl: './modify-prest.component.html',
  styleUrls: ['./modify-prest.component.css']
})
export class ModifyPrestComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
