import { Component, OnInit } from '@angular/core';
import { FactureElectriciteService } from './../../shared/services/factureElectricite/facture-electricite.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AppartementService } from './../../shared/services/appartement/appartement.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-view-factelect',
  templateUrl: './view-factelect.component.html',
  styleUrls: ['./view-factelect.component.css']
})
export class ViewFactelectComponent implements OnInit {

  idFact: number;
  facture: any;
  type: string;
  idAppart:  number;
  appartement: any;
  numAppart: string;
  constructor(private router: Router, private route: ActivatedRoute, private service: FactureElectriciteService,
              private serviceAppartement: AppartementService, public toastr: ToastrService) { }

  ngOnInit(): void {
    this.idFact = this.route.snapshot.params['idFact'];
    this.service.find(this.idFact).subscribe(data => {
      this.facture = data;
      this.type = this.facture.TypeCompteur;
      this.idAppart = this.facture.IdAppart;
      console.log(data);
      this.serviceAppartement.find(this.idAppart).subscribe(data1 => {
        this.appartement = data1;
        this.numAppart = this.appartement.NumAppart;

      });
    });
  }

  delete(id) {
    if (confirm("Voulez-vous vraiment supprimer la facture "+this.facture.NumFactElect+ " d\'électricité?????")) {
      this.service.delete(id).subscribe(data => {
        this.router.navigate(['/listFactElect', this.facture.IdAppart]);
                this.toastr.error('Facture d\'électricité supprimée.', 'Suppression!');
      });
    }
  }

}
