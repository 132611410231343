<div class="modal-header">
    <span class="h2">Prestataire</span>
    <button type="button" class="close" mat-dialog-close aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
    <div class="row">
        <img src="assets/img/avatar.jpg" alt="element 01" width="190" class="col img-fluid">
        <div class="px-3 col text-center">
            <br>
            <p><span class="text-title font-weight-bold h5"><i class="fa fa-user" aria-hidden="true"></i> Nom</span><span class="text-reset h5">  {{Nom}}</span></p><br>
            <p><span class="text-title font-weight-bold h5"><i class="fa fa-user-o" aria-hidden="true"></i> Prenom</span><span class="text-reset h5">  {{Prenom}}</span></p><br>
            <p><span class="text-title font-weight-bold h5"><i class="ft-tablet"></i> Contact</span><span class="text-reset">  {{contact}}</span></p>
        </div>
    </div>
</div>
<div class="modal-footer">
    <span class="text-muted">Enregistre le {{dates | date:'EEEE d MMMM y'}}</span>
</div>