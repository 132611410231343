import { Component, OnInit,ViewChild } from '@angular/core';
import { PrestataireService } from 'app/shared/services/prestataire/prestataire.service';
import { ActivatedRoute } from '@angular/router';
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import { Prestataire } from '../prestataire';
import { MatTableDataSource } from '@angular/material/table'
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ViewPrestComponent } from '../view-prest/view-prest.component';

@Component({
  selector: 'app-list-prest',
  templateUrl: './list-prest.component.html',
  styleUrls: ['./list-prest.component.css']
})
export class ListPrestComponent implements OnInit {
  rows = [];
  temp = [];
  closeResult: string;
  ELEMENT_DATA: Prestataire[];
  displayedColumns: string[] = [
    "Nom",
    "Prenom",
    "Contact",
    "DateAjout",
    "vue"
  ];
  batId;
  dataSource = new MatTableDataSource<Prestataire>(this.ELEMENT_DATA);
  @ViewChild(MatSort, { static: true }) sorte: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  constructor(private service:PrestataireService,private router:ActivatedRoute,private dialog: MatDialog) { }

  ngOnInit(): void {
    this.batId = this.router.snapshot.params['id'];
    this.refresh();
  }
  refresh()
  {
    this.service.readidprest(this.batId).subscribe((data:Prestataire[])=>{
      this.dataSource.data = data; 

      this.dataSource.paginator = this.paginator;

      this.dataSource.sort = this.sorte;
       console.log(this.dataSource.data);
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  Ondelete(id: number) {
    if (confirm("voulez-vous vraiment supprimer ce batiment??")) {
      this.service.delete(id).subscribe((data) => {
        console.log(data);
        this.refresh();
      });
    }
  }

  openDialog(idprest) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data ={id:idprest} ;
    dialogConfig.height = '550px';
    dialogConfig.width = '300px';
    const dialogRef = this.dialog.open(ViewPrestComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(() => {
      this.refresh();
    });
  }
}
