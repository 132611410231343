import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AppartementService } from './../../shared/services/appartement/appartement.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BatimentService } from './../../shared/services/batiment/batiment.service';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-modify-appt',
  templateUrl: './modify-appt.component.html',
  styleUrls: ['./modify-appt.component.css']
})
export class ModifyApptComponent implements OnInit {

  idAppart: number;
  form: FormGroup;
  appartParttern = '^[a|A-z|Z0-9]{5,20}$';
  appart: any;
  idBati: number;
  nomBati: string;

  constructor(private formBuilder: FormBuilder, private service: AppartementService,
              private router: Router, private route: ActivatedRoute, private serviceBatiment: BatimentService,
              public toastr: ToastrService) { }

  ngOnInit(): void {
    this.idAppart = this.route.snapshot.params['idAppart'];
    this.service.find(this.idAppart).subscribe(data => {
      this.appart = data;
      this.idBati = this.appart.IdBatiment;

      this.serviceBatiment.find(this.idBati).subscribe(data1 => {
        this.nomBati = data1['NomBatiment'];
      });
      this.initForm();
    });
    
  }

  initForm() {
    this.form = this.formBuilder.group({
      numAppart: [this.appart.NumAppart, [Validators.required, Validators.pattern(this.appartParttern)]],
      nbreSalon: [this.appart.NombreSalon, [Validators.required]],
      nbreCuisine: [this.appart.NombreCuisine, [Validators.required]],
      nbreChambre: [this.appart.NombreChambre, [Validators.required]],
      nbreSalleEau: [this.appart.NombreSalleEau, [Validators.required]],
      nbreSalleBain: [this.appart.NombreSalleBain, [Validators.required]]
    });
  }

  onSubmitForm() {
      const numAppart = this.form.get('numAppart').value;
      const nbreSalon = this.form.get('nbreSalon').value;
      const nbreChambre = this.form.get('nbreChambre').value;
      const nbreCuisine = this.form.get('nbreCuisine').value;
      const nbreSalleEau = this.form.get('nbreSalleEau').value;
      const nbreSalleBain = this.form.get('nbreSalleBain').value;
      

      this.service.numeroAppartement(this.idBati, numAppart).subscribe(data => {
        if(data == 1 && numAppart !== this.appart.NumAppart ) {
            if( confirm(' Le numéro '+numAppart+ ' existe déjà. \n\n Voulez-vous le modifier?????')) {

            } else {
              alert("Les modifications effectuent sur l\'appartement "+this.appart.NumAppart+" n\'ont pas été enregistré");
              this.router.navigate(['/listAppart']);
            }
        } else if(numAppart == this.appart.NumAppart || data == 0) {
            this.service.update(this.appart.IdAppart, numAppart, nbreSalon, nbreCuisine, nbreChambre, nbreSalleBain, nbreSalleEau).subscribe(data => {
              this.router.navigate(['/listAppart']);
              this.toastr.success('Appartement modifier!', 'Succèss!');
          });
        }
    });
  }
}
