import { Component, ViewChild, OnInit } from '@angular/core';
import { NgForm, Validators,FormBuilder, FormGroup } from '@angular/forms';
import { InscriptionService } from 'app/shared/services/inscription/inscription.service';
import { State } from 'app/enums/enum';
import { profil } from 'app/enums/enumProfil';
import { Router } from '@angular/router';
import { StorageMap } from '@ngx-pwa/local-storage';
import { NGXToastrService } from './register-toast';

@Component({
    selector: 'app-register-page',
    templateUrl: './register-page.component.html',
    styleUrls: ['./register-page.component.scss'],
    providers: [NGXToastrService]
})

export class RegisterPageComponent implements OnInit {
    @ViewChild('f') registerForm: NgForm;

    mobnumPattern = '^[6]+[5|6|7|9]+[0-9]{7}$';
    pass = '^[a|A-z|Z0-9]{5,20}$';
    //  On submit click, reset field value
    onSubmit() {
        this.registerForm.reset();
    }
    constructor(private serviceInsciption: InscriptionService,
        private router:Router,
        private storage: StorageMap,
        private service:NGXToastrService){}

    typeError(){
        this.service.typeError()
    }
    typeUsernameOrPass(){
        this.service.typeUsernameOrPass()
    }
    typeSuccess(){
        this.service.typeSuccess()
    }
    typeEmail(){
        this.service.typeEmail()
    }
    typePhone(){
        this.service.typePhone()
    }
 typeUnique()
 {
   this.service.typeUnique()  
 }
    inscription(){
        if(this.username.trim().length==0 ||
           this.password.trim().length==0 ||
           this.name.trim().length==0 ||
           this.surname.trim().length==0 ||
           this.phone.trim().length==0 ||
           this.mail.trim().length==0
           ){
            this.typeError()
        }else{
            if(this.username.trim().length<3 || this.password.trim().length<5){
                this.typeUsernameOrPass()
            }else{
                if(this.Check()){
                    if(this.checkMail()){
                        let user={
                            name:this.name,
                            surname:this.surname,
                            username:this.username,
                            password:this.password,
                            mail:this.mail,
                            phone:this.phone,
                            profil:'BAILLEUR',
                            statut:'NONACTIF'
                        }
                        console.log(user)
                        this.serviceInsciption.CreateUser(user).subscribe((response)=>{
                            console.log(response)
                            if(response['statue']==true && response['message']=='save done'){
                                this.storage.set('ids', response['data'])
                                //let info={idUser:response['data'], idBat:0}
                                localStorage.setItem('bt', JSON.stringify({idUser:response['data'], idBat:0}))
                                console.log(localStorage.getItem('bt'))
                                this.typeSuccess()
                                this.resetInput()
                                console.log(response['data'])
                                this.router.navigate(['/AddBatiment'])

                            }
                        }, ()=>{
                            this.typeUnique()
                        })
                    }else{this.typeEmail()}
                }else{
                    this.typePhone()
                }
            }
        }
    }
    
    resetInput(){
        this.username=""
        this.password=""
        this.name=""
        this.surname=""
        this.mail=""
        this.phone=""
    }

    username=""
    password=""
    name=""
    surname=""
    mail=""
    phone=""
    nom=""

    ngOnInit() : void {
    }

    public Check(){
        let result=true
        for(let i=0; i<this.phone.trim().length; i++)
        {
            if(
                Number.parseInt(this.phone.substring(i,i+1))==0 ||
                Number.parseInt(this.phone.substring(i,i+1))==1 ||
                Number.parseInt(this.phone.substring(i,i+1))==2 ||
                Number.parseInt(this.phone.substring(i,i+1))==3 ||
                Number.parseInt(this.phone.substring(i,i+1))==4 ||
                Number.parseInt(this.phone.substring(i,i+1))==5 ||
                Number.parseInt(this.phone.substring(i,i+1))==6 ||
                Number.parseInt(this.phone.substring(i,i+1))==7 ||
                Number.parseInt(this.phone.substring(i,i+1))==8 ||
                Number.parseInt(this.phone.substring(i,i+1))==9
              ){}else{result=false}
        }
        return result;
    }

    checkMail() : boolean{
        if(this.mail.trim().indexOf('@') > -1){
           return true
        }else{return false}
    }
    
}
