<!--Toastr Starts-->
<div class="row" *ngIf="facture">
  <div class="card col-8" >
    <div class="card-header" style="margin-bottom: 15px;">
      <h4 class="card-title mb-0" >Vue d'une facture d'eau</h4>
    </div>
    <div class="px-3 card-content">
      <div class="card-img-top img-fluid bg-cover height-300" style="background: url('assets/img/gallery/binaire.jpg') 50%;"></div>
        <div class="card-body text-center">
          <h3 class="row primary justify-content-center"><u>Appartement </u> : {{numAppart}}</h3>
          <h3 class="row danger justify-content-center"><u>Facture de </u> : {{facture.Mois}} {{facture.Annee}}</h3>
            <span class="row" style="margin-bottom: 5px;">
                <span class="col-6"> 
                    <i class="ft-bookmark h5"></i><span class=""><span class="font-weight-bold h6">Numéro de la fature : </span><span class="text-reset">{{facture.NumFactEau}}</span></span>
                </span>
                <span class="col-6">
                  <i class="ft-calendar h5"></i><span class=""><span class="font-weight-bold h6">Limite paie : </span ><span class="text-reset">{{facture.DateLimitePayement | date:'EEEE d MMMM y'}}</span></span>
                </span>
               
            </span>
            <span class="row" style="margin-bottom: 5px;">
              <span class="col-6">
                <i class="ft-hash h5"></i><span class=""><span class="font-weight-bold h6">Montant à payer : </span><span class="text-reset">{{facture.Prix}}</span></span>
              </span>
              <span class="col-6">
                <i class="ft-calendar h5"></i><span class=""><span class="font-weight-bold h6">Date paie : </span ><span class="text-reset">{{facture.DatePaiement | date:'EEEE d MMMM y'}}</span></span>
              </span>
            </span>
            <span class="row" style="margin-bottom: 5px;" *ngIf="type == 'Forfait'">
                <span class="col-6">
                    <i class="ft-clock h5"></i><span class=""><span class="font-weight-bold h6">Index initiale : </span><span class="text-reset">{{facture.IndexInitial}}</span></span>
                </span>
                <span class="col-6">
                  <i class="ft-clock h5"></i><span class=""><span class="font-weight-bold h6">Index final : </span><span class="text-reset">{{facture.IndexFinal}}</span></span>
                </span>
  
            </span>
            <span class="row" style="margin-bottom: 5px;">
              <span class="col-6">
                <i class="ft-codepen h5"></i><span class=""><span class="font-weight-bold h6">Type : </span><span class="text-reset">{{facture.TypeCompteur}}</span></span>
              </span>
              <span class="col-6">
                <i class="ft-toggle-left"></i><span class=""><span class="font-weight-bold h6">Statut : </span><span class="text-reset">{{facture.Status}}</span></span>
              </span>
            </span>
            <span class="row" style="margin-bottom: 5px;" *ngIf="type == 'Forfait'">
              <span class="col-6">
                <i class="ft-hash h5"></i><span class=""><span class="font-weight-bold h6">Prix du KM3 : </span><span class="text-reset">{{facture.PrixM3}}</span></span>
            </span>
            <span class="col-6">
              <i class="ft-hash h5"></i><span class=""><span class="font-weight-bold h6">Prix entretien compteur : </span><span class="text-reset">{{facture.PrixEntretienCompteur}}</span></span>
            </span>

            </span>
        </div>
      
    </div>
  
  </div>
  <div class=" card col-3 div2" style="margin-left: 10px;">
    <div>
      <nav>
          <div >
            <div class="card-title entete">
              <h2>Actions</h2>
            </div>
              
              <ul class="card-body" style="list-style-type: none;">
                  <li>
                    <button class="btn  btn-lg btn-block btn-success btn-raised btn-sm font-weight-light"  routerLink="/ajoutFactEau/{{facture.IdFactureEau}}">
                      <i class="ft-plus"></i> Ajouter
                    </button>
                  </li>
                  <li>
                      <button class="btn  btn-lg btn-block btn-raised btn-sm font-weight-light" style="background-color: #007acc !important; color:whitesmoke !important;" routerLink="/modifFactEau/{{facture.IdFactureEau}}">
                        <i class="ft-edit-2"></i> Modifier
                      </button>
                  </li>
                  <li>
                    <button class="btn  btn-danger btn-lg btn-block btn-raised btn-sm font-weight-light"  (click)="delete(facture.IdFactureEau)">
                      <i class="ft-trash"></i> Supprimer 
                    </button>
                  </li> 
                  <li style="margin-top: 40px;">
                    <div class="type-1 row justify-content-center">
                        <a routerLink="/listFactEau/{{idAppart}}" class="btn btn-1">
                          <span class="txt">Retour</span>
                          <span class="round"><i class="fa fa-chevron-left" style="color: white;"></i></span>
                        </a>
                      </div>
                </li>
              </ul>
          </div>
      </nav>
    </div>
  </div>
</div>

