import { Component, OnInit,ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators, NgForm } from '@angular/forms';
import { BatimentService } from 'app/shared/services/batiment/batiment.service';
import { ActivatedRoute, Router } from '@angular/router';
import { VerifyService } from 'app/shared/services/verify/verify.service';
import { NGXToastrService } from '../add-bat/add-batiment-toast';
import { StorageMap } from '@ngx-pwa/local-storage';
@Component({
  selector: 'app-add-bat',
  templateUrl: './add-bat.component.html',
  styleUrls: ['./add-bat.component.css'],
  providers: [NGXToastrService]
})
export class AddBatComponent implements OnInit {
  @ViewChild('f') floatingLabelForm: NgForm;
  @ViewChild('vform') validationForm: FormGroup;
  editform: FormGroup;
  mobnumPattern = "^[6]+[5|6|7|9]+[0-9]{7}$";
  Number="^([0-9]|[1-9][0-9]|150)$";
  unamePattern = "^[a|A-z|Z0-9_ -]{5,20}$";
  options: Array<string>;
  Region = ['Adamaoua', 'Centre', 'EST', 'Extreme-Nord', 'Littoral', 'Nord', 'Nord-Ouest','Sud', 'Sud-Ouest', 'Ouest'];
  regim=null;
  types=null;
  key:any;
  idBail;
  verify:boolean;
  constructor(private service:BatimentService, private storage: StorageMap, private toast: NGXToastrService,  private router: Router, private route: ActivatedRoute,private verifserv:VerifyService){
    this.key=JSON.parse(localStorage.getItem('bt'))
    console.log(localStorage.getItem('bt'))
  }
  ngOnInit() {
      this.editform = new FormGroup({
          'nom': new FormControl(null, [Validators.required,Validators.pattern(this.unamePattern)]),
          'nbretage': new FormControl('0', [Validators.required,Validators.pattern(this.Number)]),
          'type': new FormControl(null, [Validators.required]),
          'region': new FormControl(null, [Validators.required]),
          'ville': new FormControl(null, [Validators.required,Validators.pattern(this.unamePattern)]),
          'adresse': new FormControl('',[]),
          'tel': new FormControl(null, [Validators.required,Validators.pattern(this.mobnumPattern)]),
          'nbrappart': new FormControl(null, [Validators.required,Validators.pattern(this.Number)])                                                                                                                                                                                                               
      }, {updateOn: 'blur'});  
    }
  type(events:any)
  {
    let types = events.target.value;
    this.types=types;
    this.editform.patchValue({
     type: types
   });
 
  }

      // Success Type
      typeSuccess() {
        this.toast.typeSuccess()
      }

  region(events:any)  {
  
   let region = events.target.value;
   this.regim=region;
   this.editform.patchValue({
    region: this.regim
  });

   //console.log(this.editform.get('region').value);
      switch (region) {
            case 'Littoral':
                this.options = ['DOUALA', 'EDEA', 'YABASSI', 'NKONGSAMBA'];
                console.log(this.options);
                break;
            case 'Centre' :
                this.options = ['YAOUNDE', 'NANGA-EBOKO', 'MONATELE', 'BAFIA', 'NTUI', 'MFOU',
                                 'NGOUMOU', 'ESEKA', 'AKONOLINGA', 'MBALMAYO'];
                console.log(this.options);
                break;
            case 'Ouest' :
                this.options = ['MBOUDA', 'BAFANG', 'BAHAM', 'BANDJOUN', 'DSCHANG', 'BAFOUSSAM',
                                'BANGANGTE', 'FOUMBAN'];
                                console.log(this.options);
                break;
            case 'Adamaoua' :
                this.options = ['TIBABI', 'TIGNERE', 'BANYO', 'MEIGANGA', 'NGAOUNDERE'];
                console.log(this.options);
                break;
            case 'EST' :
                this.options = ['YOKADOUMA', 'ABONG-MBANG', 'BATOURI', 'BERTOUA'];
                console.log(this.options);
                break;
            case 'Extreme-Nord' :
                this.options = ['MAROUA', 'KOUSSERI', 'YAGOUA', 'KAELE', 'MORA', 'MOKOLO'];
                console.log(this.options);
                break;
            case 'Nord' :
                this.options = ['GAROUA', 'POLI', 'GUIDER', 'TCHOLLIRE'];
                console.log(this.options);
                break;
            case 'Nord-Ouest' :
                this.options = ['FUNDONG', 'KUMBO', 'NKAMBE', 'WUM', 'BAMENDA', 'MBENGWI', 'NDOP'];
                console.log(this.options);
                break;
            case 'Sud' :
                this.options = ['SANGMELIMA', 'EBOLOWA', 'KRIBI', 'AMBAM'];
                console.log(this.options);
                break;
            case 'Sud-Ouest' :
                this.options = ['LIMBE', 'BANGEM', 'MENJI', 'MAMFE', 'KUMBA', 'MUNDEMBA'];
                console.log(this.options);
                break;
            default:
                this.options = ['NULL'];
                break;
      }
  }
  option(events:any)
  {
    let option = events.target.value;
    this.editform.patchValue({
     ville: option
   });
 
  }
  
  public Onsubmit() {
    const obj = {
      NomBatiment: this.editform.get('nom').value,
      NombreEtage: this.editform.get('nbretage').value,
      Type: this.editform.get('type').value,
      Region: this.editform.get('region').value,
      Ville: this.editform.get('ville').value,
      Adresse: this.editform.get('adresse').value,
      Telephone: this.editform.get('tel').value,
      NombreAppart: this.editform.get('nbrappart').value,
      IdBailleur:this.key.idUser
    };
    console.log(obj)
    this.service.create(obj).subscribe((res) => {
      localStorage.setItem('bt', JSON.stringify({idUser:this.key.idUser,idBat:res['data']}));
      console.log(res, localStorage.getItem('bt'));
      this.typeSuccess();
      this.router.navigate(['/ajoutReglage']);
    });
  }

  retour(){
    if(JSON.parse(localStorage.getItem('bt'))['profil']=='ADMINISTRATEUR'){
      this.router.navigateByUrl('/dashboard/dashboard1')
    }else{
      this.router.navigateByUrl('/dashboard/dashboard2')
    }
  }
  
}
