import { Component, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators, NgForm } from "@angular/forms";
import { BatimentService } from "app/shared/services/batiment/batiment.service";
import { ActivatedRoute, Router } from "@angular/router";
import { AppartementService } from "app/shared/services/appartement/appartement.service";
import { ChargeService } from "app/shared/services/charge/charge.service";
import { Batiment } from "app/batiments/batiment";
@Component({
  selector: 'app-modify-charg',
  templateUrl: './modify-charg.component.html',
  styleUrls: ['./modify-charg.component.css']
})
export class ModifyChargComponent implements OnInit {

  @ViewChild("f") floatingLabelForm: NgForm;
  @ViewChild("vform") validationForm: FormGroup;
  editform: FormGroup;
  mobnumPattern = "^[6]+[5|6|7|9]+[0-9]{7}$";
  unamePattern = "^[a|A-z|Z0-9_-| ]{5,20}$";
  options: Array<string>;
  Genre = [
    "électricité",
    "eau",
    "plomberie",
    "maçonnerie",
    "nettoyage",
    "autre",
  ];
  bat: any;
  appart: any;
  id;
  ref:any;
  idbat;

  Taf:any;
  statut:any
  batiment:any;
  Nombat;
  numapp;
  priorite;
  categorie;
  NomPrestataire;
  Description;
  DateResol;
  DateOcc;
  Cout;
  appartement:any;

  constructor(
    private servbat: BatimentService,
    private servapp: AppartementService,
    private service: ChargeService,
    private router: Router,
    private route: ActivatedRoute,

  ) { }

  ngOnInit() {
    this.refresh();
  }

  refresh(){
    let nombat;
    this.idbat = JSON.parse(localStorage.getItem('bt'))['idBat'];
    this.id= this.route.snapshot.params['id'];
    this.service.find(this.id).subscribe((data:Object)=>{

      this.Taf=data;
      this.idbat=this.Taf.IdBatiment;
      console.log( this.Taf);
      this.statut=this.Taf.Statut;
      this.priorite=this.Taf.Priorite;
      this.Description=this.Taf.Description;
      this.categorie=this.Taf.Categorie;
      this.NomPrestataire=this.Taf.NomPrestataire;
      this.Cout=this.Taf.Cout;

      this.servbat.find(this.idbat).subscribe((data1:any)=>{
        this.batiment=data1;

        console.log(data1);

        this.Nombat=this.batiment.NomBatiment; 

          this.servapp.find(this.Taf.IdAppart).subscribe((data2:any)=>{
            this.appartement=data2;
            console.log(data2);
            this.numapp=this.appartement.NumAppart;
            console.log(this.numapp);
            if(this.statut="resolu")
            {
              this.editform = new FormGroup(
                {
                  categorie: new FormControl(this.categorie, [Validators.required]),
                  Description: new FormControl(this.Description, [
                    Validators.required,
                    Validators.pattern(this.unamePattern),
                  ]),
                  Priorite: new FormControl(this.priorite, [Validators.required]),
                  IdBatiment: new FormControl({value: this.Nombat, disabled: true}, [Validators.required]),
                  IdAppart: new FormControl(this.appartement.NumAppart, [Validators.required]),
                  Cout: new FormControl(this.Cout, [Validators.required]),
                  NomPrestataire: new FormControl(this.NomPrestataire, [Validators.required]),
                },
                { updateOn: "blur" }
              );
            }
            else{
              this.numapp=this.Taf.IdAppart;
              console.log(this.numapp);
              this.editform = new FormGroup(
                {
                  categorie: new FormControl(this.categorie, [Validators.required]),
                  Description: new FormControl(this.Description, [
                    Validators.required,
                    Validators.pattern(this.unamePattern),
                  ]),
                  Priorite: new FormControl(this.priorite, [Validators.required]),
                  IdBatiment: new FormControl({value: this.Nombat, disabled: true}, [Validators.required]),
                  IdAppart: new FormControl(this.Taf.IdAppart, [Validators.required]),
                },
                { updateOn: "blur" }
              );
            }
          });

      });
      
      this.servapp.read(this.idbat).subscribe((data:any)=>{
        this.appart=data;
        console.log(data);
      });
   this.Cout=this.Taf.Cout;
    });
  }
  public Onsubmit() {
    const obj = {
      IdTaf:this.id,
      Categorie: this.editform.get("categorie").value,
      Description: this.editform.get("Description").value,
      Priorite: this.editform.get("Priorite").value,
      IdBatiment:  this.idbat,
      Cout:this.editform.get("Cout").value,
      IdAppart:this.editform.get("IdAppart").value,
    };
    console.log(obj);
    this.service.update(obj).subscribe((res) => {
      console.log(res)
      this.router.navigate(["/Viewcharge",this.id]);
    });
  }
}
