import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UrlConfigService } from '../urlConfig/url-config.service';


@Injectable({
  providedIn: 'root'
})
export class PrestataireService {

  url:any;
  constructor(private httpClient: HttpClient,
              private urlConfig:UrlConfigService) {
                  this.url=this.urlConfig.getUrl()
              }
  read(){
    return this.httpClient.get(this.url+'api_gesap/controllers/prestataires/read.php')
  }

  readidprest(id){
    console.log(id);
    return this.httpClient.post(this.url+'api_gesap/controllers/prestataires/readidtaf.php',{id:id});
  }

  create(obj){
    console.log(obj);
    return this.httpClient.post(this.url+'api_gesap/controllers/prestataires/create.php', obj)
  }

  find(id){
    return this.httpClient.post(this.url+'api_gesap/controllers/prestataires/find.php', {id:id})
  }

  delete(id){
    return this.httpClient.post(this.url+'api_gesap/controllers/prestataires/delete.php', {id:id})
  }
  
  update(obj){
    return this.httpClient.post(this.url+'api_gesap/controllers/Prestataire/update.php', obj)
  }
}
