import { Component, OnInit } from '@angular/core';
import { FactureEauService } from './../../shared/services/factureEau/facture-eau.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AppartementService } from './../../shared/services/appartement/appartement.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import {isEmpty} from "lodash";

@Component({
  selector: 'app-modify-facteau',
  templateUrl: './modify-facteau.component.html',
  styleUrls: ['./modify-facteau.component.css']
})
export class ModifyFacteauComponent implements OnInit {

  idFact: number;
  form: FormGroup;
  facture: any;
  appartement: any;
  indexInitial: number;
  indexFinal: number;
  prixM3: number;
  prixEntretien: number;
  prix: number;
  NumFact = '^[a|A-z|Z0-9]{4,20}$';
  type: string;
  idAppart: number;
  nomAppart: string;
  nombre = '^[0-9]{1,10}$';
  valeur: any;
  factureMois: Array<string>;
  factureMois1: Array<string>;
  moisAnnee: any;
  mois1: any;
  annee: any;
  factureEau: any;
  mois: any;
  statut: string;
  date = new Date();
  datepaie: any;
  valeurDate: any;
  val: string;
  MoisFacture: string;
  AnneeFacture: number;

  constructor(private formBuilder: FormBuilder, private serviceAppart: AppartementService,
    private router: Router, private route: ActivatedRoute, private service: FactureEauService,
    public toastr: ToastrService) { }

    ngOnInit(): void {
      this.valeurDate = this.date.getFullYear()+'-'+(this.date.getMonth()+1)+'-'+this.date.getDate()+' '+this.date.getHours()+":"+
                    this.date.getMinutes()+":"+this.date.getSeconds();
      this.idFact = this.route.snapshot.params['idFact'];

      
      this.service.find(this.idFact).subscribe(data => {
        this.facture = data;
        this.type = this.facture.TypeCompteur;
        this.idAppart = this.facture.IdAppart;
        this.indexInitial = this.facture.IndexInitial;
        this.prixM3 = this.facture.PrixM3;
        this.prixEntretien = this.facture.PrixEntretienCompteur;
        this.MoisFacture = this.facture.Mois;
        this.AnneeFacture = this.facture.Annee;
        switch (this.facture.Mois) {
          case 'Janvier' :
              this.mois = '01';
          break;
          case 'Fevrier' :
              this.mois = '02';
          break;
          case 'Mars':
              this.mois = '03';
          break;
          case 'Avril':
              this.mois = '04';
          break;
          case 'Mai':
              this.mois = '05';
          break;
          case 'Juin':
              this.mois = '06';
          break;
          case 'Juillet':
              this.mois = '07';
              break;
          case 'Août':
              this.mois = '08';
              break;
          case 'Semptembre':
              this.mois = '09';
              break;
          case 'Octobre' :
              this.mois = '10';
              break;
          case 'Novembre' :
              this.mois = '11';
              break;
          case 'Decembre' :
              this.mois = '12';
          break;
        }
        this.valeur = this.facture.Annee+'-'+this.mois;
        this.serviceAppart.find(this.idAppart).subscribe(data => {
          this.nomAppart = data['NumAppart'];
        });

        this.service.read(this.idAppart).subscribe(data => {
          this.factureEau = data; 
        });
        console.log(data);
        this.initForm();
      });
    }

    initForm() {
      this.form = this.formBuilder.group({
        numFact: [this.facture.NumFactEau, [Validators.required, Validators.pattern(this.NumFact)]],
        prix: [this.facture.Prix, [Validators.required, Validators.pattern(this.nombre)]],
        dateLimite: [this.facture.DateLimitePayement, [Validators.required]],
        moisAnnee: [this.valeur, Validators.required],
        indexFinal: [this.facture.IndexFinal, [Validators.required, Validators.pattern(this.nombre)]],
        statut: [this.facture.Status, [Validators.required]],
      });
    }

    onChange(mois, Annee) {
      this.factureMois = [];
      this.factureEau.forEach(element => {
          if(element['Mois'] == mois && element['Annee'] == Annee) {
            this.factureMois.push(element);
          }
        });
        console.log(this.factureMois);
 
      return this.factureMois;
    }

  onSubmitForm() {
    
    const numFact = this.form.get('numFact').value;
    const prix = this.form.get('prix').value;
    const dateLimite = this.form.get('dateLimite').value;
    const indexFinal = this.form.get('indexFinal').value;
    const moisAnnee = this.form.get('moisAnnee').value;
    const statut = this.form.get('statut').value;

    this.moisAnnee = moisAnnee.split('-');
      this.annee = this.moisAnnee[0];
      this.mois1 = this.moisAnnee[1];
      switch (this.mois1) {
        case '01' :
            this.mois1 = 'Janvier';
        break;
        case '02' :
            this.mois1 = 'Fevrier';
        break;
        case '03':
            this.mois1 = 'Mars';
        break;
        case '04':
            this.mois1 = 'Avril';
        break;
        case '05':
            this.mois1 = 'Mai';
        break;
        case '06':
            this.mois1 = 'Juin';
        break;
        case '07':
            this.mois1 = 'Juillet';
            break;
        case '08':
            this.mois1 = 'Août';
            break;
        case '09':
            this.mois1 = 'Semptembre';
            break;
        case '10' :
            this.mois1 = 'Octobre';
            break;
        case '11' :
            this.mois1 = 'Novembre';
            break;
        case '12' :
            this.mois1 = 'Decembre';
        break;
      }
    const valeur = this.onChange(this.mois1, this.annee);
    console.log(valeur, moisAnnee, this.MoisFacture);
    if ((valeur.length == 1 && this.MoisFacture == this.mois1 && this.AnneeFacture == this.annee) ||
    valeur.length < 1) {
      console.log('DOUBLE YES-YES');
      if ( this.type == 'Camwater' ) {
        this.indexFinal = this.facture.IndexFinal;
        this.prix = prix;
        } else if( this.type == 'Forfait' ) {
        this.indexFinal =  indexFinal;
        this.prix = parseInt(((this.indexFinal - this.indexInitial) * this.prixM3).toString()) + parseInt(this.prixEntretien.toString());
      }
      if ( this.indexFinal < this.indexInitial) {
        this.toastr.error('L\'Ancien index n\'est jamais inférieur au nouveau index.', 'Inconcevable!');
      } else {
        this.service.numeroFacture(this.idAppart, numFact).subscribe(data => {
          if ( data == 1 && numFact !== this.facture.NumFactEau ) {
              if( confirm(' Le numéro '+numFact+' existe déjà. \n\n Voulez-vous le modifier?????')) {
  
              } else {
                this.toastr.info(" Les modifications effectuent sur la facture "+this.facture.NumFactEau+ " n\'ont pas été enregistré", 'Information');
                this.router.navigate(['/listFactEau',this.idAppart]);
              }
          } else if ( numFact == this.facture.NumFactEau || data == 0 ) {
                if(statut == 'Payee' && isEmpty(this.facture.DatePaiement)) {
                    this.datepaie = this.valeurDate;
                } else {
                  this.datepaie = null;
                }
                console.log(this.facture.IdFactureEau, numFact, this.prix, dateLimite, this.datepaie, this.mois1, this.annee,
                  this.indexFinal, statut);
              this.service.update(this.facture.IdFactureEau, numFact, this.prix, dateLimite, this.datepaie, this.mois1, this.annee,
                 this.indexFinal, statut).subscribe( data1 => {
                this.router.navigate(['/listFactEau', this.idAppart]);
                this.toastr.success('Facture d\'eau modifié!', 'Succèss!');
            });
          }
        });
      }
    } else {
      this.toastr.info('Il existe déjà une fature d\'eau pour le mois de '+this.mois1 + ' ' + this.annee, 'Information');
    }
  }
}
