import { Component, OnInit } from '@angular/core';
import { PaiementService } from 'app/shared/services/paiement/paiement.service';
import { ContratBailService } from 'app/shared/services/contratBail/contrat-bail.service';
import { Router } from '@angular/router';
import { NGXToastrService } from 'app/paiement/paiementtoast';

@Component({
  selector: 'app-add-paiement',
  templateUrl: './add-paiement.component.html',
  styleUrls: ['./add-paiement.component.css'],
  providers: [NGXToastrService]
})
export class AddPaiementComponent implements OnInit {

  MontantAppart:number
  constructor(private servicePaiement:PaiementService,
              private serviceContract:ContratBailService,
              private route:Router,
              private service:NGXToastrService) { }

  ngOnInit(): void {
    this.servicePaiement.readLocataire().subscribe((res)=>{this.locataires=res})
    }

  typeDuplication(){
    this.service.typeDuplication()
  }

  addpaiement(){
    if(this.paiement.contrat !=null && this.paiement.mois != null && this.paiement.annee != null){
          this.paiement.montant=this.mont
          let mois=this.date.split('-');
          this.paiement.annee=mois[0]
          if(mois[1]=="01"){
            this.paiement.mois='Janvier'
          }else if(mois[1]=="02"){
            this.paiement.mois='Fevrier'
          }else if(mois[1]=="03"){
            this.paiement.mois='Mars'
          }else if(mois[1]=="04"){
            this.paiement.mois='Avril'
          }else if(mois[1]=="05"){
            this.paiement.mois='Mai'
          }else if(mois[1]=="06"){
            this.paiement.mois='Juin'
          }else if(mois[1]=="07"){
            this.paiement.mois='Juillet'
          }else if(mois[1]=="08"){
            this.paiement.mois='Août'
          }else if(mois[1]=="09"){
            this.paiement.mois='Septembre'
          }else if(mois[1]=="10"){
            this.paiement.mois='Octobre'
          }else if(mois[1]=="11"){
            this.paiement.mois='Novembre'
          }else if(mois[1]=="12"){
              this.paiement.mois='Deccembre'
          }
          this.servicePaiement.findPaiement(this.paiement.contrat,this.paiement.mois,this.paiement.annee).subscribe((res)=>{
            console.log(res)
            if(res=null){
              this.servicePaiement.create(this.paiement).subscribe((result)=>{
                if(result['statue']==true && result['message']=="save done"){
                    this.route.navigate(['listpaiement'])
                }  
              })
            }else{
              this.typeDuplication()
            }
            console.log(res)
          })


    }else{

    }

  }


  searchCont(e){
    this.serviceContract.findByIdLocataire(e).subscribe((res)=>{this.MontantAppart=res['MontantAppart'];this.mont=res['MontantAppart'], this.contract=res})
  }

  paiement={
    contrat:'',
    montant:this.MontantAppart,
    mois:'',
    annee:''
  }
  
  locataires:any
  id:any
  i=' '
  contract:any
  mont:number
  d:any
  date=""
}
