import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ContratBailService } from 'app/shared/services/contratBail/contrat-bail.service';
import { LocataireService } from 'app/shared/services/locataire/locataire.service';
import { AppartementService } from 'app/shared/services/appartement/appartement.service';
import { StorageMap } from '@ngx-pwa/local-storage';
import { Appartement } from 'app/interface/appartement';
@Component({
  selector: 'app-view-contbail',
  templateUrl: './view-contbail.component.html',
  styleUrls: ['./view-contbail.component.css']
})
export class ViewContbailComponent implements OnInit {

  constructor(private routes:ActivatedRoute,
              private serviceContract:ContratBailService,
              private serviceLocataire:LocataireService,
              private serviceAppartement:AppartementService,
              private storage:StorageMap,
              private router:Router) { }

  ngOnInit(){
      this.routes.queryParams.subscribe((param)=>{
        if(param && param['idloc']){
            this.serviceContract.findByIdLocataire(param['idloc']).subscribe((res)=>{
            this.serviceLocataire.find(res['IdLocataire']).subscribe((l)=>{
            this.serviceAppartement.find(res['IdAppart']).subscribe((a:Appartement)=>{
                this.appartement=a
                this.NumAppart=a['NumAppart']
                this.locataire=l
                this.contract=res 
                this.IdBail=res['IdBail']
                
                console.log(this.IdBail)
            })
            })
          })
        }
      })
  }

  contract:any
  locataire:any
  appartement:Appartement
  IdBail:number
  NumAppart:any
}
