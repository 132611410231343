<!--Login Page Starts-->
<section id="login">
    <div class="container-fluid">
      <div class="row full-height-vh m-0">
        <div class="col-12 d-flex align-items-center justify-content-center">
          <div class="card">
            <div class="card-content">
              <div class="card-body login-img">
                <div class="row m-0">
                  <div
                    class="col-lg-5 d-lg-block d-none py-2 px-3 text-center align-middle"
                  >
                    <img
                      src="../../assets/img/gallery/maintenance.png"
                      alt=""
                      class="img-fluid mt-5"
                      width="400"
                      height="230"
                    />
                  </div>
                  <div class="col-lg-7 col-md-12 bg-white px-4 pt-3">
                    <h4 class="card-title mb-2">Modifier les informations du batiment</h4>
                    <ng-container *ngIf="form" >
                        <form [formGroup]="form" (ngSubmit)="onSubmitForm()">
                            <div class="form-group">
                                <label for="nomBati" class="control-label mb-1">Batiment</label>
                                <div class="position-relative has-icon-left">
                                  <input type="text" id="nomBati" class="form-control" [value]="nomBati" disabled>
                                    <div class="form-control-position">
                                        <i class="fa fa-institution"></i>
                                    </div>
                                </div>
                              </div>
                            <h5>Electricité</h5>
                            <div class="row col-12">
                              <div class="col-6">
                                <div class="form-group">
                                  <label for="prixKwh">Prix KWH</label>
                                  <input
                                  id="prixKwh"
                                  formControlName="prixKwh"
                                  type="number"
                                  class="form-control mb-3"
                                  required
                                  />
                                  <span *ngIf="form.controls['prixKwh'].invalid && (form.controls['prixKwh'].dirty
                                      || form.controls['prixKwh'].touched)">
                                      <div *ngIf="form.controls['prixKwh'].errors.required" style="color: red;">
                                                Champ obligatoire.
                                      </div>
                                  </span>
                                  <span *ngIf="form.controls.prixKwh.hasError('pattern')" style="color: red;">
                                      Champ invalide.
                                  </span>
                                </div>
                              </div>
                              <div class="col-6">
                                <div class="form-group">
                                  <label for="prixEntretienElectricite">Entretien cpteur</label>
                                  <input
                                  id="prixEntretienElectricite"
                                  formControlName="prixEntretienElectricite"
                                  type="number"
                                  class="form-control mb-3"
                                  required
                                  />
                                  <span *ngIf="form.controls['prixEntretienElectricite'].invalid && (form.controls['prixEntretienElectricite'].dirty
                                      || form.controls['prixEntretienElectricite'].touched)">
                                      <div *ngIf="form.controls['prixEntretienElectricite'].errors.required" style="color: red;">
                                          Champ obligatoire.
                                      </div>
                                      </span>
                                  <span *ngIf="form.controls.prixEntretienElectricite.hasError('pattern')" style="color: red;">
                                      Champ invalide.
                                  </span>
                                </div>
                              </div>
                            </div>
                            
                            
                            <h5>Eau</h5>
                            <div class="row col-12">
                              <div class="col-6">
                                <div class="form-group">
                                  <label for="prixM3">Prix M3</label>
                                  <input
                                  id="prixM3"
                                  formControlName="prixM3"
                                  type="number"
                                  class="form-control mb-3"
                                  required
                                  />
                                  <span *ngIf="form.controls['prixM3'].invalid && (form.controls['prixM3'].dirty
                                  || form.controls['prixM3'].touched)">
                                      <div *ngIf="form.controls['prixM3'].errors.required" style="color: red;">
                                      Champ obligatoire.
                                      </div>
                                  </span>
                                  <span *ngIf="form.controls.prixM3.hasError('pattern')" style="color: red;">
                                      Champ invalide.
                                  </span>
                                </div>
                              </div>
                              <div class="col-6">
                                <div class="form-group">
                                  <label for="prixEntretienEau">Entretien cpteur</label>
                                  <input
                                  id="prixEntretienEau"
                                  formControlName="prixEntretienEau"
                                  type="number"
                                  class="form-control mb-3"
                                  required
                                  />
                                  <span *ngIf="form.controls['prixEntretienEau'].invalid && (form.controls['prixEntretienEau'].dirty
                                      || form.controls['prixEntretienEau'].touched)">
                                      <div *ngIf="form.controls['prixEntretienEau'].errors.required" style="color: red;">
                                          Champ obligatoire.
                                      </div>
                                  </span>
                                  <span *ngIf="form.controls.prixEntretienEau.hasError('pattern')" style="color: red;">
                                      Champ invalide.
                                  </span>
                                </div>
                              </div>
                            </div>
                            
                              
                            <div class="form-actions right row justify-content-center">
                                <button type="submit" class="btn btn-raised btn-primary" [disabled]="form.invalid" style="margin-right: 30px;">
                                    <i class="fa fa-check-square-o"></i> Enregistrer
                                </button>
                                <button type="reset" routerLinkActive="active" routerLink="/dashboard/dashboard1" class="btn btn-raised btn-warning mr-1">
                                    <i class="ft-x"></i> Annuler
                                </button>   
                            </div>
                        </form>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!--Login Page Ends-->
  